import { Typography } from '@mui/material';
import { CONFIG, LABELS, PATHS } from '../../global-constants';
import themeStyles from '../../themes/styles';
import loginImage from '../../assets/img/login_bg.svg';
import cloudDownloadImage from '../../assets/img/cloud-download.svg';
import React, { useEffect, useState } from 'react';
import { parseJwt } from '../../utils/functions';
import moment from 'moment';
import Loader from '../Loader';
import FileOktaDoc from '../../assets/docs/Client User Enrollment.pdf';
import { CTA, FlexColumnGap } from '../../common/styled/styled';
import { LoginTheme } from './themes';
import axios from 'axios';
import { Endpoints } from '../../http/Endpoints';
import AuthStore from '../../common/AuthStore';
import { useDispatch, useSelector } from 'react-redux';
import { PermissionAction, Roles } from '../../store/actions/Login/actions';
import styled from 'styled-components';
import Unauthenticated from '../Unauthenticated';
import PropTypes from 'prop-types';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import useInterval from '../../common/useInterval';
import { checkForRefresh } from '../../common/api/login';

const LoginComponent = props => {
    const dispatch = useDispatch();
    const { loading } = useSelector(state => state.permissionReducer);
    const { mid, children } = props;
    const styles = themeStyles();
    const theme = LoginTheme();
    const accessToken = localStorage.getItem('access_token') || AuthStore.accessToken;

    const [loginState, updateState] = useState({
        isAuthed: false,
        isLoading: true,
        initialLoading: true,
    });
    const [isUnAuthed, setUnauthed] = useState(false);

    const { isAuthed, isLoading, initialLoading } = loginState;

    const checkIsAuth = checkAuth => {
        updateState({ isAuthed: checkAuth, isLoading: false, initialLoading: false });
    };

    const getUserRolesAndPermissions = () => {
        const token_type = localStorage.getItem('token_type') || 'Bearer';
        const token = `${token_type} ${AuthStore.accessToken}`;
        dispatch(PermissionAction(token));
        dispatch(Roles({ page: 1, limit: 50, token }));
    };

    const checkTokenExpiration = () => {
        const access_token = mid.accessToken();
        const infoFromToken = parseJwt(access_token);
        const { exp = 0 } = infoFromToken;
        const { exp: userExp = 0 } = AuthStore.parsedData || {};
        if (exp - moment().unix() > 0 || userExp - moment().unix() > 0) {
            return true;
        }
        return false;
    };

    useEffect(() => {
        if (accessToken && checkTokenExpiration()) {
            checkIsAuth(true);
            getUserRolesAndPermissions();
        } else if (window.location.pathname.includes('/auth/callback')) {
            mid.isAuthed().then(loginStatus => {
                checkIsAuth(loginStatus);
            });
        } else {
            checkIsAuth(false);
        }
        // eslint-disable-next-line
    }, []);

    const makeUserLogin = () => {
        mid.isAuthed().then(loginStatus => {
            if (loginStatus) {
                checkValidateUser();
            } else {
                checkIsAuth(false);
            }
        });
    };

    useInterval(() => checkForRefresh(), 900000);

    const checkValidateUser = async () => {
        const midToken = mid.accessToken();
        if (midToken) {
            const infoFromToken = parseJwt(midToken);
            const { auth_time = 0 } = infoFromToken;
            try {
                const response = await axios.request({
                    method: 'GET',
                    url: `${CONFIG.BASE_PATH_APP}${Endpoints.loginUrl}`,
                    headers: {
                        Authorization: `Bearer ${midToken}`,
                    },
                });
                const {
                    data: { access_token = '', refresh_token = '', token_type = '' },
                    status = '',
                } = response;
                if (status === 200) {
                    const token = `${token_type} ${access_token}`;
                    axios.defaults.headers[`Authorization`] = token;
                    AuthStore.accessToken = access_token;
                    AuthStore.refreshToken = refresh_token;
                    AuthStore.midToken = midToken;
                    AuthStore.auth_time = auth_time;
                    localStorage.setItem('token_type', token_type);
                    dispatch(PermissionAction(token));
                    dispatch(Roles({ page: 1, limit: 50, token }));
                    checkIsAuth(true);
                }
            } catch (error) {
                setUnauthed(true);
            }
        } else {
            checkIsAuth(false);
        }
    };

    const submitHandler = () => {
        updateState({ ...loginState, isLoading: true });
        const access_token = mid.accessToken();
        const infoFromToken = parseJwt(access_token);
        const { exp = 0 } = infoFromToken;
        if (exp - moment().unix() > 0) {
            checkValidateUser();
        } else {
            const pathname = window?.location?.pathname || '';
            if (pathname.includes(PATHS.KNOWLEDGE_CENTER)) {
                AuthStore.redirectionURL = (`${pathname}${window?.location?.search}` || '').replace('/self-serve', '');
            } else {
                AuthStore.redirectionURL = '';
            }
            mid.login().finally(() => {
                makeUserLogin();
            });
        }
    };

    if (isAuthed) {
        return children;
    } else if (isUnAuthed) {
        return <Unauthenticated />;
    }

    if (loading || initialLoading) return <Loader position="fixed" showBackground background="transparent" />;

    return (
        <div data-testid="logincomponent">
            {isLoading && <Loader position="fixed" showBackground background="transparent" />}
            <MainDiv>
                <FlexDiv
                    display="inline-flex"
                    ai="flex-end"
                    gap={96}
                    style={{
                        height: '80vh',
                        width: '100%',
                    }}
                >
                    <FlexColumnGap gap={pxToVh(40)} className="loginSection">
                        <Typography
                            className={`${theme.title}`}
                            dangerouslySetInnerHTML={{ __html: LABELS.WELCOME }}
                        ></Typography>
                        <ButtonLogin
                            onClick={submitHandler}
                            bg={colorsCode.SecondaryAmber100}
                            lh={24}
                            size={16}
                            fw={400}
                        >
                            {LABELS.LOGIN_TEXT}
                        </ButtonLogin>
                    </FlexColumnGap>
                </FlexDiv>
                {/* footer */}
                <FlexDiv
                    display="flex"
                    gap={20}
                    ai="center"
                    style={{
                        height: '20vh',
                    }}
                >
                    <Typography color={colorsCode.Neutral30} component="span" data-testid="login">
                        {LABELS.EXTERNAL_INFO}
                    </Typography>
                    <Typography
                        color={colorsCode.SecondaryAmber50}
                        component="span"
                        className="instruction-container"
                        mb={6}
                    >
                        <img src={cloudDownloadImage} alt="cloud-download" className="cloud-download" />
                        <a href={FileOktaDoc} className={`${styles.oktaClass}`} download>
                            Click here
                        </a>
                        {LABELS.OKTA_TEXT}
                    </Typography>
                </FlexDiv>
            </MainDiv>
        </div>
    );
};

const ButtonLogin = styled(CTA)`
    padding: ${pxToVh(12)}vh ${pxToVw(24)}vw !important;
`;

const MainDiv = styled.div`
    background: url(${loginImage}) no-repeat center center;
    -webkit-background-size: cover;
    background-size: cover;
`;

const FlexDiv = styled.div`
    display: ${({ display }) => display};
    align-items: ${({ ai }) => ai};
    gap: ${({ gap }) => pxToVh(gap)}vh;
    flex-direction: column;
    justify-content: center;
    .loginSection {
        width: 100% !important;
        align-items: center !important;
    }
    .instruction-container {
        display: flex;
        align-items: center;
    }
    .cloud-download {
        margin-right: ${pxToVw(8)}vw;
    }
    span {
        font-size: ${pxToVh(16)}vh !important;
        line-height: ${pxToVh(24)}vh !important;
        font-weight: 300 !important;
        a {
            font-weight: 400;
        }
    }
`;

LoginComponent.propTypes = {
    mid: PropTypes.shape({
        accessToken: PropTypes.func,
        isAuthed: PropTypes.func.isRequired,
        login: PropTypes.func.isRequired,
    }),
    children: PropTypes.object,
};

export default LoginComponent;
