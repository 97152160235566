import React from 'react';
import { useDispatch } from 'react-redux';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Typography from '@mui/material/Typography';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import {
    FlexStartGap,
    FlexInline,
    QuestionContent,
    AccordionDetailsStyled,
    AccordionStyled,
    AccordionSummaryStyled,
} from '../../../../../common/styled/styled';
import { Modulebody } from '../OptionalModules';
import { Pagetab } from '../../../../../common/FormInputs/Common';
import { SETUP_SURVEY_TEXT } from '../../../../../utils/kc-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import { commonThemes } from '../../../../../common/themes';
import CheckboxSvg from '../../../../../assets/img/Checkbox.svg';
import DisabledCheckboxSvg from '../../../../../assets/img/DisabledCheckbox.svg';
import AuthStore from '../../../../../common/AuthStore';
import arrowDown from '../../../../../assets/img/Small-down.svg';
import styled from 'styled-components';
import { setSurveySliceLoading } from '../../../../../store/reducers/slice/SurveySlice';
import { cloneDeep } from 'lodash';
import { API_SUCCESS_CODES, INFLUENCER } from '../../../../../global-constants';

const PssQuestions = ({
    pssData = [],
    handleQuestionResponse,
    updatePssQuestion,
    tabId,
    setTabId,
    questions,
    selectedSectionIndex,
    handleParentCheck,
    selectedSectionKey,
    sections,
    handleInfluencerParentCheck,
}) => {
    const dispatch = useDispatch();
    const stylesComp = commonThemes();
    const [isExpanded, setIsExpanded] = React.useState(false);

    const isInfluencer = tabId === INFLUENCER;

    React.useEffect(() => {
        if (tabId === null) {
            const { tab_key } = pssData.sections[0];
            setTabId(tab_key);
        }
        // eslint-disable-next-line
    }, [pssData, tabId]);

    const handleTab = newTabId => setTabId(newTabId);

    const handleColCheck = async (target, qIndex, questionId) => {
        const { checked } = target;
        dispatch(setSurveySliceLoading(true));
        /* Section where we update module level flag based on any question is selected or not
        We also calculate whether we have make an API call to update parent flag.*/
        const updatedPssModule = cloneDeep(pssData);
        const { is_social_included, sections } = updatedPssModule;

        updatedPssModule.sections[selectedSectionIndex].questions[qIndex].use_in_survey = checked;

        const selectedTabQuestions = sections[selectedSectionIndex].questions;
        const selectedQuestionsLength = selectedTabQuestions.filter(({ use_in_survey }) => use_in_survey).length;
        const makeParentModuleCall =
            (checked && selectedQuestionsLength === 1) || (!checked && !selectedQuestionsLength);

        const updateSocial = selectedSectionKey === 'social_sector';
        if (updateSocial) {
            updatedPssModule.is_social_included = !!selectedQuestionsLength;
        } else {
            updatedPssModule.is_included = !!selectedQuestionsLength;

            //unselect social sector module and all its questions
            if (!selectedQuestionsLength && is_social_included && !checked) {
                updatedPssModule.is_social_included = false;

                const questionsPosition = sections.findIndex(({ tab_key }) => tab_key === 'social_sector');

                updatedPssModule.sections[questionsPosition].questions = updatedPssModule.sections[
                    questionsPosition
                ].questions.map(oldQuestion => ({
                    ...oldQuestion,
                    use_in_survey: checked,
                }));
            }
        }

        const updatedPayload = {};
        if (makeParentModuleCall) {
            if (updateSocial) {
                updatedPayload.is_social_included = checked;
            } else {
                updatedPayload.include = checked;
            }
        }
        const questionResp = await handleQuestionResponse(questionId, checked, updatedPayload);
        if (questionResp && API_SUCCESS_CODES.includes(questionResp?.status)) {
            dispatch(
                updatePssQuestion({
                    updatedPssModule,
                })
            );
        }
    };

    const allQuesNotSelected = questions.some(({ use_in_survey }) => !use_in_survey);
    const selectedQuesLen = questions.filter(({ use_in_survey }) => use_in_survey).length;
    const makeQuestionDisable = !AuthStore.isAdmin || (!pssData?.is_included && selectedSectionKey === 'social_sector');

    return (
        <Modulebody style={{ paddingTop: 0, paddingBottom: `${pxToVh(40)}vh` }} className="mainContent">
            <AccordionStyled paddingBottom={0.001} expanded={isExpanded}>
                <AccordionSummaryStyled
                    pdLeftIcon={4}
                    expandIcon={<img src={arrowDown} alt="arrow-down" />}
                    pdTop={0}
                    pdBottom={0}
                    width={'fit-content'}
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                >
                    <Typography className="actionClass" color={colorsCode.SecondaryDeepBlue500}>
                        {isExpanded ? 'HIDE QUESTIONS' : 'VIEW QUESTIONS'}
                    </Typography>
                </AccordionSummaryStyled>
                <AccordionDetailsStyled
                    style={{
                        paddingTop: 0,
                        borderBottom: 0,
                    }}
                >
                    <TabsWrapper className="tabDiv">
                        <FlexStartGap gap={pxToVw(16)}>
                            <div style={{ alignSelf: 'stretch' }}>
                                <FlexInline>
                                    {sections.map(({ display_text, tab_key }) => {
                                        return (
                                            <Pagetab
                                                key={tab_key}
                                                active={tabId === tab_key}
                                                label={display_text}
                                                clickHandler={() => handleTab(tab_key)}
                                            />
                                        );
                                    })}
                                </FlexInline>
                            </div>
                        </FlexStartGap>
                    </TabsWrapper>
                    <QuestionContent textSize={14} lineHeight={20}>
                        <FormGroup>
                            <FormControlLabel
                                disabled={makeQuestionDisable}
                                sx={{ margin: 0 }}
                                control={
                                    <Checkbox
                                        disableRipple
                                        checked={!allQuesNotSelected}
                                        indeterminate={!!(allQuesNotSelected && selectedQuesLen)}
                                        onChange={({ target }) =>
                                            isInfluencer
                                                ? handleInfluencerParentCheck(target)
                                                : handleParentCheck(target, selectedSectionKey === 'social_sector')
                                        }
                                        sx={{
                                            height: 0,
                                            width: 0,
                                            padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                            borderRadius: 0,
                                        }}
                                        icon={
                                            <img
                                                src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                alt=""
                                                className="img"
                                            />
                                        }
                                        checkedIcon={<CheckBoxIcon />}
                                    />
                                }
                                label={
                                    <Typography
                                        className={`${stylesComp.formControlLabel} ${
                                            makeQuestionDisable ? 'isDisabled' : ''
                                        }`}
                                        style={{ fontWeight: 500 }}
                                    >
                                        {SETUP_SURVEY_TEXT.QUESTIONS_HEADING}
                                    </Typography>
                                }
                            />
                            {questions?.map(({ question_text, use_in_survey, uuid }, qIndex) => {
                                return (
                                    <FormControlLabel
                                        disabled={makeQuestionDisable}
                                        sx={{ margin: 0 }}
                                        key={`${qIndex}-${uuid}`}
                                        control={
                                            <Checkbox
                                                disableRipple
                                                checked={use_in_survey}
                                                onChange={({ target }) =>
                                                    isInfluencer
                                                        ? handleInfluencerParentCheck(target)
                                                        : handleColCheck(target, qIndex, uuid)
                                                }
                                                icon={
                                                    <img
                                                        src={makeQuestionDisable ? DisabledCheckboxSvg : CheckboxSvg}
                                                        alt=""
                                                        className="img"
                                                    />
                                                }
                                                checkedIcon={<CheckBoxIcon />}
                                                sx={{
                                                    height: 0,
                                                    width: 0,
                                                    padding: `${pxToVh(16)}vh ${pxToVh(20)}vh`,
                                                    borderRadius: 0,
                                                }}
                                            />
                                        }
                                        label={
                                            <Typography
                                                className={`${stylesComp.formControlLabel} ${
                                                    makeQuestionDisable ? 'isDisabled' : ''
                                                }`}
                                                dangerouslySetInnerHTML={{ __html: question_text }}
                                            />
                                        }
                                    />
                                );
                            })}
                        </FormGroup>
                    </QuestionContent>
                </AccordionDetailsStyled>
            </AccordionStyled>
        </Modulebody>
    );
};

export default PssQuestions;

export const TabsWrapper = styled.div`
    padding: 20px 0 0 0;
    align-self: stretch;
`;
