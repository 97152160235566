import React, { useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import { CommonChip, CommonLabel, Img } from '../../../../../common/FormInputs/Common';
import { CTA, FlexAlignCenter, FlexBetween, FlexColumnGap, FlexRow } from '../../../../../common/styled/styled';
import styled from 'styled-components';
import { SURVEY_STATUS } from '../../../../../global-constants';
import Note from '../../../../../assets/img/YellowNote.svg';
import ArrowRight from '../../../../../assets/img/Arrow-right.svg';
import CloseIcon from '../../../../../assets/img/closecross.svg';
import RedWarningIcon from '../../../../../assets/img/red_warning.svg';
import Text from '../../../../KnowledgeCenter/Text';
import { Box, Dialog, DialogActions, DialogContent, Icon, Typography } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';

const PageHeader = ({
    title,
    desc,
    isButton = false,
    handler = () => {},
    displayLogicNavigation = false,
    handleDisplayLogicNavigation = {},
    reasons,
    chipText = '',
}) => {
    const [showFinalizePopup, setShowFinalizePopup] = useState(false);

    return (
        <>
            <MainContent>
                <FlexBetween>
                    <FlexColumnGap gap={pxToVh(12)}>
                        <CommonLabel
                            label={title}
                            textSize={24}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                            lineHeight={28}
                        />
                        <CommonLabel
                            label={desc}
                            textSize={16}
                            textWeight={400}
                            textColor={colorsCode.Neutral80}
                            lineHeight={24}
                        />
                    </FlexColumnGap>
                    {isButton ? (
                        <CTA
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={6}
                            pl={16}
                            size={14}
                            fw={400}
                            lh={28}
                            color={colorsCode.white}
                            onClick={() => {
                                if (reasons?.length) {
                                    setShowFinalizePopup(true);
                                } else {
                                    handler(SURVEY_STATUS.LIVE);
                                }
                            }}
                        >
                            Finalize survey
                        </CTA>
                    ) : null}
                </FlexBetween>
                {displayLogicNavigation && (
                    <ReDirectionConatiner>
                        <FlexAlignCenter gap="0.5">
                            <Img image={Note} h={pxToVh(18)} w={pxToVw(18)} alt="" />
                            <Text fontSize={13} lineHeight={20} label="Note:" fontWeight={500} />
                            <Text
                                fontSize={13}
                                lineHeight={20}
                                label="To add conditionality to your questions, go to the 'Display Logic' tab."
                            />
                        </FlexAlignCenter>
                        <FlexRow>
                            <Typography
                                className="actionClass"
                                data-testid="resumetypo"
                                color={colorsCode.SecondaryDeepBlue500}
                                onClick={handleDisplayLogicNavigation}
                            >
                                GO TO DISPLAY LOGIC
                            </Typography>
                            <img
                                src={ArrowRight}
                                alt="resume"
                                className="imgApprove"
                                style={{ cursor: 'pointer' }}
                                onClick={handleDisplayLogicNavigation}
                            />
                        </FlexRow>
                    </ReDirectionConatiner>
                )}
                {chipText ? (
                    <Box sx={{ marginTop: `${pxToVh(8)}vh` }}>
                        <CommonChip
                            value={chipText}
                            bgColor={colorsCode.PrimaryDeepBlue}
                            color={colorsCode.white}
                            onDeleteHandler={() => {}}
                            iconEl={
                                <InfoIcon
                                    sx={{
                                        width: `${pxToVh(12)}vh`,
                                        height: `${pxToVh(12)}vh`,
                                        path: {
                                            fill: colorsCode.SecondaryDeepBlue,
                                        },
                                    }}
                                />
                            }
                            hasStartIcon
                            fontSize={`${pxToVh(13)}vh`}
                            lineHeight={`${pxToVh(16)}vh`}
                            fontWeight={400}
                            padding={`${pxToVh(4)}vh ${pxToVw(4)}vw`}
                            height="auto"
                        />
                    </Box>
                ) : null}
            </MainContent>
            {showFinalizePopup ? (
                <Dialog
                    open={showFinalizePopup}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVw(500)}vw`,
                            width: `${pxToVw(500)}vw`,
                            borderRadius: '5px',
                        },
                    }}
                >
                    <Icon
                        aria-label="close"
                        onClick={() => setShowFinalizePopup(false)}
                        sx={{
                            position: 'absolute',
                            right: `${pxToVw(14)}vw`,
                            top: `${pxToVh(10)}vh`,
                            cursor: 'pointer',
                        }}
                    >
                        <img src={CloseIcon} alt="close" style={{ width: '24px' }} />
                    </Icon>
                    <DialogContent sx={{ mt: `${pxToVh(20)}vh` }}>
                        <FlexRow gap="0.5">
                            <div style={{ paddingLeft: '10px' }}>
                                <Img image={RedWarningIcon} h={3} w={3.2} />
                            </div>
                            <CommonLabel
                                textSize={24}
                                textWeight={500}
                                textColor={colorsCode.TextHeading}
                                lineHeight={18}
                                label="You have pending actions"
                            />
                        </FlexRow>
                        <div style={{ paddingTop: '10px' }}>
                            <ul>
                                {reasons?.map(item => {
                                    return (
                                        <div key={item}>
                                            <CommonLabel
                                                textColor={colorsCode.TextHeading}
                                                textSize={14}
                                                lineHeight={24}
                                                textWeight={400}
                                                label={item}
                                                component={reasons?.length > 1 ? 'li' : ''}
                                            />
                                        </div>
                                    );
                                })}
                            </ul>
                        </div>
                    </DialogContent>
                    <DialogActions sx={{ justifyContent: 'center', paddingBottom: `${pxToVh(40)}vh` }}>
                        <CTA
                            size={16}
                            lh={16}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            onClick={() => setShowFinalizePopup(false)}
                        >
                            Okay
                        </CTA>
                    </DialogActions>
                </Dialog>
            ) : null}
        </>
    );
};

export const MainContent = styled.div`
    padding: ${pxToVh(24)}vh ${pxToVw(48)}vw;
    background: ${colorsCode.SecondaryAmber100};
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
`;

const ReDirectionConatiner = styled.div`
    background: #fff3ce;
    margin: 15px 0;
    border: 1px solid #f3c233;
    padding: 15px 12px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
`;

export default PageHeader;
