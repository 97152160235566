import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Paper,
    IconButton,
    Icon,
    Autocomplete,
    TextField,
} from '@mui/material';
import React, { Fragment, memo, useEffect, useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import { CommonChip, CommonLabel, Img } from '../../../../../common/FormInputs/Common';
import LightTooltip from '../../../../../common//LightTooltip';
import {
    Flex,
    FlexBetween,
    FlexColumn,
    FlexStart,
    FlexColumnGap,
    FlexStartGap,
    FlexCenter,
    CTA,
    FlexRow,
    FlexCenterWithAlign,
    FlexColumnCenter,
    FlexRowWidth,
} from '../../../../../common/styled/styled';
import { ReactComponent as LaunchIcon } from '../../../../../assets/img/Launch.svg';
import line from '../../../../../assets/img/Line 50.svg';
import OnlineIcon from '../../../../../assets/img/Ellipse 104.svg';
import OfflineIcon from '../../../../../assets/img/Offline.svg';
import CloseIcon from '../../../../../assets/img/close.svg';
import download from '../../../../../assets/img/downloadqr.svg';
import { ReactComponent as CopyIcon } from '../../../../../assets/img/copy_grey.svg';
import Header from '../../create/Header';
import PageHeader from '../common-components/PageHeader';
import { EditSurveyDiv } from '../Wording';
import styled from 'styled-components';
import AuthStore from '../../../../../common/AuthStore';
import TotalStatusCount from './partials/TotalStatusCount';
import Actions from './partials/Actions';
import { useDispatch, useSelector } from 'react-redux';
import RedWarningIcon from '../../../../../assets/img/red_warning.svg';
import {
    AllCustomQuestion,
    GetSurveyPreviewLinks,
    SaveStatusQuestions,
} from '../../../../../store/actions/Surveys/FinalizeActions';
import Loader from '../../../../Loader/Loader';
import approved from '../../../../../assets/img/approved.svg';
import rejected from '../../../../../assets/img/rejected.svg';
import pending from '../../../../../assets/img/pending.svg';
import notsubmitted from '../../../../../assets/img/not submitted.svg';
import { cloneDeep, debounce, isEmpty } from 'lodash';
import { resetData as surveyReset } from '../../../../../store/reducers/slice/SurveySlice';
import { resetData } from '../../../../../store/reducers/slice/FinalizeReducer';
import InputField from '../../../../../common/FormInputs/InputField';
import {
    GetAdditionalModules,
    GetSurveyInformation,
    getTimezones,
    UpdateSurveyStatus,
} from '../../../../../store/actions/Surveys/action';
import { closeModal, openModal } from '../../../../../store/reducers/slice/Modal';
import { SuccessModal } from '../../../../../common/DialogModal';
import {
    CONFIG,
    GET_CLASS,
    LABELS,
    MESSAGE,
    pulseSurveyTypes,
    QUES_STATUS_TYPE,
    STEPS,
    THROTTLE_TIME,
    NPM_TEMPLATE,
    LTE_TEMPLATE,
    TEI_TEMPLATE,
    MA_TEMPLATE,
    SURVEY_STATUS,
    updateStatusOptions,
    timeFormat,
    dateFormat,
} from '../../../../../global-constants';
import QRCode from 'qrcode';
import OptionalModules from '../../setup-survey/OptionalModules';
import QuestionTable from './partials/QuestionTable';
import { StatusBadge } from '../Badge';
import { setActiveStep, setDisabledStep } from '../../../../../store/reducers/slice/ActiveStep';
import { getOutcomesAndPractices } from '../../../../../store/actions/TextEdit/actions';
import InfoIcon from '@mui/icons-material/Info';
import { ButtonView } from '../../../../../common/ButtonView';
import { OpportunityLogsListing } from '../../../../../store/actions/Client/actions';
import { setToast } from '../../../../../store/reducers/slice/Toast';
import DropdownMenu from '../../../../../common/FormInputs/DropdownMenu';
import DatePickerComponent from '../../../../../common/DatePickerComponent';
import TimeSelector from '../../../../../common/themes/TimeSelector';
import { CustomPopupIcon } from '../DisplayLogic/DisplayLogicComponents/SelectQuestionDropdown';
import moment from 'moment/moment';

const Finalize = ({ goToTab, styles }) => {
    const dispatch = useDispatch();
    const isAdmin = AuthStore.isAdmin;
    const [finalizeList, setFinalizeList] = useState([]);
    const [open, setOpen] = useState(false);
    const [copied, setCopied] = React.useState(false);
    const [qid, setId] = useState('');
    const [src, setSrc] = useState('');
    const [reason, setReason] = useState('');
    const [search, setSearch] = useState('');
    const [viewType, setViewType] = useState(1);
    const [finalizeOpen, setFinalizeOpen] = useState({
        status: '',
        open: false,
    });
    const { loading, data, error, previewData = {}, errorMessage = '' } = useSelector(state => state.finalSetupReducer);
    const {
        loading: outcomesLoading,
        selectedQuestionCount,
        outcomesData: ohiData,
    } = useSelector(state => state.ohiTemp);
    const { isOpen } = useSelector(state => state.dialogReducer);
    const {
        additionalModules: am = [],
        surveyInformation: si = {},
        loading: surveyLoading,
        timezoneList,
        updateSurveyLoading,
        timezonesLoading,
    } = useSelector(state => state.surveyReducer);
    const { opportunityLogs, clientData, opportunitiesLoading } = useSelector(state => state?.clientReducer);
    const demo = data?.filter(({ is_demo }) => is_demo === true);
    const isMATemplate = si?.schema_name === MA_TEMPLATE;
    const openText = !isMATemplate ? data?.filter(({ is_opentext }) => is_opentext === true) : [];
    const additional = data?.filter(({ is_opentext, is_demo }) => is_demo === false && is_opentext === false);
    const merging = [...additional, ...demo, ...openText];
    const { survey_url_path, survey_url_path_live } = previewData;
    const { NOT_SUBMITTED, APPROVED, PENDING, REJECTED } = QUES_STATUS_TYPE;
    const surveyPath = CONFIG.BASE_PATH_APP;
    const COLORS = {
        pending: colorsCode.pendingbg,
        notsubmitted: colorsCode.Neutral05,
        approved: colorsCode.approvedbg,
        rejected: colorsCode.rejectedbg,
    };
    const [showDeletePopUp, setShowDeletePopUp] = useState({ show: false, uuid: '' });

    const { status, timezone_uuid = '', uuid: surveyId } = si;
    const { DRAFT, LIVE, LIVE_SCHEDULE } = SURVEY_STATUS;
    const currentDate = moment().startOf('day');

    const initialConfig = {
        status_type: '',
        date: new Date(),
        time: null,
        timezone: timezone_uuid,
    };
    const [surveyConfig, setSurveyConfig] = useState(initialConfig);
    const [anythingUpdated, setAnythingUpdated] = useState(false);
    const { status_type, date, time, timezone } = surveyConfig;
    const updateStatusOptionsInUse = isAdmin
        ? updateStatusOptions[status]
        : updateStatusOptions[status].filter(({ onlyAdminAllowed }) => !onlyAdminAllowed);

    const showOutcomes = pulseSurveyTypes.includes(si?.schema_name);
    const isNpmTemplate = si?.schema_name === NPM_TEMPLATE;
    const isLteTemplate = si?.schema_name === LTE_TEMPLATE;
    const isTeiTemplate = si?.schema_name === TEI_TEMPLATE;
    const showLinkSection = si.survey_type === 'GENERIC' || si?.is_combined_survey;
    const showOutcomePracticeError = showOutcomes && !selectedQuestionCount;

    const surveyPathInUse = viewType === 1 ? survey_url_path : survey_url_path_live;
    const isTestLink = viewType === 1;

    useEffect(() => {
        if (!timezoneList?.length) {
            dispatch(getTimezones());
        }

        if (!surveyPathInUse) {
            dispatch(GetSurveyPreviewLinks({ surveyId: si?.uuid }));
        }
        dispatch(AllCustomQuestion({ surveyId: si?.uuid }));
        if (!am.length) {
            dispatch(GetAdditionalModules({ survey_id: si.uuid }));
        }
        if (isEmpty(ohiData) && showOutcomes) {
            dispatch(getOutcomesAndPractices({ surveyId: si?.uuid }));
        }
        dispatch(
            OpportunityLogsListing({
                client_id: clientData?.uuid,
            })
        );
        return () => {
            dispatch(resetData());
            dispatch(closeModal());
            dispatch(surveyReset());
        };
    }, []);

    useEffect(() => {
        if (error) {
            dispatch(
                setToast({
                    show: true,
                    type: 'error',
                    message: errorMessage,
                })
            );
        }
    }, [error, errorMessage]);

    useEffect(() => {
        setFinalizeList(merging);
        const qrcode = async url => {
            QRCode.toDataURL(url, { errorCorrectionLevel: 'H' }).then(setSrc);
        };
        if (previewData && Object.keys(previewData).length) {
            const { survey_url_path, survey_url_path_live } = previewData;
            const url = surveyPath.concat(viewType === 1 ? survey_url_path : survey_url_path_live);
            qrcode(url);
        }
    }, [data, previewData, viewType]);

    const handleCloseModal = () => {
        dispatch(GetSurveyInformation({ survey_id: si.uuid }));
        dispatch(setDisabledStep([STEPS.SURVEY_SETUP]));
        const stepper = AuthStore.isAdmin ? STEPS.TEXT_EDIT : STEPS.DISTRIBUTE_SURVEY;
        dispatch(setActiveStep([stepper]));
        dispatch(closeModal());
        dispatch(surveyReset());
    };

    const showImageStatus = question_status => {
        const getClass = Object.keys(QUES_STATUS_TYPE).find(item => QUES_STATUS_TYPE[item] === question_status);
        return (
            <StatusBadge
                color={colorsCode[GET_CLASS[getClass]]}
                bg={COLORS[GET_CLASS[getClass]]}
                label={question_status}
                src={images(question_status)}
            />
        );
    };

    const images = status => {
        switch (status) {
            case APPROVED:
                return approved;
            case NOT_SUBMITTED:
                return notsubmitted;
            case REJECTED:
                return rejected;
            default:
                return pending;
        }
    };

    const handleAllClick = e => {
        const {
            target: { checked },
        } = e;
        const clone = cloneDeep(finalizeList);
        setFinalizeList(
            clone.map(item => {
                return { ...item, is_selected: checked };
            })
        );
    };

    const handleOneClick = (e, i) => {
        const {
            target: { checked },
        } = e;
        const clone = cloneDeep(finalizeList);
        clone[i].is_selected = checked;
        setFinalizeList(clone);
    };

    const handleReason = (uuid, remarks) => {
        setId(uuid);
        setReason(remarks);
        setOpen(true);
    };

    const handleChange = e => {
        const {
            target: { value },
        } = e;
        setReason(value);
    };

    const handleDateChange = e => {
        setAnythingUpdated(true);
        setSurveyConfig({ ...surveyConfig, date: e });
    };

    const handleOnChange = e => {
        const {
            target: { name, value },
        } = e;

        setAnythingUpdated(true);

        if (name === 'status_type') {
            setSurveyConfig({
                date: new Date(),
                time: null,
                timezone: timezone_uuid,
                [name]: value,
            });
        } else {
            setSurveyConfig({
                ...surveyConfig,
                [name]: value,
            });
        }
    };

    const changeSurveyStatus = async () => {
        let payload = { survey_status: LIVE };

        if (status_type === LIVE_SCHEDULE) {
            payload = {
                ...payload,
                survey_launch_time: moment(time).format(timeFormat),
                survey_launch_date: moment(date).format(dateFormat),
                survey_timezone: timezone,
            };
        }

        try {
            const resp = await dispatch(
                UpdateSurveyStatus({
                    surveyId: surveyId,
                    payload: payload,
                    showToast: false,
                })
            ).unwrap();
            if (resp && resp?.message) {
                dispatch(openModal());
            }
        } catch (error) {
            // error occurred
        }
        setFinalizeOpen({ open: false, status: '' });
    };

    const enableDisable = () => {
        let disableUpdateCTA = false;

        const statusTypeNotExists = !status_type;

        if (status === DRAFT) {
            if (statusTypeNotExists) {
                disableUpdateCTA = true;
            } else if (
                status_type === LIVE_SCHEDULE &&
                (!date || !time || !timezone || moment(date).isBefore(currentDate))
            ) {
                disableUpdateCTA = true;
            }
        }

        return disableUpdateCTA;
    };

    const handleSave = () => {
        const requestParams = [
            {
                question_id: qid,
                remarks: reason,
                is_rejected: true,
                question_status: QUES_STATUS_TYPE.REJECTED,
            },
        ];
        dispatch(SaveStatusQuestions({ surveyId: si?.uuid, requestParams, editReason: true }));
        handleClose();
    };

    const handleStatus = (e, uuid, is_condition_applied) => {
        const {
            target: { value },
        } = e;

        if (value === 'Rejected' && is_condition_applied) {
            setShowDeletePopUp({ show: true, uuid: uuid });
        } else {
            const requestParams = [
                {
                    question_id: uuid,
                    remarks: reason,
                    is_rejected: [PENDING, NOT_SUBMITTED].includes(value) ? null : value === APPROVED ? false : true,
                    question_status: value,
                },
            ];
            dispatch(SaveStatusQuestions({ surveyId: si?.uuid, requestParams, editReason: false }));
        }
    };

    const handleClose = () => {
        setOpen(false);
        setId('');
        setReason('');
    };

    const handleSearch = debounce(e => {
        const {
            target: { value },
        } = e;
        setSearch(value);
        if (value.length >= 3) {
            const filterData = data.filter(({ question_text }) =>
                question_text.toLowerCase().includes(value.toLowerCase())
            );
            setFinalizeList(filterData);
        } else {
            setFinalizeList(merging);
        }
    }, THROTTLE_TIME);

    const finalizeSurvey = status => {
        setFinalizeOpen({
            status,
            open: true,
        });
    };

    const downloadOrRedirect = (flag = 'download', url) => {
        let downloadLink = document.createElement('a');
        downloadLink.href = url;
        if (flag === 'download') {
            downloadLink.download = `${si?.survey_id?.toUpperCase()}_${viewType === 1 ? 'test' : 'live'}-qr-code.png`;
        } else {
            downloadLink.target = '_blank';
        }
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    function copy(input) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(input).then(
                () => {
                    setCopied(true);
                },
                err => {
                    console.log('Failed to copy the text to clipboard.', err);
                }
            );
        } else if (window.clipboardData) {
            window.clipboardData.setData('Text', input);
        }
    }

    const getCtaTooltip = () => {
        const reasons = [];
        if (finalizeList.length && finalizeList.filter(({ is_rejected }) => is_rejected === null).length) {
            reasons.push('Review custom questions.');
        }
        if (showOutcomePracticeError) {
            reasons.push(MESSAGE.finalizePulseError);
        }
        if (si.salesforce_id) {
            const opp = opportunityLogs?.find(item => item?.salesforce_id === si.salesforce_id);
            const isLegal = opp?.legal_check || opp?.legal_special_case;
            if (!isLegal && !isNpmTemplate && !isLteTemplate && !isTeiTemplate && !AuthStore?.isClient) {
                reasons.push(MESSAGE.SALESFORCE_FINALIZE);
            }
        }
        return reasons;
    };

    const tooltipUi = getCtaTooltip();

    return (
        <Fragment>
            {(loading ||
                surveyLoading ||
                outcomesLoading ||
                opportunitiesLoading ||
                updateSurveyLoading ||
                timezonesLoading) && <Loader showBackground position="fixed" background="transparent" />}
            <Header
                position="fixed"
                zIndex={999}
                width="100%"
                isNextEnabled={false}
                isPreviewRequired={true}
                isPreviewDisabled={false}
                previewHandler={() => downloadOrRedirect('redirect', surveyPath.concat(surveyPathInUse))}
                loading={loading}
            />
            <FlexColumnGap>
                <EditSurveyDiv>
                    <Paper elevation={2} sx={styles}>
                        <PageHeader
                            title="Finalize survey"
                            desc=""
                            isButton={true}
                            handler={finalizeSurvey}
                            reasons={tooltipUi}
                        />
                        {!showOutcomePracticeError ? (
                            <>
                                {showLinkSection ? (
                                    <FlexDiv>
                                        <FlexColumnGap gap={pxToVh(3)} width="100%">
                                            <FlexRow justifyContent="space-between" style={{ width: '100%' }}>
                                                <CommonLabel
                                                    textSize={14}
                                                    textWeight={400}
                                                    textColor={colorsCode.Neutral80}
                                                    lineHeight={20}
                                                    label="As default, this page will show the test link for preview. Please use the toggle to access the live link."
                                                />
                                                <ButtonView
                                                    viewType={viewType}
                                                    setType={setViewType}
                                                    btn1text="Test link"
                                                    btn2Text="Live link"
                                                />
                                            </FlexRow>
                                            <CommonChip
                                                value="IMPORTANT: Do not distribute the live link before the survey is finalized."
                                                bgColor={colorsCode.PrimaryDeepBlue}
                                                color={colorsCode.white}
                                                onDeleteHandler={() => {}}
                                                iconEl={
                                                    <InfoIcon
                                                        sx={{
                                                            width: `${pxToVh(12)}vh`,
                                                            height: `${pxToVh(12)}vh`,
                                                            path: {
                                                                fill: colorsCode.SecondaryDeepBlue,
                                                            },
                                                        }}
                                                    />
                                                }
                                                hasStartIcon
                                                fontSize={`${pxToVh(13)}vh`}
                                                lineHeight={`${pxToVh(16)}vh`}
                                                fontWeight={400}
                                                padding={`${pxToVh(2)}vh ${pxToVw(4)}vw`}
                                                height="auto"
                                            />
                                            <FlexRow gap={pxToVw(8)} style={{ marginTop: '1rem' }}>
                                                <img src={isTestLink ? OfflineIcon : OnlineIcon} alt="Link icon" />
                                                <CommonLabel
                                                    textSize={20}
                                                    textWeight={500}
                                                    textColor={colorsCode.Neutral80}
                                                    lineHeight={32}
                                                    label={`${isTestLink ? 'Test' : 'Live'} link`}
                                                />
                                            </FlexRow>
                                        </FlexColumnGap>
                                    </FlexDiv>
                                ) : null}
                                <LinkWrapper showTopPadding={!showLinkSection}>
                                    <FlexColdiv>
                                        <FlexRow style={{ gap: '4px' }}>
                                            <div style={{ width: `${pxToVw(460)}vw` }}>
                                                <CommonLabel
                                                    textSize={18}
                                                    textWeight={500}
                                                    textColor={colorsCode.PrimaryDeepBlue}
                                                    lineHeight={28}
                                                    label="Preview survey in browser window"
                                                />
                                            </div>
                                            <LaunchIcon
                                                className="img"
                                                onClick={() =>
                                                    downloadOrRedirect('redirect', surveyPath.concat(surveyPathInUse))
                                                }
                                            />
                                        </FlexRow>
                                        <Flex style={{ gap: '14px' }}>
                                            <Linkfield>
                                                <div style={{ width: `${pxToVw(430)}vw` }}>
                                                    <CommonLabel
                                                        textColor={colorsCode.primaryCyan}
                                                        textWeight={400}
                                                        textSize={16}
                                                        lineHeight={24}
                                                        td={true}
                                                        cursor="pointer"
                                                        clickHandler={() =>
                                                            downloadOrRedirect(
                                                                'redirect',
                                                                surveyPath.concat(surveyPathInUse)
                                                            )
                                                        }
                                                        label={
                                                            loading
                                                                ? `Loading...`
                                                                : surveyPath.concat(surveyPathInUse).slice(0, 55) +
                                                                  '...'
                                                        }
                                                        noWrap
                                                        customStyles={{
                                                            display: 'block',
                                                            marginRight: `${pxToVw(4)}vw`,
                                                        }}
                                                    />
                                                </div>
                                                <img src={line} alt="" />
                                                &nbsp;&nbsp;
                                                <LightTooltip
                                                    title={
                                                        <CommonLabel
                                                            label={copied ? 'Copied' : 'Copy'}
                                                            textColor={colorsCode.approved}
                                                            textWeight={500}
                                                            textSize={14}
                                                            lineHeight={20}
                                                        />
                                                    }
                                                >
                                                    <CopyIcon
                                                        className="copy"
                                                        onClick={() => copy(surveyPath.concat(surveyPathInUse))}
                                                    />
                                                </LightTooltip>
                                            </Linkfield>
                                        </Flex>
                                    </FlexColdiv>
                                    <QrcodeDiv>
                                        <FlexColumnGap gap={pxToVh(12)}>
                                            <Fragment>
                                                <FlexStart>
                                                    <CommonLabel
                                                        label="Preview on mobile"
                                                        textSize={18}
                                                        textColor={colorsCode.TextHeading}
                                                        textWeight={500}
                                                        lineHeight={28}
                                                    />
                                                </FlexStart>
                                                <CommonLabel
                                                    label="Open a QR code reader app on your mobile device >
                                            Scan this code > Open the link"
                                                    textSize={14}
                                                    textColor={colorsCode.TextHeading}
                                                    textWeight={400}
                                                    lineHeight={20}
                                                />
                                            </Fragment>
                                            <FlexStartGap gap={pxToVw(3)} style={{ alignItems: 'center' }}>
                                                <img
                                                    src={download}
                                                    className="img2"
                                                    alt=""
                                                    style={{ cursor: 'pointer' }}
                                                />
                                                <CommonLabel
                                                    label="DOWNLOAD QR CODE"
                                                    textSize={12}
                                                    textColor={colorsCode.primaryCyan}
                                                    textWeight={400}
                                                    lineHeight={16}
                                                    ls={pxToVw(0.6)}
                                                    clickHandler={() => downloadOrRedirect('download', src)}
                                                    customStyles={{ cursor: 'pointer' }}
                                                />
                                            </FlexStartGap>
                                        </FlexColumnGap>
                                        {loading ? `Loading...` : <img src={src} className="img1" alt="" />}
                                    </QrcodeDiv>
                                </LinkWrapper>
                            </>
                        ) : (
                            <FlexDiv>
                                <CommonChip
                                    value="Please select a minimum of 1 outcome or 1 practice under “question modules” section to preview survey link "
                                    bgColor={colorsCode.cyan100}
                                    color={colorsCode.Neutral80}
                                    onDeleteHandler={() => {}}
                                    iconEl={
                                        <InfoIcon
                                            sx={{
                                                width: `${pxToVh(12)}vh`,
                                                height: `${pxToVh(12)}vh`,
                                                path: {
                                                    fill: colorsCode.blueShade5,
                                                },
                                            }}
                                        />
                                    }
                                    hasStartIcon
                                    fontSize={`${pxToVh(13)}vh`}
                                    lineHeight={`${pxToVh(16)}vh`}
                                    fontWeight={400}
                                    padding={`${pxToVh(2)}vh ${pxToVw(4)}vw`}
                                    height="auto"
                                />
                            </FlexDiv>
                        )}
                    </Paper>
                </EditSurveyDiv>
                <EditSurveyDiv style={{ marginTop: '-5vh' }}>
                    <Paper elevation={2} sx={{ width: '83vw', maxWidth: '83vw', ...styles }}>
                        <TotalStatusCount state={finalizeList} AuthStore={AuthStore} si={si} />
                        <Actions
                            AuthStore={AuthStore}
                            state={finalizeList}
                            si={si}
                            search={handleSearch}
                            searchText={search}
                        />
                        <Flex className="flexClass">
                            <QuestionTable
                                state={finalizeList}
                                AuthStore={AuthStore}
                                handleOneClick={handleOneClick}
                                showImageStatus={showImageStatus}
                                handleStatus={handleStatus}
                                images={images}
                                handleReason={handleReason}
                                goToTab={goToTab}
                                handleAllClick={handleAllClick}
                                bg={COLORS}
                            />
                        </Flex>
                    </Paper>
                </EditSurveyDiv>
                {/* optional modules */}
                {am.length &&
                am.filter(({ is_included }) => is_included === true).length &&
                !isNpmTemplate &&
                !isTeiTemplate ? (
                    <OptionalModules
                        isHeaderRequired={false}
                        styles={styles}
                        isValuesReq
                        isOtherThanValue
                        hideOptionalQuestions
                        hideOutcomesAndPractices
                    />
                ) : null}
            </FlexColumnGap>
            {/* dialog modal */}
            {open ? (
                <Dialog
                    open={open}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVw(600)}vw`,
                            width: `${pxToVw(600)}vw`,
                        },
                    }}
                >
                    <DialogTitle>
                        <FlexCenterWithAlign>
                            <CommonLabel
                                label="Reason"
                                textColor={colorsCode.TextHeading}
                                textWeight={500}
                                textSize={24}
                                lineHeight={36}
                            />
                        </FlexCenterWithAlign>
                    </DialogTitle>
                    <DialogContent
                        sx={{
                            padding: `0 ${pxToVh(20)}vw`,
                        }}
                    >
                        <DialogContentText>
                            <InputField
                                ai="flex-start"
                                name="reason"
                                id="reason"
                                val={reason}
                                width={pxToVw(500)}
                                height={pxToVh(160)}
                                onChangeFn={handleChange}
                                disabled={AuthStore.isClientOrCst}
                                placeholder={'Add reason'}
                            />
                        </DialogContentText>
                    </DialogContent>
                    <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                        {AuthStore.isAdminOrCoach ? (
                            <CTA
                                size={16}
                                lh={24}
                                fw={400}
                                bg={colorsCode.PrimaryDeepBlue}
                                pt={8}
                                pl={16}
                                color={colorsCode.white}
                                onClick={handleSave}
                                disabled={!reason.trim()}
                            >
                                Save
                            </CTA>
                        ) : null}
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={handleClose}
                        >
                            Cancel
                        </CTA>
                    </DialogActionsStyled>
                </Dialog>
            ) : null}

            {finalizeOpen.open ? (
                <Dialog
                    open={finalizeOpen.open}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVh(590)}vh`,
                        },
                    }}
                >
                    <DialogContent
                        sx={{
                            padding: `${pxToVh(10)}vh ${pxToVh(20)}vw`,
                        }}
                    >
                        <IconButton
                            aria-label="close"
                            onClick={() => close(false)}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                            }}
                        >
                            <img src={CloseIcon} alt="" onClick={() => setFinalizeOpen({ open: false, status: '' })} />
                        </IconButton>
                        <DialogContentText>
                            <br />
                            <FlexColumnGap gap={pxToVh(16)}>
                                <FlexColumnGap
                                    gap={pxToVh(2)}
                                    className="dialogContent"
                                    style={{ alignItems: 'center', alignSelf: 'center' }}
                                >
                                    <CommonLabel
                                        label="You are about to finalize the survey"
                                        lineHeight={36}
                                        textSize={24}
                                        textWeight={500}
                                        textColor={colorsCode.TextHeading}
                                        customStyles={{
                                            textAlign: 'center',
                                        }}
                                    />
                                </FlexColumnGap>
                                <FlexColumnGap gap={pxToVh(4)} style={{ alignItems: 'center' }}>
                                    <CommonLabel
                                        label={LABELS.FINALIZE.message1}
                                        lineHeight={24}
                                        textSize={16}
                                        textWeight={400}
                                        textColor={colorsCode.TextHeading}
                                    />
                                    <CommonLabel
                                        label={LABELS.FINALIZE.message2}
                                        lineHeight={24}
                                        textSize={16}
                                        textWeight={400}
                                        textColor={colorsCode.TextHeading}
                                    />
                                </FlexColumnGap>
                                <FlexColumnGap gap="0.5" width={'100%'}>
                                    <DropdownMenu
                                        fullWidth={true}
                                        menuItems={updateStatusOptionsInUse}
                                        onChangeFn={handleOnChange}
                                        value={status_type}
                                        width={'100%'}
                                        name="status_type"
                                        placeholder="Select an option"
                                        wrapperStyles={{ width: '100%' }}
                                    />
                                </FlexColumnGap>
                                {status_type === LIVE_SCHEDULE ? (
                                    <>
                                        <FlexRowWidth gap="0.5" alignItems="flex-start">
                                            <FlexColumnGap width={'50%'}>
                                                <DatePickerComponent
                                                    setDate={handleDateChange}
                                                    date={date}
                                                    fullWidth
                                                    blockPreviousDates
                                                />
                                            </FlexColumnGap>
                                            <FlexColumnGap width={'50%'}>
                                                <TimeSelector
                                                    time={time}
                                                    headerInfo={''}
                                                    minutesStep={15}
                                                    setTimeValue={value =>
                                                        handleOnChange({
                                                            target: { name: 'time', value },
                                                        })
                                                    }
                                                />
                                            </FlexColumnGap>
                                        </FlexRowWidth>
                                        <FlexColumnGap width={'50%'}>
                                            <Autocomplete
                                                options={timezoneList}
                                                getOptionLabel={option => option.name}
                                                value={timezoneList.find(option => option.uuid === timezone) || null}
                                                onChange={(e, obj) => {
                                                    handleOnChange({
                                                        target: { name: 'timezone', value: obj.uuid },
                                                    });
                                                }}
                                                clearIcon={null}
                                                sx={{
                                                    '& .MuiOutlinedInput-root': {
                                                        padding: `0 0.28vw 0 ${pxToVw(16)}vw`,
                                                    },
                                                    '&&& input': {
                                                        paddingLeft: '0',
                                                    },
                                                    '&& .MuiAutocomplete-endAdornment': {
                                                        right: `${pxToVw(16)}vw`,
                                                    },
                                                    borderColor: colorsCode.Neutral30,
                                                    width: '100%',
                                                }}
                                                renderTags={() => null}
                                                renderInput={params => (
                                                    <TextField
                                                        {...params}
                                                        name="timezone"
                                                        placeholder="Select a timezone"
                                                        sx={{
                                                            '& .MuiInputBase-root': {
                                                                height: '5.67vh',
                                                                borderColor: colorsCode.Neutral30,
                                                                borderRadius: '2px',
                                                                fontSize: `2.2vh`,
                                                            },
                                                        }}
                                                    />
                                                )}
                                                popupIcon={<CustomPopupIcon />}
                                            />
                                        </FlexColumnGap>
                                    </>
                                ) : null}
                            </FlexColumnGap>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActionsStyled sx={{ justifyContent: 'center', marginBottom: '4vh', marginTop: '3vh' }}>
                        <CTA
                            pl={16}
                            pt={8}
                            lh={24}
                            size={16}
                            bg={colorsCode.white}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() =>
                                setFinalizeOpen({
                                    open: false,
                                    status: '',
                                })
                            }
                        >
                            Cancel
                        </CTA>
                        <CTA
                            disabled={!anythingUpdated || enableDisable()}
                            pl={16}
                            pt={8}
                            lh={24}
                            size={16}
                            bg={colorsCode.PrimaryDeepBlue}
                            color={colorsCode.white}
                            onClick={() => {
                                changeSurveyStatus(surveyConfig);
                            }}
                        >
                            Finalize survey
                        </CTA>
                    </DialogActionsStyled>
                </Dialog>
            ) : null}
            {isOpen ? (
                <SuccessModal
                    open={isOpen}
                    onClose={handleCloseModal}
                    title={'Success'}
                    message={
                        status_type === LIVE
                            ? 'Survey status changed to "LIVE" successfully.'
                            : `Your survey is scheduled to be live on ${moment(date, dateFormat).format(
                                  'Do MMMM'
                              )} at ${moment(time, timeFormat).format('h:mm a')}`
                    }
                    type={'success'}
                    confirmFlag={false}
                    btnCancel="Cancel"
                />
            ) : null}
            {showDeletePopUp.show ? (
                <Dialog
                    open={showDeletePopUp.show}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVw(520)}vw`,
                            width: `${pxToVw(520)}vw`,
                            borderRadius: '15px',
                        },
                    }}
                >
                    <FlexRow justifyContent="flex-end" margin="1vw 1vw 0 0">
                        <Icon
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: `${pxToVw(16)}vw`,
                                top: `${pxToVh(16)}vh`,
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseIcon}
                                alt=""
                                style={{
                                    height: `${pxToVh(20)}vh`,
                                    width: ` ${pxToVw(20)}vw`,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowDeletePopUp({ show: false, uuid: '' })}
                            />
                        </Icon>
                    </FlexRow>
                    <DialogTitle>
                        <FlexCenterWithAlign>
                            <Img image={RedWarningIcon} h={pxToVh(40)} w={pxToVw(40)} alt="" />
                        </FlexCenterWithAlign>
                        <FlexCenterWithAlign>
                            <CommonLabel
                                textSize={20}
                                textColor={colorsCode.TextHeading}
                                textWeight={500}
                                lineHeight={36}
                                label="Reject question"
                            />
                        </FlexCenterWithAlign>
                        <FlexColumnCenter gap="2" margin="20px 0">
                            <FlexCenterWithAlign>
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={300}
                                    textAlign="center"
                                    label="This question has display logic applied to it. Rejecting it will also reject the applied logic."
                                />
                            </FlexCenterWithAlign>
                            <FlexCenterWithAlign>
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={300}
                                    textAlign="center"
                                    label="Are you sure you want to continue?"
                                />
                            </FlexCenterWithAlign>
                        </FlexColumnCenter>
                    </DialogTitle>

                    <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() => setShowDeletePopUp({ show: false, uuid: '' })}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            onClick={() => {
                                const requestParams = [
                                    {
                                        question_id: showDeletePopUp?.uuid,
                                        remarks: reason,
                                        is_rejected: true,
                                        question_status: 'Rejected',
                                    },
                                ];
                                dispatch(SaveStatusQuestions({ surveyId: si?.uuid, requestParams, editReason: false }));
                                setShowDeletePopUp({ show: false, uuid: '' });
                            }}
                        >
                            Reject
                        </CTA>
                    </DialogActionsStyled>
                </Dialog>
            ) : null}
        </Fragment>
    );
};

export const FlexDiv = styled(Flex)`
    align-items: flex-start;
    gap: ${pxToVw(16)}vw;
    padding: ${pxToVh(20)}vh ${pxToVw(40)}vw;
`;

export const LinkWrapper = styled(Flex)`
    align-items: flex-start;
    gap: ${pxToVw(16)}vw;
    padding: ${props =>
        props.showTopPadding ? `${pxToVh(40)}vh ${pxToVw(40)}vw` : `0 ${pxToVw(40)}vw ${pxToVh(40)}vh ${pxToVw(40)}vw`};
`;

export const FlexColdiv = styled(FlexColumn)`
    gap: ${pxToVh(20)}vh;
    padding: ${pxToVw(32)}vh ${pxToVw(24)}vw;
    justify-content: center;
    align-self: stretch !important;
    border-radius: 4px;
    flex: 1 0 0;
    border: 1px solid ${colorsCode.Neutral10};
    && .img {
        height: ${pxToVh(16)}vh;
        width: ${pxToVw(16)}vw;
        cursor: pointer;

        path {
            fill: ${colorsCode.PrimaryDeepBlue};
        }
    }
`;

export const Linkfield = styled(FlexBetween)`
    padding: ${pxToVh(10)}vh ${pxToVw(16)}vw;
    align-items: center;
    border-radius: 4px;
    border: 1px solid ${colorsCode.Neutral10};
    .copy {
        cursor: pointer;
        path {
            fill: ${colorsCode.PrimaryDeepBlue};
        }
    }
`;

export const QrcodeDiv = styled(FlexStart)`
    gap: ${pxToVw(24)}vw;
    padding: ${pxToVh(32)}vh ${pxToVw(24)}vw ${pxToVw(24)}vw ${pxToVw(24)}vw;
    align-self: stretch;
    border-radius: 4px;
    flex: 1 0 0;
    border: 1px solid ${colorsCode.Neutral10};
    .img1 {
        height: ${pxToVh(106)}vh;
        width: ${pxToVw(106)}vw;
    }
    .img2 {
        height: ${pxToVh(12)}vh;
        width: ${pxToVw(12)}vw;
    }
`;

export const StatusDiv = styled(FlexCenter)`
    padding: ${pxToVh(2)}vh ${pxToVw(8)}vw;
    align-items: center !important;
    gap: 4px;
    border-radius: 15px;
`;

export const DialogActionsStyled = styled(DialogActions)`
    gap: ${pxToVw(24)}vw;

    && > :not(:first-of-type) {
        margin-left: 0;
    }
`;

export const TooltipWrapper = styled(FlexColumnGap)`
    ul {
        display: flex;
        flex-direction: column;
        gap: ${pxToVh(10)}vh;
        padding: 0 1rem;
        margin: 0;
    }

    .listItem {
        font-size: ${pxToVh(14)}vh;
        line-height: ${pxToVh(20)}vh;
        font-weight: 400;
    }

    .midLine {
        height: 1px;
        width: 100%;
        background: ${colorsCode.Neutral10};
    }
`;

export default memo(Finalize);
