import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
    loading: false,
    org_name: '',
    survey_title: '',
    industry: '',
    emp_count: '',
    survey_base: '',
    select_translation: [],
    eng_exclude: false,
    link: '',
    owners: [],
    sdm: '',
    managers: '',
    error: false,
    errorMessage: '',
    template: '',
    org_id: '',
};

const BasicInformationSlice = createSlice({
    name: 'basicinfo',
    initialState: INITIAL_STATE,
    reducers: {
        setTitle: (state, { payload }) => {
            state.survey_title = payload;
        },
        setOrgname: (state, { payload }) => {
            state.org_name = payload;
        },
        setOrgId: (state, { payload }) => {
            state.org_id = payload;
        },
        setIndustry: (state, { payload }) => {
            state.industry = payload;
        },
        setEmpCount: (state, { payload }) => {
            state.emp_count = payload;
        },
        setBaseLang: (state, { payload }) => {
            state.survey_base = payload;
        },
        setTranslation: (state, { payload }) => {
            state.select_translation = payload;
        },
        setEngExclude: (state, { payload }) => {
            state.eng_exclude = payload;
        },
        setLink: (state, { payload }) => {
            state.link = payload;
        },
        setOwners: (state, { payload }) => {
            if (payload) {
                state.owners = [...state.owners, payload];
            }
        },
        setSdm: (state, { payload }) => {
            if (payload) {
                state.sdm = [...state.sdm, payload];
            }
        },
        setManagers: (state, { payload }) => {
            if (payload) {
                state.managers = [...state.owners, payload];
            }
        },
        setTemplate: (state, { payload }) => {
            state.template = payload;
        },
    },
});

export const {
    setTitle,
    setOrgname,
    setTranslation,
    setIndustry,
    setEmpCount,
    setEngExclude,
    setOwners,
    setManagers,
    setSdm,
    setLink,
    setBaseLang,
    setTemplate,
    setOrgId,
} = BasicInformationSlice.actions;
export default BasicInformationSlice.reducer;
