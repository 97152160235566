import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import React from 'react';
import CloseIcon from '../../../../assets/img/close.svg';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import Text from '../../../KnowledgeCenter/Text';
import { CommonLabel, SwitchOnOff } from '../../../../common/FormInputs/Common';
import { CTA, FlexBetween, FlexCenterWithAlign, FlexColumnWithAlign, FlexRow } from '../../../../common/styled/styled';
import styled from 'styled-components';
import InputField from '../../../../common/FormInputs/InputField';
import LightTooltip from '../../../../common/LightTooltip';
import InfoIcon from '@mui/icons-material/Info';
import { cloneDeep } from 'lodash';
import { saveSubGroupQuestions } from '../../../../store/actions/Surveys/action';
import { useDispatch } from 'react-redux';

const EditInfluencerQuest = ({ open, close, survey_id, influencerQuestions, subGroupLoader = false }) => {
    const [questionList, setQuestionList] = React.useState([]);
    const dispatch = useDispatch();

    React.useEffect(() => {
        setQuestionList(influencerQuestions);
    }, [influencerQuestions]);

    const handleSwitchChange = (e, index, key) => {
        const { checked } = e.target;
        const updatedQuestionList = cloneDeep(questionList);
        updatedQuestionList[index].options.find(({ option_value }) => option_value === key).is_active = checked;

        setQuestionList(updatedQuestionList);
    };

    const handleSaveQuestion = () => {
        dispatch(
            saveSubGroupQuestions({
                survey_id,
                payload: { ...questionList[0] },
            })
        );
        close();
    };

    const renderQuestionList = questionList?.map(({ question_text, question_order, options }, index) => {
        const opt_out = options.find(({ option_value }) => option_value === 'wish_not_to_be_included');
        const preferred = options.find(({ option_value }) => option_value === 'prefer_not_to_answer');
        return (
            <Card key={question_order}>
                <FlexColumnWithAlign gap="1">
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize="14px"
                        label={`Question ${index + 1}`}
                        textAlign="center"
                        fontWeight={400}
                        lineHeight="20px"
                    />
                    <InputField
                        placeholder="Enter your response"
                        fullWidth={true}
                        height={pxToVh(80)}
                        ai="flex-start"
                        val={question_text}
                        multiline
                        disabled={true}
                    />
                </FlexColumnWithAlign>
                <FlexBetween gap="1">
                    <FlexRow gap="0.5">
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize="16px"
                            label='Add "Prefer not to answer"'
                            textAlign="center"
                            fontWeight={400}
                            lineHeight="24px"
                        />
                        <SwitchOnOff
                            name="preferred"
                            id="preferred"
                            onChangeHandler={e => {
                                handleSwitchChange(e, index, 'prefer_not_to_answer');
                            }}
                            checkedFlag={preferred?.is_active}
                        />
                    </FlexRow>
                    <FlexRow gap="0.5">
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize="16px"
                            label='Allow user to "opt-out" of being nominate'
                            textAlign="center"
                            fontWeight={400}
                            lineHeight="24px"
                        />
                        <LightTooltip
                            title="Respondents can have a choice to opt-out of the results"
                            placement="top"
                            arrow
                        >
                            <InfoIcon
                                sx={{
                                    color: colorsCode.Neutral30,
                                    width: '16px',
                                    height: '16px',
                                }}
                            />
                        </LightTooltip>
                        <SwitchOnOff
                            name="opt_out"
                            id="opt_out"
                            onChangeHandler={e => {
                                handleSwitchChange(e, index, 'wish_not_to_be_included');
                            }}
                            checkedFlag={opt_out?.is_active}
                        />
                    </FlexRow>
                </FlexBetween>
            </Card>
        );
    });
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(650)}vw`,
                    width: `${pxToVw(650)}vw`,
                    borderRadius: '8px',
                    padding: '0 15px',
                },
            }}
        >
            <img
                src={CloseIcon}
                alt=""
                style={{
                    height: `${pxToVh(16)}vh`,
                    width: ` ${pxToVw(16)}vw`,
                    cursor: 'pointer',
                    right: `${pxToVw(10)}vw`,
                    top: `${pxToVh(8)}vh`,
                    position: 'absolute',
                }}
                onClick={close}
            />
            <DialogTitle sx={{ marginTop: '15px' }}>
                <FlexColumnWithAlign gap="1">
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize="24px"
                        label={`Edit influencer question`}
                        textAlign="center"
                        fontWeight={500}
                        lineHeight="36px"
                    />
                    <CommonLabel
                        fontColor={colorsCode.TextHeading}
                        fontSize="16px"
                        label={`The default minimum value is 1 and the maximum value is 10. If you need to change these values, please contact Helpdesk.`}
                        fontWeight={400}
                        lineHeight="24px"
                    />
                </FlexColumnWithAlign>
            </DialogTitle>
            <DialogContent sx={{ marginTop: '15px' }}>
                {subGroupLoader ? (
                    <FlexCenterWithAlign>
                        <CircularProgress />
                    </FlexCenterWithAlign>
                ) : (
                    <FlexColumnWithAlign gap="3">{questionList?.length && renderQuestionList}</FlexColumnWithAlign>
                )}
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'start', padding: '10px 25px 30px' }}>
                <FlexColumnWithAlign gap="4">
                    {/* {questionList?.length < 10 && (
                        <CTA pl={15} pt={6} pr={15} bcolor={colorsCode.SecondaryDeepBlue200} bg={colorsCode.white}>
                            <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                <Img image={PlusSvg} h={pxToVh(14)} w={pxToVw(14)} />
                                <CommonLabel
                                    label="Add another question"
                                    textColor={colorsCode.PrimaryDeepBlue}
                                    textSize={14}
                                    lineHeight={20}
                                    textWeight={400}
                                    cursor="pointer"
                                    clickHandler={() => {
                                        setQuestionList([...questionList, newQuestion]);
                                    }}
                                />
                            </FlexStartGap>
                        </CTA>
                    )} */}
                    <CTA
                        size={14}
                        lh={20}
                        fw={400}
                        bg={colorsCode.PrimaryDeepBlue}
                        color={colorsCode.white}
                        onClick={handleSaveQuestion}
                    >
                        Save
                    </CTA>
                </FlexColumnWithAlign>
            </DialogActions>
        </Dialog>
    );
};

export default EditInfluencerQuest;

const Card = styled.div`
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px, rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
    width: 100%;
    padding: 20px;
    border-radius: 8px;
    margin: 1px;
    display: flex;
    flex-direction: column;
    gap: 20px;
`;
