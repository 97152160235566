import { createSlice } from '@reduxjs/toolkit';
import { getGlossary, updateGlossary, addCustomGlossary } from '../../actions/Surveys/action';

export const INITIAL_STATE = {
    loading: false,
    glossaries: { CUSTOM: { records: [] }, STANDARD: { records: [] } },
};

const SurveyOverview = createSlice({
    initialState: INITIAL_STATE,
    name: 'SurveyOverview',
    extraReducers: builder => {
        builder
            .addCase(getGlossary.pending, state => {
                state.loading = true;
            })
            .addCase(getGlossary.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.glossaries = payload;
            })
            .addCase(getGlossary.rejected, state => {
                state.loading = false;
            })
            .addCase(updateGlossary.pending, state => {
                state.loading = true;
            })
            .addCase(updateGlossary.fulfilled, state => {
                state.loading = false;
            })
            .addCase(updateGlossary.rejected, state => {
                state.loading = false;
            })
            .addCase(addCustomGlossary.pending, state => {
                state.loading = true;
            })
            .addCase(addCustomGlossary.fulfilled, (state, { payload }) => {
                const { data, index } = payload;
                state.loading = false;
                state.glossaries['CUSTOM'].records[index].uuid = data.uuid;
                state.glossaries['CUSTOM'].records[index].is_active = data.is_active;
                delete state.glossaries['CUSTOM'].records[index].local;
                delete state.glossaries['CUSTOM'].records[index].is_edited;
            })
            .addCase(addCustomGlossary.rejected, state => {
                state.loading = false;
            });
    },
    reducers: {
        reset: () => {},
        updateStandardGlossary: (state, { payload }) => {
            const { uuid, update_obj } = payload;
            const recordIndex = state.glossaries['STANDARD'].records.findIndex(el => el.uuid === uuid);
            state.glossaries['STANDARD'].records[recordIndex][update_obj.field] = update_obj.value;
        },
        setCustomGlossary: (state, { payload }) => {
            state.glossaries['CUSTOM'].records = payload;
        },
    },
});

export const { reset, updateStandardGlossary, setCustomGlossary } = SurveyOverview.actions;
export default SurveyOverview.reducer;
