/* istanbul ignore file */
import React, { Suspense, useState } from 'react';
import Loader from '../Loader';
import AuthStore from '../../common/AuthStore';
import Unauthenticated from '../Unauthenticated';
import styled from 'styled-components';
import Edit from '../../assets/img/pen.svg';
import { colorsCode, pxToVh, pxToVw } from '../../common/colors';
import { ChatBot } from '@ohi/genai-fe';
import Text from '../KnowledgeCenter/Text';
import Feedback from '../Feedback/Feedback';
import { ShowMessage } from '../../common/FormInputs/Snackbar';

const SecureRoutes = ({ children, isAdmin = false, canAccess = true, isLoading = false }) => {
    const [showFeedbackModal, setShowFeedbackModal] = useState(false);
    const [feedbackSubmitRes, setFeedbackSubmitRes] = useState('');

    const refreshToken = AuthStore.refreshToken;
    const [showUploadMessage, setUploadMessage] = useState(null);

    if (isLoading) {
        return <Loader showBackground position="absolute" background="transparent" />;
    }
    if (canAccess !== undefined && canAccess === false) {
        return <Unauthenticated />;
    } else if (isAdmin && !AuthStore.isAdmin) {
        return <Unauthenticated />;
    }
    return (
        <Suspense fallback={<Loader position="absolute" showBackground background="transparent" />}>
            <FeedbackDiv onClick={() => setShowFeedbackModal(true)}>
                <img src={Edit} />
                <Text
                    fontColor={colorsCode.NeutralWhite}
                    fontSize={16}
                    lineHeight={20}
                    label="Feedback"
                    fontWeight={400}
                    letterSpacing="1px"
                />
            </FeedbackDiv>
            {!AuthStore?.isClient ? <ChatBot userAuthToken={refreshToken} userName={AuthStore.name} /> : null}
            {children}
            {showFeedbackModal ? (
                <Feedback
                    onClose={() => setShowFeedbackModal(false)}
                    showFeedbackModal={showFeedbackModal}
                    setFeedbackSubmitRes={val => setFeedbackSubmitRes(val)}
                    setUploadMessage={setUploadMessage}
                />
            ) : null}
            {feedbackSubmitRes?.length ? (
                <ShowMessage
                    open={feedbackSubmitRes?.length}
                    type={feedbackSubmitRes?.length ? 'success' : 'error'}
                    message={feedbackSubmitRes}
                    close={() => setFeedbackSubmitRes('')}
                />
            ) : null}
            {showUploadMessage && (
                <ShowMessage
                    open={showUploadMessage?.length}
                    type="success"
                    message={showUploadMessage}
                    close={() => setUploadMessage(null)}
                />
            )}
        </Suspense>
    );
};

const FeedbackDiv = styled.div`
    position: fixed;
    top: 35%;
    right: 16px;
    transform: translateY(0) rotate(270deg);
    transform-origin: right;
    white-space: nowrap;
    font-size: 16px;
    padding: 8px 15px;
    border-radius: 5px;
    background-color: ${colorsCode.neutral60};
    z-index: 16;
    display: flex;
    gap: 8px;
    img {
        width: ${pxToVw(12)};
        height: ${pxToVh(12)};
        transform: rotate(90deg);
    }
    &:hover {
        box-shadow: 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
        cursor: pointer;
    }
`;

export default SecureRoutes;
