import { Typography, Chip, Switch } from '@mui/material';
import React from 'react';
import { colorsCode, pxToVh, pxToVw } from '../colors';
import deleteIcon from '../../assets/img/Focus Area.svg';
import { Icon } from '@mds/mds-reactjs-library';
import { Bottomline, FlexCenter, FlexColumnGap, FlexAlignCenter, BottomSubline } from '../styled/styled';
import LightTooltip from '../LightTooltip';
import InfoIcon from '@mui/icons-material/Info';
import themeStyles from '../../components/Dashboard/themes';
import styled from 'styled-components';

export const CommonLabel = ({
    label,
    name,
    clickHandler = () => {},
    textSize,
    textWeight,
    textColor,
    lineHeight,
    td = false,
    ls = '',
    customStyles = {},
    noWrap = false,
    onMouseEnter = () => {},
    cursor = 'default',
    title = '',
    textAlign,
    component,
    tooltip = false,
    enableEllipsis,
    showRequiredSymbol = false,
}) => {
    const globalStyles = themeStyles();
    const style = {
        color: textColor,
        fontSize: `${pxToVh(textSize)}vh`,
        lineHeight: lineHeight ? `${pxToVh(lineHeight)}vh` : 'normal',
        fontStyle: 'normal',
        textDecoration: td ? td : 'none',
        letterSpacing: ls ? `${ls}px` : 'normal',
        cursor: cursor || 'default',
        ...customStyles,
    };

    return (
        <FlexAlignCenter>
            <Typography
                name={name}
                fontWeight={textWeight}
                onClick={clickHandler}
                className={`${enableEllipsis ? globalStyles.ellipsisCss : {}} ${
                    showRequiredSymbol ? globalStyles.isRequired : {}
                } `}
                sx={style}
                component={component || 'span'}
                dangerouslySetInnerHTML={{ __html: label }}
                noWrap={noWrap}
                onMouseEnter={onMouseEnter}
                title={title}
                align={textAlign}
            />
            {tooltip && (
                <LightTooltip title={<AdditionQuestionTooltipText />} placement="right" arrow>
                    <InfoIcon
                        sx={{
                            color: colorsCode.Neutral30,
                            width: `${pxToVh(15)}vh`,
                            height: `${pxToVh(15)}vh`,
                            verticalAlign: 'middle',
                            marginLeft: `${pxToVh(4)}vh`,
                            path: {
                                fill: colorsCode.Neutral30,
                            },
                        }}
                    />
                </LightTooltip>
            )}
        </FlexAlignCenter>
    );
};

export const CommonChip = ({
    value,
    onDeleteHandler = () => {},
    clickHandler = null,
    bgColor,
    fontWeight,
    color,
    canDelete,
    iconEl,
    hasStartIcon,
    lineHeight,
    fontSize,
    height,
    padding,
    width,
    borderRadius,
    cursor = 'default',
}) => {
    const style = {
        background: bgColor || colorsCode.chipColor,
        color: color || colorsCode.PrimaryDeepBlue,
        fontWeight: fontWeight || 500,
        lineHeight: lineHeight || '2.97vh',
        fontSize: fontSize || null,
        padding: padding || null,
        height: height || '2.8vh',
        marginRight: '0.42vw',
        marginTop: '0.42vh',
        borderRadius: borderRadius || '3px',
        width: width || 'auto',
        cursor: cursor,
        alignItems: 'center',
        '& .MuiChip-label': {
            textOverflow: 'initial',
            whiteSpace: 'break-spaces',
        },
    };
    return (
        <Chip
            label={value}
            size="small"
            sx={style}
            onClick={clickHandler}
            deleteIcon={!canDelete ? <div /> : <img src={deleteIcon} alt="" />}
            onDelete={onDeleteHandler}
            icon={hasStartIcon ? iconEl : null}
        />
    );
};

export const SwitchOnOff = ({ disabled = false, onChangeHandler = () => {}, checkedFlag = false, name, id }) => {
    return (
        <Switch
            disabled={disabled}
            onChange={onChangeHandler}
            checked={checkedFlag}
            inputProps={{ 'aria-label': 'controlled' }}
            name={name}
            title="switch"
            id={id}
            sx={{
                width: '40px',
                height: '20px',
                padding: '0',
                '& .MuiSwitch-switchBase': {
                    color: colorsCode.white, //thumb color when non-selected
                    margin: '2.5px 4px',
                    padding: '0',
                    '&.Mui-checked': {
                        color: colorsCode.white, //thumb color when selected
                        marginLeft: '0px',
                        transform: 'translateX(22px)',
                        '& + .MuiSwitch-track': {
                            opacity: 'unset',
                            backgroundColor: '#021b4b', //track color when selected
                        },

                        '&.Mui-disabled + .MuiSwitch-track': {
                            backgroundColor: colorsCode.deepBlue100, //track color when selected(disabled)
                        },
                    },
                    '&.Mui-disabled .MuiSwitch-thumb': {
                        color: colorsCode.neutral02, //thumb color when (disabled)
                    },
                    '&.Mui-disabled + .MuiSwitch-track': {
                        backgroundColor: colorsCode.Neutral10, //track color when non-selected(disabled)
                    },
                },
                '& .Mui-Disabled': {
                    color: 'white',
                },
                '& .MuiSwitch-thumb': {
                    height: '15px',
                    width: '15px',
                },
                '& .MuiSwitch-track': {
                    height: '20px',
                    borderRadius: '10px',
                    width: '40px',
                    opacity: 'unset !important',
                    backgroundColor: colorsCode.backgroundDisabled, //track color when non-selected
                },
            }}
        />
    );
};

export const Img = ({ h, w, image, cursor = 'default', clickEvent = () => {}, alt = '', color }) => {
    return (
        <Icon
            src={image}
            alt={alt}
            style={{
                height: `${h}vh`,
                width: `${w}vw`,
                cursor: cursor,
                color: color,
            }}
            onClick={clickEvent}
        />
    );
};

export const Pagetab = ({ active, label, clickHandler, hasError, textSize = 16, textEdit }) => {
    const { PrimaryDeepBlue, Neutral80 } = colorsCode;

    return (
        <FlexCenter
            style={{
                gap: `${pxToVw(10)}vw`,
                // alignItems: `center`,
            }}
        >
            <TabWrapper gap={pxToVh(4)} textEdit={textEdit}>
                <CommonLabel
                    textColor={active ? PrimaryDeepBlue : Neutral80}
                    textSize={textSize}
                    textWeight={active ? 400 : 300}
                    lineHeight={24}
                    label={label}
                    cursor="pointer"
                    clickHandler={clickHandler}
                    customStyles={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                />
                {active ? <Bottomline error={hasError} /> : null}
            </TabWrapper>
        </FlexCenter>
    );
};

export const PageSubtab = ({ active, label, clickHandler, hasError, textEdit }) => {
    const { PrimaryDeepBlue, Neutral80 } = colorsCode;

    return (
        <FlexCenter
            style={{
                gap: `${pxToVw(10)}vw`,
                // alignItems: `center`,
            }}
        >
            <TabWrapper gap={pxToVh(4)} textEdit={textEdit}>
                <CommonLabel
                    textColor={active ? PrimaryDeepBlue : Neutral80}
                    textSize={14}
                    textWeight={active ? 400 : 300}
                    lineHeight={24}
                    label={label}
                    cursor="pointer"
                    clickHandler={clickHandler}
                    customStyles={{
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                    }}
                />
                {active ? <BottomSubline error={hasError} /> : null}
            </TabWrapper>
        </FlexCenter>
    );
};

export const AdditionQuestionTooltipText = () => {
    return (
        <React.Fragment>
            <CommonLabel
                textSize={12}
                textColor={colorsCode.Neutral80}
                textWeight={400}
                lineHeight={16}
                label={`The questions can consist of the following characters:`}
            />
            <ul
                style={{
                    marginTop: 0,
                    marginBottom: 0,
                    paddingLeft: `${pxToVw(12)}vw`,
                    fontSize: `${pxToVh(12)}vh`,
                    fontWeight: 400,
                    lineHeight: `${pxToVh(16)}vh`,
                }}
            >
                <li>Letters a to z (both lowercase and uppercase)</li>
                <li>Numbers 0 to 9</li>
                <li>{`Special characters ' " ? . _ ( ) . , / # $ % & ! - `}</li>
            </ul>
        </React.Fragment>
    );
};

export const TabWrapper = styled(FlexColumnGap)`
    align-items: center;
    padding: 0 ${pxToVw(8)}vw;

    &:nth-child(1) {
        padding-left: 0;
        padding-right: ${props => (props.textEdit ? '0' : null)};
    }
`;

export const SubTabdiv = styled.div`
    padding: 24px 40px 0px 32px;
    border-bottom: 1px solid var(--neutral-10, #e6e6e6);
    align-self: stretch;
`;
