import React, { useEffect } from 'react';
import { Box, Typography } from '@mui/material';
import { FlexRow } from '../../../../common/styled/styled';
import ClientImg from '../../../../assets/img/client.svg';
import UsersImg from '../../../../assets/img/users.svg';
import themeStyles from '../../../Dashboard/themes';
import { useDispatch, useSelector } from 'react-redux';
import { SurveyTemplatesListing } from '../../../../store/actions/Client/actions';
import { LIMIT } from '../../../../global-constants';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { colorsCode, pxToVw } from '../../../../common/colors';
import { CommonLabel } from '../../../../common/FormInputs/Common';

export const OverViewComponent = ({ totalUsersCount, totalClientsCount }) => {
    const styles = themeStyles();
    const dispatch = useDispatch();
    const { surveyTemplates } = useSelector(state => state.clientReducer);

    useEffect(() => {
        if (!surveyTemplates.length) {
            dispatch(SurveyTemplatesListing({ page: 1, limit: LIMIT }));
        }
    }, []);

    return (
        <FlexRowWrap style={{ marginTop: '3.6vh', marginBottom: '9vh' }} data-testid="overview">
            <Box className={`${styles.sectionHeaderBlue}`}>
                <FlexRow>
                    <img src={UsersImg} alt="clients" />
                    <CommonLabel
                        textSize={14}
                        textWeight={400}
                        lineHeight={20}
                        textColor={colorsCode.TextSubtle}
                        label="Total Users"
                    />
                </FlexRow>
                <Typography className={`${styles.txtClient}`}>{totalUsersCount}</Typography>
            </Box>
            <Box className={`${styles.sectionHeaderBlue}`}>
                <FlexRow>
                    <img src={ClientImg} alt="users" />
                    <CommonLabel
                        textSize={14}
                        textWeight={400}
                        lineHeight={20}
                        textColor={colorsCode.TextSubtle}
                        label="Total Clients"
                    />
                </FlexRow>
                <Typography className={`${styles.txtClient}`}>{totalClientsCount}</Typography>
            </Box>
        </FlexRowWrap>
    );
};

const FlexRowWrap = styled(FlexRow)`
    gap: ${pxToVw(24)}vw !important;
`;

OverViewComponent.propTypes = {
    totalUsersCount: PropTypes.number.isRequired,
    totalClientsCount: PropTypes.number.isRequired,
};
export default OverViewComponent;
