import {
    Menu,
    MenuItem,
    Paper,
    Table,
    TableBody,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { Fragment, useEffect, useState } from 'react';
import { Flex, FlexColumnGap, FlexEnd, FlexRow, TableCellStyled } from '../../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import {
    GetAllSurveyListAction,
    GetAllSurveys,
    DownloadMonitorFiles,
    SurveyTemplatesLang,
    ExportAllSurvey,
    UpdateSurveyStatus,
} from '../../../../store/actions/Surveys/action';
import actions from '../../../../assets/img/Menu-5.svg';
import arrowRight from '../../../../assets/img/Arrow-right.svg';
import moment from 'moment';
import PaginationComponent from '../../../User/PaginationComponent';
import { cloneDeep, debounce, filter } from 'lodash';
import {
    SURVEY_STATUS,
    SURVEY_STATUS_UI,
    SURVEY_TYPE,
    THROTTLE_TIME,
    maintenanceModeList,
    timeFormat,
    dateFormat,
} from '../../../../global-constants';
import styled from 'styled-components';
import DashboardFilters from './DashboardFilters';
import { useSelector } from 'react-redux';
import { Badge } from '../../../Surveys/partials/setup-survey/Badge';
import { CommonLabel, Pagetab } from '../../../../common/FormInputs/Common';
import { getResponseRatePercent, renderColoredChips, sanitize } from '../../../../utils/functions';
import { FlexInline } from '../../../User/partials/Client/SurveyTemplates';
import { resetDataExported } from '../../../../store/reducers/slice/SurveySlice';
import Outline24MoveLayerDown from '@mds/mds-icons/icons/svg/outline-24-move-layer-down.svg';
import { Icon } from '@mds/mds-reactjs-library';
import { createPortal } from 'react-dom';
import { DataExportModal } from './DataExportModal';
import AuthStore from '../../../../common/AuthStore';
import { api } from '../../../../http/api';
import { Endpoints } from '../../../../http/Endpoints';
import ConfirmationPopUp from '../../../ConfirmationPopUp/ConfirmationPopUp';
import SurveyExportModal from '../../../SurveyExportModal';
import DuplicateSurveyModal from './DuplicateSurveyModal';
import Loader from '../../../Loader';
import { UpdateStatus } from '../../Type/UpdateStatus';

const ManageSurveys = ({
    dispatchAction,
    data = [],
    mySurveys = [],
    allSurveys = [],
    allTotal,
    myTotal,
    handleResume,
    handleMonitorSurvey = () => {},
    handleTextEdit = () => {},
    templatesLang,
    setShowTest = () => {},
    handleDuplicate,
}) => {
    const surveyStatuses = Object.values(SURVEY_STATUS_UI).map(item => ({ is_selected: false, name: item }));
    const SurveyTypes = Object.values(SURVEY_TYPE).map(item => ({ is_selected: false, name: item }));

    const initialSelectedFilters = {
        status: [],
        link_type: [],
        template_type: [],
        survey_language: [],
        is_test: false,
        maintenance_mode: 'Both',
    };
    const initialParams = {
        limit: 10,
        page: 1,
        searchParam: '',
        dateRange: { startDate: null, endDate: null },
    };
    const { surveyTemplates: st = [] } = useSelector(state => state.clientReducer);
    const initialFilters = {
        surveyTemplatesList: st?.records || [],
        statusList: surveyStatuses,
        maintenanceModeList: maintenanceModeList,
        typesList: SurveyTypes,
        languages: [],
        localShowTest: false,
    };

    const [anchorEl, setAnchorEl] = useState(false);
    const [all, setAllState] = useState(initialParams);
    const [allSurveyData, setAllSurveyData] = useState([]);
    const [mySurveyData, setMySurveyData] = useState([]);
    const [selectedSurveyId, setSelectedSurveyId] = useState('');
    const [openUpdateStatusModal, setOpenUpdateStatusModal] = useState(false);
    const [params, setId] = useState({
        uuid: '',
        survey_template: '',
        status: '',
        langid: '',
    });
    const [filters, setFilters] = useState(initialSelectedFilters); // The one sent with API
    const [dataExportModal, setDataExportModal] = useState({
        open: false,
        surveyId: '',
    });
    const [duplicateModal, setDuplicateModal] = useState({
        open: false,
        surveyId: '',
    });
    const [filtersObj, setFiltersObj] = useState(initialFilters); // The one used to show selection/deselection in filter modal
    const [startDate, setStartDate] = useState(new Date('April 1, 2023'));
    const [endDate, setEndDate] = useState(new Date());
    const isAdmin = AuthStore.isAdmin;

    const {
        data: { can_view_all_survey = '', can_view_my_survey },
    } = useSelector(state => state.permissionReducer);
    const { errorMessage, dataExported, surveyExportLoader } = useSelector(state => state.surveyReducer);
    const [tab, setTab] = useState(can_view_all_survey.length ? 1 : 2);
    const menuOpen = Boolean(anchorEl);
    const { LIVE, DRAFT, UNDER, COMPLETED, REVIEWED, LIVE_SCHEDULE } = SURVEY_STATUS;
    const ifAllOrMySurvey = tab === 1 ? allSurveyData : mySurveyData;
    const [surveyExport, setSurveyExport] = useState({
        open: false,
        type: 'base',
        isMulti: false,
    });
    const total = tab === 1 ? allTotal : myTotal;
    const [languageObj, setLanguageObj] = useState({});
    const [surveyLanguages, setSurveyLanguages] = useState(templatesLang);
    const checkEditSurvey = ifAllOrMySurvey?.filter(({ uuid }) => uuid === selectedSurveyId)[0] || {};

    useEffect(() => {
        if (params?.langid?.split(',').length > 1 && templatesLang?.length) {
            const filteredLanguages = filter(templatesLang, lang => {
                return lang?.short_code !== 'en' && params.langid.includes(lang.uuid);
            });
            setSurveyLanguages(filteredLanguages);
        }
    }, [params, templatesLang]);

    useEffect(() => {
        getLanguages();
        dispatchAction(resetDataExported());
    }, []);

    useEffect(() => {
        if (!can_view_all_survey) {
            setTab(2);
            dispatchAction(GetAllSurveys({ limit: all.limit, page: all.page, reqBody: { ...filters } }));
        }
    }, [can_view_my_survey]);

    const getLanguages = async () => {
        const response = await api.get(`${Endpoints.getLanguages}?limit=50&page=1`);
        const _languages = response?.data?.data?.records || [];
        _languages.map((item, i) => {
            return Object.assign(_languages[i], { is_selected: false });
        });
        setFiltersObj({
            ...filtersObj,
            languages: _languages,
        });
        setLanguageObj(_languages);
    };

    useEffect(() => {
        setAllSurveyData(allSurveys);
        setMySurveyData(mySurveys);
    }, [allSurveys, mySurveys]);

    useEffect(() => {
        if (st?.records) {
            const updatedList = st?.records?.map(item => {
                return { ...item, is_selected: false };
            });
            setFiltersObj({ ...filtersObj, surveyTemplatesList: updatedList });
        }
    }, [st]);

    const handleClearFilters = () => {
        setFilters(initialSelectedFilters);
        setFiltersObj({ ...initialFilters, languages: languageObj });
    };

    const handleClick = val => {
        setTab(val);
        handleClearFilters();
        setAllState(initialParams);
        setStartDate(new Date('April 1, 2023'));
        setEndDate(new Date());
        setShowTest(false);
        if (val === 1) {
            dispatchAction(
                GetAllSurveyListAction({
                    limit: initialParams.limit,
                    page: initialParams.page,
                    reqBody: { ...initialSelectedFilters },
                })
            );
        }
        if (val === 2) {
            dispatchAction(
                GetAllSurveys({
                    limit: initialParams.limit,
                    page: initialParams.page,
                    reqBody: { ...initialSelectedFilters },
                })
            );
        }
    };

    const handleChangeFn = (e, value) => {
        setAllState({ ...all, page: value });
        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...all, page: value, reqBody: { ...filters } }));
        }
        if (tab === 2) {
            dispatchAction(
                GetAllSurveys({
                    ...all.dateRange,
                    limit: all.limit,
                    page: value,
                    q: all.searchParam,
                    reqBody: { ...filters },
                })
            );
        }
    };

    const handleUpdateStatus = val => {
        setAnchorEl(false);
        setSelectedSurveyId(val);
        setOpenUpdateStatusModal(true);
    };

    const changeSurveyStatus = async updateConfig => {
        const { maintenance_mode_enabled, status_type, date, time, timezone } = updateConfig;

        let payload = {
            maintenance_mode: maintenance_mode_enabled,
            survey_status: status_type === LIVE_SCHEDULE ? LIVE : status_type,
        };

        if (!maintenance_mode_enabled && status_type === LIVE_SCHEDULE) {
            payload = {
                ...payload,
                survey_launch_time: moment(time).format(timeFormat),
                survey_launch_date: moment(date).format(dateFormat),
                survey_timezone: timezone,
            };
        }

        try {
            const resp = await dispatchAction(
                UpdateSurveyStatus({
                    surveyId: selectedSurveyId,
                    payload: payload,
                })
            ).unwrap();
            if (resp && resp?.message) {
                setOpenUpdateStatusModal(false);
                if (tab === 1) {
                    dispatchAction(GetAllSurveyListAction({ ...all, reqBody: { ...filters } }));
                } else {
                    dispatchAction(
                        GetAllSurveys({
                            ...all.dateRange,
                            limit: all.limit,
                            page: all.page,
                            q: all.searchParam,
                            reqBody: { ...filters },
                        })
                    );
                }
            }
        } catch (error) {
            console.log(error);
        }
    };

    const handleItemPerPageFn = val => {
        setAllState({ ...all, page: 1, limit: val });
        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...all, page: 1, limit: val, reqBody: { ...filters } }));
        }
        if (tab === 2) {
            dispatchAction(
                GetAllSurveys({ ...all.dateRange, limit: val, page: 1, q: all.searchParam, reqBody: { ...filters } })
            );
        }
    };

    const onSearchFn = debounce(e => {
        const {
            target: { value },
        } = e;
        const sanitizedValue = sanitize(value);
        setAllState({ ...all, searchParam: sanitizedValue, page: 1 });

        if (tab === 1) {
            dispatchAction(
                GetAllSurveyListAction({ ...all, searchParam: sanitizedValue, page: 1, reqBody: { ...filters } })
            );
        }
        if (tab === 2) {
            dispatchAction(
                GetAllSurveys({
                    ...all.dateRange,
                    limit: all.limit,
                    page: 1,
                    q: sanitizedValue,
                    reqBody: { ...filters },
                })
            );
        }
    }, THROTTLE_TIME);

    const OnDateChangeFn = (startDate, endDate) => {
        const copy = { ...all, page: 1 };
        copy.dateRange.startDate = startDate;
        copy.dateRange.endDate = endDate;
        setAllState(copy);

        if (tab === 1) {
            dispatchAction(GetAllSurveyListAction({ ...copy, reqBody: { ...filters } }));
        } else {
            dispatchAction(
                GetAllSurveys({
                    startDate,
                    endDate,
                    limit: all.limit,
                    page: 1,
                    q: all.searchParam,
                    reqBody: { ...filters },
                })
            );
        }
    };

    const handleMenu = (e, uuid, survey_template, status, langid) => {
        setAnchorEl(e.currentTarget);
        setId({
            uuid,
            survey_template,
            status,
            langid,
        });
    };

    const handleSurveyExport = () => {
        setAnchorEl(false);
        if (params.langid.split(',').length > 1) {
            dispatchAction(SurveyTemplatesLang({ tid: params.survey_template }));
            setSurveyExport({
                ...surveyExport,
                open: true,
            });
        } else {
            dispatchAction(DownloadMonitorFiles({ surveyId: params.uuid, langid: params.langid }));
        }
    };

    const handleDataExport = data => {
        setDataExportModal({
            open: true,
            surveyId: ifAllOrMySurvey.find(({ uuid }) => uuid === data.uuid)?.survey_id,
            uuid: data.uuid,
        });
    };
    const closeDataExport = () => {
        setDataExportModal({ open: false, surveyId: '', uuid: '' });
    };

    const handleDuplicateModal = data => {
        setDuplicateModal({
            open: true,
            surveyId: ifAllOrMySurvey.find(({ uuid }) => uuid === data.uuid)?.survey_id,
            uuid: data.uuid,
        });
    };
    const closeDuplicateModal = () => {
        setDuplicateModal({ open: false, surveyId: '', uuid: '' });
    };
    const handleExportAllSurvey = () => {
        let tempMaintenanceMode = filters.maintenance_mode;
        const tempFilter = cloneDeep(filters);
        delete tempFilter.maintenance_mode;
        if (tempMaintenanceMode != 'Both') {
            tempMaintenanceMode = tempMaintenanceMode === 'ENABLE';
            tempFilter.maintenance_mode = tempMaintenanceMode;
        }
        dispatchAction(
            ExportAllSurvey({
                self: tab === 2,
                dateRange: all.dateRange,
                searchVal: all?.searchParam,
                reqBody: tempFilter,
            })
        );
    };
    return (
        <FlexColumnGap gap={pxToVh(24)} data-testid="manage">
            {surveyExportLoader && <Loader showBackground background="transparent" position="fixed" />}
            <FlexInline>
                {can_view_all_survey === 'can_view_all_survey' ? (
                    <Pagetab
                        active={tab === 1}
                        clickHandler={() => (tab !== 1 ? handleClick(1) : null)}
                        label="All surveys"
                    />
                ) : null}
                {can_view_my_survey === 'can_view_my_survey' && can_view_all_survey === 'can_view_all_survey' ? (
                    <Pagetab
                        active={tab === 2}
                        clickHandler={() => (tab !== 2 ? handleClick(2) : null)}
                        label="My surveys"
                    />
                ) : null}
            </FlexInline>
            <DashboardFilters
                OnDateChangeFn={OnDateChangeFn}
                onSearchFn={onSearchFn}
                tab={tab}
                dispatchAction={dispatchAction}
                allState={all}
                setAllState={setAllState}
                filters={filters}
                setFilters={setFilters}
                filtersObj={filtersObj}
                setFiltersObj={setFiltersObj}
                handleClearFilters={handleClearFilters}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                exportAllSurvey={handleExportAllSurvey}
                setShowTest={setShowTest}
            />
            <TableContainer component={Paper}>
                <Table
                    sx={{
                        boxShadow: '0px 0px 1px 0px #051c2c26, 0px 2px 4px -1px #051c2c26',
                    }}
                    stickyHeader
                >
                    <TableHead>
                        <TableRow>
                            <TableCellStyled
                                sx={{
                                    minWidth: '270px',
                                    position: 'sticky',
                                    left: '0',
                                    zIndex: 9,
                                }}
                                noWrap={true}
                            >
                                Survey name
                            </TableCellStyled>
                            <TableCellStyled noWrap={true}>Survey template</TableCellStyled>
                            {AuthStore.isAdminOrCoach && <TableCellStyled>Organization name</TableCellStyled>}
                            <TableCellStyled noWrap={true}>Status</TableCellStyled>
                            <TableCellStyled noWrap={true}>Response Rate</TableCellStyled>
                            {AuthStore.isAdminOrCoach && (
                                <TableCellStyled noWrap={true}>Closing protocols</TableCellStyled>
                            )}
                            {!AuthStore.isAdminOrCoach && <TableCellStyled noWrap={true}>Created on</TableCellStyled>}
                            <TableCellStyled noWrap={true}>Live on</TableCellStyled>
                            <TableCellStyled noWrap={true}>Closed on</TableCellStyled>
                            <TableCellStyled
                                sx={{
                                    position: 'sticky',
                                    right: 0,
                                }}
                                noWrap={true}
                            >
                                <div>Actions</div>
                            </TableCellStyled>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ifAllOrMySurvey && ifAllOrMySurvey.length ? (
                            ifAllOrMySurvey.map(
                                (
                                    {
                                        survey_name = '',
                                        survey_id = '',
                                        uuid = '',
                                        survey_template,
                                        created_on = '',
                                        status,
                                        survey_launch_date = '',
                                        survey_launch_time = '',
                                        response_rate,
                                        closed_on = '',
                                        launch_date = '',
                                        supported_languages = '',
                                        survey_sub_type = '',
                                        maintenance_mode = '',
                                        client_name = '',
                                        closing_protocol_status = null,
                                    },
                                    i
                                ) => {
                                    const surveyName = `${survey_id.toUpperCase()} - ${survey_name.replaceAll(
                                        '&amp;',
                                        '&'
                                    )}`;
                                    return (
                                        <TableRow key={i}>
                                            <TableCellStyled
                                                sx={{ minWidth: '300px', position: 'sticky', left: 0 }}
                                                noWrap={true}
                                            >
                                                <CommonLabel
                                                    customStyles={{
                                                        width: '270px',
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                    }}
                                                    textWeight={400}
                                                    textSize={14}
                                                    lineHeight={20}
                                                    textColor={colorsCode.Neutral80}
                                                    title={surveyName}
                                                    label={surveyName}
                                                />
                                            </TableCellStyled>
                                            <TableCellStyled>
                                                <Badge
                                                    data={data}
                                                    survey_template={survey_template}
                                                    survey_sub_type={survey_sub_type}
                                                />
                                            </TableCellStyled>
                                            {AuthStore.isAdminOrCoach && (
                                                <TableCellStyled noWrap={true}>{client_name}</TableCellStyled>
                                            )}
                                            <TableCellStyled className="tblecellStyled" noWrap={true}>
                                                <div className="surveyStatusClass">
                                                    {renderColoredChips(
                                                        status,
                                                        maintenance_mode,
                                                        survey_launch_date,
                                                        survey_launch_time
                                                    )}
                                                </div>
                                            </TableCellStyled>
                                            <TableCellStyled className="tblecellStyled" noWrap={true}>
                                                {`${response_rate.total_completed}/${
                                                    response_rate.total_respondents
                                                } (${getResponseRatePercent(
                                                    response_rate.total_completed,
                                                    response_rate.total_respondents
                                                )}%)`}
                                            </TableCellStyled>
                                            {AuthStore.isAdminOrCoach && (
                                                <TableCellStyled noWrap={true} textAlign="center">
                                                    {closing_protocol_status === null
                                                        ? 'N.A'
                                                        : closing_protocol_status
                                                        ? 'Yes'
                                                        : 'No'}
                                                </TableCellStyled>
                                            )}
                                            {!AuthStore.isAdminOrCoach && (
                                                <TableCellStyled className="tblecellStyled" noWrap={true}>
                                                    {moment.unix(created_on).format('DD-MMM-YYYY')}
                                                </TableCellStyled>
                                            )}
                                            <TableCellStyled className="tblecellStyled" noWrap={true}>
                                                {launch_date && [COMPLETED, LIVE].includes(status)
                                                    ? moment.unix(launch_date).format('DD-MMM-YYYY')
                                                    : '-'}
                                            </TableCellStyled>
                                            <TableCellStyled className="tblecellStyled" noWrap={true}>
                                                {status === COMPLETED && closed_on
                                                    ? moment.unix(closed_on).format('DD-MMM-YYYY')
                                                    : '-'}
                                            </TableCellStyled>
                                            <TableCellStyled
                                                sx={{
                                                    position: 'sticky',
                                                    right: 0,
                                                    textAlign: 'center',
                                                }}
                                                noWrap={true}
                                            >
                                                <Fragment>
                                                    <img
                                                        src={actions}
                                                        alt=""
                                                        onClick={e =>
                                                            handleMenu(
                                                                e,
                                                                uuid,
                                                                survey_template,
                                                                status,
                                                                supported_languages
                                                            )
                                                        }
                                                        style={{ cursor: 'pointer', verticalAlign: 'middle' }}
                                                    />
                                                </Fragment>
                                            </TableCellStyled>
                                        </TableRow>
                                    );
                                }
                            )
                        ) : (
                            <TableRow>
                                <TableCellStyled colSpan={9} sx={{ textAlign: 'center' }}>
                                    No survey found
                                </TableCellStyled>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>
            {ifAllOrMySurvey.length && total > all.limit ? (
                <FlexEnd style={{ alignSelf: 'stretch', marginTop: `${pxToVh(-18)}vh` }}>
                    <PaginationComponent
                        page={all.page}
                        perPage={all?.limit}
                        handleItemPerPageFn={handleItemPerPageFn}
                        handleChangeFn={handleChangeFn}
                        count={Math.ceil(total / all.limit)}
                    />
                </FlexEnd>
            ) : null}

            {menuOpen ? (
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    sx={{
                        '& .MuiMenu-paper': {
                            boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
                            width: `auto`,
                            marginTop: `${pxToVh(10)}vh`,
                        },
                    }}
                >
                    {[DRAFT, UNDER, REVIEWED].includes(params.status) ? (
                        <MenuItem>
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleResume(params.uuid, params.survey_template);
                                    }}
                                    sx={{
                                        cursor: 'pointer',
                                    }}
                                >
                                    RESUME
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleResume(params.uuid, params.survey_template);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    <MenuItem>
                        <FlexRow>
                            <Typography
                                className="actionClass"
                                color="#2B5580"
                                onClick={() => {
                                    setAnchorEl(false);
                                    handleDuplicateModal(params);
                                }}
                                sx={{
                                    cursor: 'pointer',
                                }}
                            >
                                DUPLICATE
                            </Typography>
                            <img
                                src={arrowRight}
                                className="image"
                                alt=""
                                onClick={() => {
                                    setAnchorEl(false);
                                    handleDuplicateModal(params);
                                }}
                                style={{ cursor: 'pointer' }}
                            />
                        </FlexRow>
                    </MenuItem>
                    {[LIVE, COMPLETED].includes(params.status) ? (
                        <>
                            <MenuItem value="monitor">
                                <FlexRow>
                                    <Typography
                                        className="actionClass"
                                        color="#2B5580"
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleMonitorSurvey(params.uuid);
                                        }}
                                    >
                                        MONITOR
                                    </Typography>
                                    <img
                                        src={arrowRight}
                                        className="image"
                                        alt=""
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleMonitorSurvey(params.uuid);
                                        }}
                                        style={{ cursor: 'pointer' }}
                                    />
                                </FlexRow>
                            </MenuItem>
                            {params.status === COMPLETED ? (
                                ['OHI 4.0', 'OHI 3.2'].includes(
                                    data?.find(({ uuid }) => uuid == params?.survey_template)?.template_name
                                ) ? (
                                    <MenuItem value="monitor">
                                        <FlexRow>
                                            <Typography
                                                className="actionClass"
                                                color="#2B5580"
                                                onClick={() => {
                                                    setAnchorEl(false);
                                                    handleMonitorSurvey(params.uuid, true);
                                                }}
                                            >
                                                CLOSING PROTOCOL
                                            </Typography>
                                            <img
                                                src={arrowRight}
                                                className="image"
                                                alt=""
                                                onClick={() => {
                                                    setAnchorEl(false);
                                                    handleMonitorSurvey(params.uuid, true);
                                                }}
                                                style={{ cursor: 'pointer' }}
                                            />
                                        </FlexRow>
                                    </MenuItem>
                                ) : null
                            ) : null}
                            <MenuItem value="schedule">
                                <FlexRow>
                                    <Typography
                                        className="actionClass"
                                        color="#2B5580"
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleResume(params.uuid, params.survey_template);
                                        }}
                                    >
                                        DISTRIBUTE SURVEY
                                    </Typography>
                                    <img
                                        src={arrowRight}
                                        className="image"
                                        alt=""
                                        onClick={() => {
                                            setAnchorEl(false);
                                            handleResume(params.uuid, params.survey_template);
                                        }}
                                    />
                                </FlexRow>
                            </MenuItem>
                        </>
                    ) : null}
                    {isAdmin && ![UNDER, REVIEWED].includes(params.status) ? (
                        <MenuItem value="udpateStatus">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color={colorsCode.SecondaryDeepBlue500}
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleUpdateStatus(params.uuid, params.survey_template);
                                    }}
                                >
                                    UPDATE STATUS
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleUpdateStatus(params.uuid, params.survey_template);
                                    }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    {isAdmin && [DRAFT, LIVE, UNDER, REVIEWED].includes(params.status) ? (
                        <MenuItem value="text_edit">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color={colorsCode.SecondaryDeepBlue500}
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleTextEdit(params.uuid, params.survey_template);
                                    }}
                                >
                                    TEXT EDIT
                                </Typography>
                                <img
                                    src={arrowRight}
                                    className="image"
                                    alt=""
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleTextEdit(params.uuid, params.survey_template);
                                    }}
                                    style={{ cursor: 'pointer' }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                    <MenuItem value="monitor">
                        <FlexRow>
                            <Typography
                                className="actionClass"
                                color="#2B5580"
                                onClick={() => {
                                    setAnchorEl(false);
                                    handleSurveyExport();
                                }}
                            >
                                SURVEY EXPORT
                            </Typography>

                            <Icon
                                src={Outline24MoveLayerDown}
                                name="export"
                                style={{
                                    height: `${pxToVh(12)}vh`,
                                    width: `${pxToVw(12)}vw`,
                                    color: colorsCode.SecondaryDeepBlue500,
                                }}
                                onClick={() => {
                                    setAnchorEl(false);
                                    handleSurveyExport();
                                }}
                            />
                        </FlexRow>
                    </MenuItem>

                    {isAdmin ? (
                        <MenuItem value="data-export">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleDataExport(params);
                                    }}
                                >
                                    DATA EXPORT
                                </Typography>

                                <Icon
                                    src={Outline24MoveLayerDown}
                                    name="export"
                                    style={{
                                        height: `${pxToVh(12)}vh`,
                                        width: `${pxToVw(12)}vw`,
                                        color: colorsCode.SecondaryDeepBlue500,
                                    }}
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleDataExport(params);
                                    }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                </Menu>
            ) : null}
            <DataExportModal dataExportModal={dataExportModal} closeDataExport={closeDataExport} />
            <DuplicateSurveyModal
                duplicateModal={duplicateModal}
                closeDuplcateModal={closeDuplicateModal}
                handleDuplicate={() => handleDuplicate(params.uuid, params.survey_template)}
            />
            {openUpdateStatusModal && (
                <UpdateStatus
                    open={openUpdateStatusModal}
                    surveydata={checkEditSurvey}
                    handleSave={changeSurveyStatus}
                    handleClose={() => {
                        setOpenUpdateStatusModal(false);
                    }}
                    dispatchAction={dispatchAction}
                />
            )}
            {createPortal(
                SurveyExportModal(
                    surveyExport,
                    setSurveyExport,
                    surveyLanguages,
                    setSurveyLanguages,
                    setId,
                    dispatchAction,
                    params,
                    ifAllOrMySurvey
                ),
                document.body
            )}
            {dataExported ? (
                <ConfirmationPopUp
                    isOpen={dataExported}
                    message={errorMessage}
                    onClose={() => dispatchAction(resetDataExported())}
                />
            ) : null}
        </FlexColumnGap>
    );
};

export const FlexRowStyled = styled(FlexRow)`
    gap: 0.3vw;
`;

export const Langdiv = styled(Flex)`
    padding: ${pxToVh(4)}vh ${pxToVw(8)}vw;
    align-items: flex-start;
    gap: ${pxToVw(10)}vw;
    border-radius: 4px;
    border: 1px solid ${colorsCode.neutral20};
`;

export default ManageSurveys;
