import React, { useState, useEffect } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import { BorderLightButton, FlexBetween, FlexRow, StyledFilterMenu } from '../styled/styled';
import { ReactComponent as ArrowDown } from '../../assets/img/arrow_down.svg';
import { LABELS } from '../../global-constants';
import { colorsCode, pxToVh, pxToVw } from '../colors';
import { commonThemes } from '../themes';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CountLabel from './CountLabel';
import PropTypes from 'prop-types';
import { CommonLabel } from './Common';
import SearchBar from './SearchBar';
import { cloneDeep } from 'lodash';

const FilterModal = ({
    open,
    cancelBtnName,
    saveBtnName,
    mainTitle,
    clearTitle,
    close,
    onApply,
    anchorEl = null,
    clientFilter,
    filterObj,
    setFilterObj,
    resetLocalFilter,
}) => {
    const [expanded, setExpanded] = useState(false);
    const [filterSearch, setFilterSearch] = useState('');
    const [localSurveyCount, setLocalSurveyCount] = useState([]);
    const [localSurveyTemplate, setLocalSurveyTemplate] = useState([]);

    const handleAccordion = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    useEffect(() => {
        setFilterSearch('');
        setLocalSurveyCount(filterObj?.surveyCount);
        setLocalSurveyTemplate(filterObj?.templateType);
    }, [expanded]);

    const handleOnChange = (val, e, type) => {
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(filterObj);
        if (type === 'surveyCount') {
            copy[type] = copy[type]?.map(item => {
                if (item.name === val.name) item.is_selected = checked;
                return item;
            });
            setLocalSurveyCount(
                copy[type].filter(({ name }) => name.toLowerCase().includes(filterSearch.toLowerCase()))
            );
        }

        if (type === 'templateType') {
            copy[type] = copy[type]?.map(item => {
                if (item.template_name === val.template_name) item.is_selected = checked;
                return item;
            });
            setLocalSurveyTemplate(
                copy[type].filter(({ template_name }) =>
                    template_name.toLowerCase().includes(filterSearch.toLowerCase())
                )
            );
        }
        setFilterObj(copy);
    };

    const clearFilters = () => {
        close();
        resetLocalFilter();
        setExpanded(false);
        onApply({
            ...clientFilter,
            surveyCount: null,
        });
    };

    const styles = commonThemes();

    return (
        <StyledFilterMenu
            open={open}
            className={styles.ContentWrapper}
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            height={`${pxToVh(350)}vh`}
            style={{
                marginLeft: `9vw`,
                marginTop: `-1vh`,
            }}
        >
            <FlexBetween>
                <DialogTitle className={styles.filterModalTitle}>{mainTitle}</DialogTitle>
                <DialogTitle onClick={clearFilters} className={`${styles.filterModalClearTitle} `}>
                    {clearTitle}
                </DialogTitle>
            </FlexBetween>
            <DialogContent className={styles.filterOptionsContainer}>
                <DialogContentText className={styles.accordianTitle}>
                    <Accordion
                        square={true}
                        expanded={expanded == 'surveyTemplates'}
                        elevation={0}
                        disableGutters={true}
                        onChange={handleAccordion('surveyTemplates')}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ArrowDown />}
                            aria-controls="panel1a-content"
                            id="surveyTemplates"
                        >
                            <CommonLabel
                                textWeight={500}
                                textSize={14}
                                textColor={colorsCode.Neutral80}
                                lineHeight={20}
                                label={LABELS.FILTER_MODAL.SURVEY_TYPE}
                            />
                        </AccordionSummary>
                        <AccordionDetails>
                            <SearchBar
                                placeholder="Search"
                                id="search"
                                name="search"
                                val={filterSearch}
                                onChangeFn={e => {
                                    setFilterSearch(e.target.value);
                                    setLocalSurveyTemplate(
                                        filterObj?.templateType?.filter(item =>
                                            item?.template_name?.toLowerCase().includes(e.target.value.toLowerCase())
                                        )
                                    );
                                }}
                                customClass={styles.searchClassInFilter}
                            />
                            {!localSurveyTemplate?.length && <FlexRow justifyContent="center">No data found</FlexRow>}
                            {localSurveyTemplate &&
                                localSurveyTemplate.map(item => {
                                    return (
                                        <FlexRow key={item.template_name}>
                                            <Checkbox
                                                checked={item.is_selected}
                                                sx={{
                                                    height: '4.38vh',
                                                    width: '4.38vh',
                                                    color: colorsCode.Neutral30,
                                                }}
                                                checkedIcon={<CheckBoxIcon sx={{ color: '#000' }} />}
                                                onChange={e => handleOnChange(item, e, 'templateType')}
                                            />
                                            <CommonLabel
                                                textWeight={400}
                                                textSize={14}
                                                textColor={colorsCode.Neutral80}
                                                lineHeight={20}
                                                label={item.template_name}
                                            />
                                        </FlexRow>
                                    );
                                })}
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        square={true}
                        expanded={expanded == 'created'}
                        elevation={0}
                        disableGutters={true}
                        onChange={handleAccordion('created')}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} aria-controls="panel1a-content" id="created">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.SURVEY_CREATED}
                                />
                                <CountLabel count={filterObj?.surveyCount?.filter(item => item?.is_selected)?.length} />
                            </FlexBetweenWrap>
                        </AccordionSummary>

                        <AccordionDetails>
                            <SearchBar
                                placeholder="Search"
                                id="search"
                                name="search"
                                val={filterSearch}
                                onChangeFn={e => {
                                    setFilterSearch(e.target.value);
                                    setLocalSurveyCount(
                                        filterObj?.surveyCount?.filter(item =>
                                            item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                                        )
                                    );
                                }}
                                customClass={styles.searchClassInFilter}
                            />
                            {!localSurveyCount?.length && <FlexRow justifyContent="center">No data found</FlexRow>}
                            {localSurveyCount.map(item => {
                                return (
                                    <FlexRow onClick={e => handleOnChange(item, e, 'surveyCount')} key={item?.name}>
                                        <Checkbox
                                            checked={item.is_selected}
                                            sx={{
                                                height: '4.38vh',
                                                width: '4.38vh',
                                                color: colorsCode.Neutral30,
                                            }}
                                            checkedIcon={<CheckBoxIcon sx={{ color: colorsCode.TextHeading }} />}
                                        />
                                        <CommonLabel
                                            textWeight={400}
                                            textSize={14}
                                            textColor={colorsCode.Neutral80}
                                            lineHeight={20}
                                            label={item.name}
                                        />
                                    </FlexRow>
                                );
                            })}
                        </AccordionDetails>
                    </Accordion>
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'flex-end',
                    gap: `${pxToVw(20)}vw`,
                    p: `${pxToVh(20)}vh ${pxToVw(17)}vw ${pxToVh(20)}vh 0vw`,
                    borderTop: `1px solid #ccc`,
                }}
            >
                <BorderLightButton
                    onClick={() => {
                        setExpanded(false);
                        close();
                    }}
                    color={colorsCode.PrimaryDeepBlue}
                    light
                >
                    {cancelBtnName}
                </BorderLightButton>
                <BorderLightButton
                    onClick={() => {
                        const allChecked = filterObj.surveyCount.every(item => item.is_selected);
                        onApply({
                            ...filterObj,
                            templateType: filterObj.templateType
                                .filter(item => item.is_selected)
                                .map(item => item.uuid),
                            surveyCount: allChecked
                                ? null
                                : filterObj.surveyCount.filter(item => item.is_selected)[0]?.id,
                        });
                        setExpanded(false);
                        close();
                    }}
                >
                    {saveBtnName}
                </BorderLightButton>
            </DialogActions>
            {/* </FlexEndWrap> */}
            <br />
        </StyledFilterMenu>
    );
};

const FlexBetweenWrap = styled(FlexBetween)`
    width: 100%;
    margin-right: 0.7vh;
`;

FilterModal.propTypes = {
    cancelBtnName: PropTypes.string.isRequired,
    saveBtnName: PropTypes.string.isRequired,
    mainTitle: PropTypes.string.isRequired,
    clearTitle: PropTypes.string.isRequired,
    open: PropTypes.bool.isRequired,
    close: PropTypes.func.isRequired,
    onApply: PropTypes.func.isRequired,
    filterObj: PropTypes.object.isRequired,
    setFilterObj: PropTypes.func.isRequired,
};
export default FilterModal;
