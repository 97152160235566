import { createSlice } from '@reduxjs/toolkit';
import {
    ClientListing,
    IndustryListing,
    SurveyTemplatesListing,
    BulkDeleteClient,
    DeleteClient,
    AddClient,
    GetClientById,
    UpdateClientDetails,
    ExtraAttributes,
    OpportunityLogsListing,
    ValidateOpportunity,
    DeleteOpportunity,
    CountriesListing,
} from '../../actions/Client/actions';
import { find } from 'lodash';

export const INTITIAL_STATE = {
    loading: false,
    clientDataLoading: false,
    opportunitiesLoading: false,
    isDataFetched: false,
    data: [],
    surveyTemplates: [],
    clientData: {},
    clientList: [],
    opportunityLogs: [],
    validationLoading: false,
    validateOpportunity: {},
    industry: [],
    countries: [],
    extraAttributes: {},
    isSaved: false,
    isUpdated: false,
    singleDeleted: false,
    bulkDeleted: false,
};
const ClientObject = createSlice({
    name: 'Client',
    initialState: INTITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(SurveyTemplatesListing.pending, state => {
                state.loading = true;
            })
            .addCase(SurveyTemplatesListing.fulfilled, (state, { payload }) => {
                const {
                    data: { records = [], total = 0 },
                } = payload;
                state.loading = false;
                const allTemplates = [];
                const activeTemplates = [];
                records?.map(template => {
                    allTemplates.push({ ...template, is_selected: false });
                    if (template?.is_active) {
                        activeTemplates.push({ ...template, is_selected: false });
                    }
                });
                state.surveyTemplates = {
                    records: allTemplates,
                    activeRecords: activeTemplates,
                    total: total || 0,
                };
            })
            .addCase(SurveyTemplatesListing.rejected, state => {
                state.loading = false;
                state.surveyTemplates = [];
            })
            .addCase(ClientListing.pending, state => {
                state.loading = true;
            })
            .addCase(ClientListing.fulfilled, (state, { payload }) => {
                const {
                    data: { records = [], total = 0, total_client_count = 0 },
                } = payload;
                state.loading = false;
                state.clientList = {
                    records: records?.length ? records.map(item => ({ ...item, is_selected: false })) : [],
                    total,
                    total_client_count,
                };
            })
            .addCase(ClientListing.rejected, state => {
                state.loading = false;
                state.clientList = [];
            })
            .addCase(IndustryListing.pending, state => {
                state.loading = true;
            })
            .addCase(IndustryListing.fulfilled, (state, { payload }) => {
                const {
                    data: { records = [], total = 0 },
                } = payload;
                const industryRes = records.length
                    ? records.map(item => {
                          if (item.sub_industries.length) {
                              item.sub_industries.map(sitem => {
                                  Object.assign(sitem, { name: sitem.sub_industry_text, is_selected: false });
                              });
                          }
                          return { ...item, name: item.industry_text, is_selected: false };
                      })
                    : [];
                //set response
                const response = {
                    records: industryRes,
                    total,
                };
                state.loading = false;
                state.industry = response;
                state.isDataFetched = true;
            })
            .addCase(IndustryListing.rejected, state => {
                state.loading = false;
                state.isDataFetched = true;
            })
            .addCase(CountriesListing.pending, state => {
                state.loading = true;
            })
            .addCase(CountriesListing.fulfilled, (state, { payload }) => {
                const {
                    data: { records = [] },
                } = payload;
                let countriesRecord = records.length
                    ? records.map(country => {
                          const { country_text } = country;
                          return { ...country, is_selected: false, name: country_text };
                      })
                    : [];
                countriesRecord = countriesRecord.sort((a, b) => -b.region.localeCompare(a.region));
                state.loading = false;
                state.countries = countriesRecord;
                state.isDataFetched = true;
            })
            .addCase(CountriesListing.rejected, (state, { payload }) => {
                state.loading = false;
                state.errorMessage = payload;
                state.isDataFetched = true;
                state.error = true;
            })
            .addCase(OpportunityLogsListing.pending, state => {
                state.opportunitiesLoading = true;
            })
            .addCase(OpportunityLogsListing.fulfilled, (state, action) => {
                const {
                    payload,
                    meta: { arg },
                } = action;
                const { data } = payload;
                const { createClient } = arg;
                const updatedLogs = [];

                data.forEach(log => {
                    const { opportunity_name, opportunity_id } = log;
                    const logAlreadyPresent = find(updatedLogs, { name: opportunity_name });
                    if (!logAlreadyPresent || createClient) {
                        updatedLogs.push({
                            ...log,
                            name: opportunity_name,
                            id: opportunity_id,
                            uuid: opportunity_id,
                        });
                    }
                });
                state.opportunitiesLoading = false;
                state.opportunityLogs = updatedLogs;
            })
            .addCase(OpportunityLogsListing.rejected, state => {
                state.opportunitiesLoading = false;
                state.opportunityLogs = [];
            })
            .addCase(ValidateOpportunity.pending, state => {
                state.validationLoading = true;
            })
            .addCase(ValidateOpportunity.fulfilled, (state, { payload }) => {
                const { data } = payload;
                state.validationLoading = false;
                state.validateOpportunity = data;
            })
            .addCase(ValidateOpportunity.rejected, state => {
                state.validationLoading = false;
                state.validateOpportunity = {};
            })
            .addCase(DeleteOpportunity.pending, state => {
                state.loading = true;
            })
            .addCase(DeleteOpportunity.fulfilled, state => {
                state.loading = false;
            })
            .addCase(DeleteOpportunity.rejected, state => {
                state.loading = false;
            })
            .addCase(ExtraAttributes.pending, state => {
                state.loading = true;
            })
            .addCase(ExtraAttributes.fulfilled, (state, { payload }) => {
                const { data = [] } = payload;
                const attrObj = {};
                data?.records.forEach(attr => {
                    const { attribute_values, attribute_key } = attr;
                    if (attribute_values && attribute_values?.options.length) {
                        const optionsArray = [];
                        attribute_values?.options.forEach(option => {
                            optionsArray.push({
                                id: option,
                                name: option,
                                is_selected: false,
                                uuid: option,
                            });
                        });
                        attrObj[attribute_key] = optionsArray;
                    }
                });
                state.loading = false;
                state.extraAttributes = attrObj;
                state.isDataFetched = true;
            })
            .addCase(ExtraAttributes.rejected, state => {
                state.loading = false;
                state.isDataFetched = true;
            })
            .addCase(AddClient.pending, state => {
                state.loading = true;
            })
            .addCase(AddClient.fulfilled, state => {
                state.loading = false;
                state.isSaved = true;
            })
            .addCase(AddClient.rejected, state => {
                state.loading = false;
            })
            .addCase(DeleteClient.pending, state => {
                state.loading = true;
            })
            .addCase(DeleteClient.fulfilled, state => {
                state.loading = false;
                state.singleDeleted = true;
            })
            .addCase(DeleteClient.rejected, state => {
                state.loading = false;
                state.singleDeleted = true;
            })
            .addCase(BulkDeleteClient.pending, state => {
                state.loading = true;
            })
            .addCase(BulkDeleteClient.fulfilled, state => {
                state.loading = false;
                state.bulkDeleted = true;
            })
            .addCase(BulkDeleteClient.rejected, state => {
                state.loading = false;
                state.bulkDeleted = true;
            })
            .addCase(GetClientById.pending, state => {
                state.loading = true;
                state.clientDataLoading = true;
            })
            .addCase(GetClientById.fulfilled, (state, { payload }) => {
                const updatedPayload = payload.data;
                updatedPayload.surveys = [{
                    "uuid": "-1",
                    "survey_name": "Survey information not available"
                },...updatedPayload.surveys];

                updatedPayload?.surveys.forEach(survey => {
                        const { survey_name } = survey;
                        survey.name = survey_name;
                    });
                state.loading = false;
                state.clientData = updatedPayload;
                state.isDataFetched = true;
                state.clientDataLoading = false;
            })
            .addCase(GetClientById.rejected, state => {
                state.loading = false;
                state.clientDataLoading = false;
            })
            .addCase(UpdateClientDetails.pending, state => {
                state.loading = true;
            })
            .addCase(UpdateClientDetails.fulfilled, state => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(UpdateClientDetails.rejected, state => {
                state.loading = false;
            });
    },
    reducers: {
        resetSaved: state => {
            state.isSaved = false;
            state.isUpdated = false;
            state.singleDeleted = false;
            state.bulkDeleted = false;
            state.isDataFetched = false;
            state.clientData = {};
            state.extraAttributes = {};
            state.opportunityLogs = [];
            state.validationLoading = false;
            state.validateOpportunity = {};
        },
        updateOpportunityLogs: (state, { payload }) => {
            const { records } = payload;
            state.opportunityLogs = records;
        },
        updateOpportunity: (state, { payload }) => {
            state.validateOpportunity = payload;
        },
    },
});
export const { resetSaved, updateOpportunityLogs, updateOpportunity } = ClientObject.actions;
export default ClientObject.reducer;
