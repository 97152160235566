import { Box, Dialog, DialogActions, DialogContent, DialogTitle, Icon, Popover, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import CloseIcon from '../../../../../assets/img/close.svg';
import { Set_Question_Logic } from '../../../../../global-constants';
import Text from '../../../../KnowledgeCenter/Text';
import { CTA, FlexColumnGap, FlexColumnWithAlign, FlexRow, FlexStartGap } from '../../../../../common/styled/styled';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Loader';
import {
    SelectDropdown,
    SelectQuestionDropdown,
    SelectStateDropdown,
} from './DisplayLogicComponents/SelectQuestionDropdown';
import { SaveDisplayLogic } from '../../../../../store/actions/DisplayLogic/actions';
import { cloneDeep } from 'lodash';
import { DisplayLogicContainer } from './DisplayLogicQuestion';
import DeleteIcon from '../../../../../assets/img/delete.svg';
import { CommonLabel, Img } from '../../../../../common/FormInputs/Common';
import styled from 'styled-components';
import RedWarningIcon from '../../../../../assets/img/red_warning.svg';
import { generateUUID } from '../../../../../utils/functions';

const DisplayResponseLogicModal = ({
    onClose,
    showResponseLogicModal,
    question,
    surveyId,
    activeTab,
    toEdit = false,
    selectedOptions,
}) => {
    const dispatch = useDispatch();
    const { loading, previousQuestionList, subGroupId } = useSelector(state => state.displayLogicData);
    let { question_id = '', conditions, question_options = [] } = question;
    const [responseLogicState, setResponseLogicState] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedLogicIndex, setSelectedLogicIndex] = useState(false);

    const defaultLogicObj = {
        question_id: '',
        option_id: [], // array of response option ids
        selected_options: [], //array of response options object
        state: '',
        allowed_options: [], // array of questions' allowed option ids
        detailed_allowed_options: [], // array of questions' allowed options object
        question_options: [], // frontend property
    };

    useEffect(() => {
        let updatedConditions = conditions;

        if (!toEdit && conditions) {
            // logic already exists
            const logic = conditions?.cases[0]?.logic;
            const selectedOptionIds = selectedOptions.map(option => option?.option_id);
            updatedConditions = {
                ...conditions,
                cases: [
                    {
                        logic: [
                            ...logic,
                            {
                                ...defaultLogicObj,
                                unique_id: generateUUID(),
                                detailed_allowed_options: selectedOptions,
                                allowed_options: selectedOptionIds,
                            },
                        ],
                    },
                ],
            };
        }
        if (!toEdit && !conditions) {
            // user is adding the first logic
            const selectedOptionIds = selectedOptions.map(option => option?.option_id);
            updatedConditions = {
                on: 'option',
                cases: [
                    {
                        logic: [
                            {
                                ...defaultLogicObj,
                                detailed_allowed_options: selectedOptions,
                                allowed_options: selectedOptionIds,
                                unique_id: generateUUID(),
                            },
                        ],
                    },
                ],
            };
        }

        setResponseLogicState({
            question_id: question_id,
            questions_used_in_conditions: '',
            conditions: updatedConditions,
        });
    }, [previousQuestionList]);

    const handleSelectOptions = (event, selectedOptions, logicIdx) => {
        let updatedLogics = cloneDeep(responseLogicState?.conditions?.cases[0]?.logic);
        updatedLogics[logicIdx].allowed_options = selectedOptions.map(opt => opt.option_id);
        updatedLogics[logicIdx].detailed_allowed_options = selectedOptions;

        setResponseLogicState({
            ...responseLogicState,
            conditions: { ...responseLogicState.conditions, cases: [{ logic: updatedLogics }] },
        });
    };

    const handleQuestionSelect = (event, selectedQuest, logicIdx) => {
        const updatedLogics = cloneDeep(responseLogicState?.conditions?.cases[0]?.logic);
        let selectedLogic = updatedLogics[logicIdx];
        if (selectedQuest) {
            selectedLogic = {
                ...selectedLogic,
                question_id: selectedQuest?.question_id,
                question_options:
                    selectedQuest && selectedQuest?.question_options?.length ? selectedQuest.question_options : [],
                selected_options: [],
                option_id: [],
                selected_quest: selectedQuest,
            };
        } else {
            selectedLogic = {
                ...selectedLogic,
                question_id: '',
                question_options: [],
                selected_options: [],
                option_id: [],
                selected_quest: '',
            };
        }

        updatedLogics[logicIdx] = selectedLogic;

        const temp = {
            ...responseLogicState,
            conditions: { ...responseLogicState.conditions, cases: [{ logic: updatedLogics }] },
        };

        setResponseLogicState(temp);
    };

    const handleSelectResponse = (event, selectedResponses, logicIdx) => {
        let updatedLogics = cloneDeep(responseLogicState?.conditions?.cases[0]?.logic);
        updatedLogics[logicIdx].option_id = selectedResponses.map(opt => opt.option_id);
        updatedLogics[logicIdx].selected_options = selectedResponses;

        setResponseLogicState({
            ...responseLogicState,
            conditions: { ...responseLogicState.conditions, cases: [{ logic: updatedLogics }] },
        });

        setResponseLogicState({
            ...responseLogicState,
            conditions: { ...responseLogicState.conditions, cases: [{ logic: updatedLogics }] },
        });
    };

    const handleStateResponse = (value, question_id) => {
        const updatedLogics = responseLogicState?.conditions?.cases[0]?.logic?.map(ele => {
            if (ele?.question_id === question_id) {
                return { ...ele, state: value === 'Selected' ? 'IN' : 'NIN' };
            }
            return ele;
        });
        setResponseLogicState({
            ...responseLogicState,
            conditions: { ...responseLogicState.conditions, cases: [{ logic: updatedLogics }] },
        });
    };

    const handleSaveDisable = () => {
        const logics = responseLogicState?.conditions?.cases[0]?.logic;
        let disableSave = false;

        if (logics && logics?.length) {
            logics.forEach(logic => {
                const { question_id, option_id, allowed_options, state } = logic;
                if (disableSave) {
                    return;
                }
                if (!question_id || !option_id?.length || !allowed_options?.length || !state) {
                    disableSave = true;
                }
            });
        }

        return disableSave;
    };

    const handleSave = event => {
        event.stopPropagation();
        const tempPayload = cloneDeep(responseLogicState);
        const logics = tempPayload?.conditions?.cases[0]?.logic;
        if (logics && logics?.length) {
            logics.forEach(logic => {
                if (logic?.question_options) {
                    delete logic.question_options;
                }
            });
        }

        let selectedQuestionIds = tempPayload?.conditions.cases[0]?.logic.map(logic => logic?.question_id);
        selectedQuestionIds = selectedQuestionIds.join(',');
        tempPayload.questions_used_in_conditions = selectedQuestionIds;

        dispatch(SaveDisplayLogic({ surveyId, tempPayload, activeTab, subGroupId }));
        onClose(true);
    };

    const handleDeleteLogic = logicIdx => {
        const updatedLogics = cloneDeep(responseLogicState?.conditions?.cases[0]?.logic);
        updatedLogics.splice(logicIdx, 1);
        setResponseLogicState({
            ...responseLogicState,
            conditions: { ...responseLogicState.conditions, cases: [{ logic: updatedLogics }] },
        });
    };

    const toggleMenu = (event, logicIdx) => {
        event.stopPropagation();
        if (logicIdx) {
            setSelectedLogicIndex(logicIdx);
        }
        setAnchorEl(event.currentTarget);
    };

    return (
        <Dialog
            open={showResponseLogicModal}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(1200)}vw`,
                    width: `${pxToVw(1200)}vw`,
                    height: `${pxToVh(587)}vh`,
                    maxheight: `${pxToVh(587)}vh`,
                },
            }}
        >
            {loading && <Loader showBackground background="transparent" position="fixed" />}

            <DialogTitle sx={{ mt: `${pxToVh(10)}vh` }}>
                <FlexColumnWithAlign gap="1">
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={18}
                        lineHeight={24}
                        label={Set_Question_Logic}
                        fontWeight={500}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={14}
                        lineHeight={24}
                        label={'Define the conditions through which this question will be displayed to your user. '}
                        fontWeight={400}
                    />
                </FlexColumnWithAlign>
                <Icon
                    aria-label="close"
                    onClick={() => {
                        onClose(false);
                    }}
                    sx={{
                        position: 'absolute',
                        right: `${pxToVw(16)}vw`,
                        top: `${pxToVh(16)}vh`,
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={CloseIcon}
                        alt=""
                        style={{
                            height: `${pxToVh(18)}vh`,
                            width: ` ${pxToVw(18)}vw`,
                        }}
                    />
                </Icon>
            </DialogTitle>
            <DialogContent sx={{ width: '100%' }}>
                <FlexColumnWithAlign gap="2">
                    <DisplayLogicContainer width="50%">
                        <FlexRow justifyContent="space-between">
                            <FlexColumnGap gap="0.5" width="100%">
                                <Text
                                    fontColor={colorsCode.purple}
                                    fontSize={12}
                                    lineHeight={24}
                                    label={'Show this response'}
                                    fontWeight={400}
                                    fontStyle="italic"
                                />
                            </FlexColumnGap>
                            <FlexColumnGap gap="0.5" width="100%">
                                <Text
                                    fontColor={colorsCode.purple}
                                    fontSize={12}
                                    lineHeight={24}
                                    label={'Only if this logic is applied'}
                                    fontWeight={400}
                                    fontStyle="italic"
                                />
                            </FlexColumnGap>
                        </FlexRow>
                    </DisplayLogicContainer>
                    {responseLogicState?.conditions?.cases[0]?.logic?.map((item, logicIdx) => {
                        const responseOptions = conditions?.cases[0]?.logic[logicIdx]?.question_id
                            ? previousQuestionList.find(ele => ele?.question_id === item?.question_id)?.question_options
                            : item?.question_options;

                        return (
                            <DisplayLogicContainer key={item?.unique_id}>
                                <FlexRow>
                                    <FlexColumnGap gap="0.5" width="25%">
                                        <Typography
                                            style={{ color: `${colorsCode.TextSubtle}` }}
                                            component="span"
                                            fontWeight="400"
                                        >
                                            Responses
                                        </Typography>
                                        <SelectDropdown
                                            optionList={question_options}
                                            handleSelectOptions={(event, value) => {
                                                handleSelectOptions(event, value, logicIdx);
                                            }}
                                            defaultValue={item?.detailed_allowed_options}
                                        />
                                    </FlexColumnGap>
                                    <FlexColumnGap gap="0.5" width="25%">
                                        <Typography component="span" fontWeight="400">
                                            Select question
                                        </Typography>
                                        <SelectQuestionDropdown
                                            previousQuestionList={previousQuestionList}
                                            handleQuestionSelect={(event, selectedQues) => {
                                                handleQuestionSelect(event, selectedQues, logicIdx);
                                            }}
                                            selectedQues={item.selected_quest}
                                        />
                                    </FlexColumnGap>
                                    <FlexColumnGap gap="0.5" width="25%">
                                        <Typography component="span" fontWeight="400">
                                            Select response
                                        </Typography>
                                        <SelectDropdown
                                            optionList={responseOptions}
                                            handleSelectOptions={(event, value) => {
                                                handleSelectResponse(event, value, logicIdx);
                                            }}
                                            defaultValue={item?.selected_options}
                                        />
                                    </FlexColumnGap>
                                    <FlexColumnGap gap="0.5" width="25%">
                                        <Typography component="span" fontWeight="400">
                                            Select state
                                        </Typography>
                                        <SelectStateDropdown
                                            handleStateResponse={val => handleStateResponse(val, item?.question_id)}
                                            state={item?.state}
                                        />
                                    </FlexColumnGap>
                                    <FlexColumnGap>
                                        <img
                                            src={DeleteIcon}
                                            alt="delete img"
                                            onClick={e => toggleMenu(e, logicIdx)}
                                            style={{
                                                visibility: !logicIdx ? 'hidden' : 'unset',
                                                marginTop: '1.4rem',
                                                cursor: 'pointer',
                                            }}
                                        />
                                    </FlexColumnGap>
                                </FlexRow>
                            </DisplayLogicContainer>
                        );
                    })}
                </FlexColumnWithAlign>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'start', m: `${pxToVw(25)}vw` }}>
                <CTA
                    bg={colorsCode.PrimaryDeepBlue}
                    disabled={handleSaveDisable()}
                    pt={4}
                    pl={16}
                    size={12}
                    fw={400}
                    lh={28}
                    color={colorsCode.white}
                    onClick={handleSave}
                    id="basic-button"
                >
                    Save
                </CTA>
            </DialogActions>
            <Popover
                id={open ? 'simple-popover' : undefined}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={() => {
                    setAnchorEl(null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <PopoverWrapper>
                    <FlexRow gap={pxToVw(12)} alignItems="unset">
                        <Img image={RedWarningIcon} h={pxToVh(25)} w={pxToVw(25)} alt="" />
                        <FlexColumnGap gap={pxToVh(16)}>
                            <Box>
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={400}
                                    lineHeight={20}
                                    label="Deleting this thread will result in deleting the applied logic."
                                />
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={400}
                                    lineHeight={20}
                                    label="Are you sure you want to continue?"
                                />
                            </Box>
                            <FlexStartGap gap={pxToVw(24)}>
                                <React.Fragment>
                                    <CTA
                                        pl={16}
                                        pt={6}
                                        lh={20}
                                        size={14}
                                        bg={colorsCode.white}
                                        color={colorsCode.PrimaryDeepBlue}
                                        bcolor={colorsCode.PrimaryDeepBlue}
                                        onClick={() => setAnchorEl(null)}
                                    >
                                        Cancel
                                    </CTA>
                                    <CTA
                                        pl={16}
                                        pt={6}
                                        lh={20}
                                        size={14}
                                        bg={colorsCode.PrimaryDeepBlue}
                                        bcolor={colorsCode.PrimaryDeepBlue}
                                        color={colorsCode.white}
                                        onClick={() => {
                                            setAnchorEl(null);
                                            handleDeleteLogic(selectedLogicIndex);
                                        }}
                                    >
                                        Delete
                                    </CTA>
                                </React.Fragment>
                            </FlexStartGap>
                        </FlexColumnGap>
                    </FlexRow>
                </PopoverWrapper>
            </Popover>
        </Dialog>
    );
};

export const PopoverWrapper = styled(FlexColumnWithAlign)`
    padding: ${pxToVh(16)}vh ${pxToVw(16)}vw ${pxToVw(16)}vw ${pxToVw(16)}vw;
    max-width: 20rem;
`;

export default DisplayResponseLogicModal;
