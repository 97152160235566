/* istanbul ignore file */
import React from 'react';
import styled, { css } from 'styled-components';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Step,
    StepConnector,
    StepContent,
    StepLabel,
    Stepper,
    Menu,
    TableCell,
    tableCellClasses,
    Typography,
} from '@mui/material';
import { colorsCode, pxToVh, pxToVw } from '../colors';

export const Wrapper = styled.div`
    margin-left: 16vw;
`;

export const StyledButton = styled.button`
    background: ${props => (props.light ? `${colorsCode.SecondaryAmber100}` : `${colorsCode.PrimaryDeepBlue}`)};
    width: ${({ width }) => width || `10vw`} !important;
    min-height: ${({ minHeight }) => minHeight || `auto`};
    padding: ${props => (props.padding ? props.padding : `1.75vh 2vw`)};
    border-radius: 4px;
    border: 0;
    cursor: ${props => (props.disabled === true ? `not-allowed` : `pointer`)};
    opacity: ${props => (props.disabled === true ? '0.5' : '1')};
    color: ${props => (props.color ? `${props.color}` : `#fff`)};
    font-size: 2.26vh !important;
    @media (min-width: 1200px) {
        padding: 0.85vh 1.11vw !important;
        font-size: 1.11vw !important;
    }
`;

export const BorderLightButton = styled(StyledButton)`
    border: 1px solid ${({ borderColor }) => borderColor || colorsCode.PrimaryDeepBlue};
    color: ${props => props.color} !important;
    background: ${props => (props.light ? '#fff' : colorsCode.PrimaryDeepBlue)} !important;
    font-size: 1.98vh !important;
    font-weight: 400;
    line-height: 2.83vh;
    padding: 0.85vh 1.11vw !important;
    width: ${({ width }) => width || '7vw'} !important;
    @media (min-width: 1200px) {
        padding: 0.85vh 1.11vw !important;
        font-size: 0.98vw !important;
    }

    ${({ isDisabled }) =>
        isDisabled &&
        css`
            pointer-events: none;
            background: ${colorsCode.Neutral10} !important;
            color: ${colorsCode.Neutral30} !important;
            border: none;
        `}
`;

export const CTA = styled.button`
    background: ${({ bg, disabled }) => (disabled ? colorsCode.Neutral10 : bg)};
    padding: ${({ pt, pl }) => (pt && pl ? `${pxToVh(pt)}vh ${pxToVw(pl)}vw` : `0.85vh 1.11vw`)};
    cursor: ${({ disabled }) => (disabled === true ? `not-allowed` : `pointer`)};
    color: ${({ color, disabled }) => (disabled ? colorsCode.Neutral30 : color)};
    font-size: ${({ size }) => (size ? pxToVh(size) : pxToVh(14))}vh;
    line-height: ${({ lh }) => (lh ? pxToVh(lh) : pxToVh(20))}vh;
    font-weight: ${({ fw }) => (fw ? fw : 400)};
    border: ${({ bcolor, disabled }) => (bcolor && !disabled ? `1px solid ${bcolor}` : `none`)};
    border-radius: 4px;
    opacity: ${({ disabled }) => (disabled === true ? '0.5' : '1')};
    width: fit-content;
`;

export const Flex = styled.div`
    display: flex;
    flex-wrap: ${({ flexWrap }) => flexWrap};
`;

export const GridDiv = styled.div`
    display: grid;
`;

export const FlexStart = styled(Flex)`
    justify-content: flex-start;
`;

export const FlexCenter = styled(Flex)`
    justify-content: center;
`;

export const FlexCenterWithAlign = styled(Flex)`
    justify-content: center;
    align-items: center;
    gap: ${({ gap }) => gap}vw;
`;

export const FlexColumnCenter = styled(FlexCenterWithAlign)`
    flex-direction: column;
    gap: ${({ gap }) => (gap ? gap : '0.7')}vh;
    margin: ${({ margin }) => margin};
`;

export const FlexAlignCenter = styled(FlexStart)`
    align-items: center;
    gap: ${({ gap }) => gap}vw;
`;

export const FlexBetween = styled(Flex)`
    justify-content: space-between;
    align-items: ${({ alignItems }) => alignItems || 'center'};
    margin: ${({ margin }) => margin};
    gap: ${({ gap }) => (gap ? `${gap}vw` : null)};
    background: ${({ bg }) => bg};
`;

export const FlexEnd = styled(Flex)`
    justify-content: flex-end;
`;

export const FlexSelfEnd = styled(Flex)`
    align-self: flex-end;
`;

export const TwoColumn = styled(GridDiv)`
    grid-template-columns: auto auto;
`;

export const FlexColumn = styled(Flex)`
    flex-direction: column;
    align-items: flex-start;
`;

export const FlexColumnWithAlign = styled(Flex)`
    flex-direction: column;
    align-items: ${({ alignItems }) => alignItems || 'flex-start'};
    gap: ${({ gap }) => gap || '0.7'}vh;
    margin: ${({ margin }) => margin};
`;

export const FlexRow = styled(Flex)`
    flex-direction: row;
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    gap: ${props => (props.gap ? props.gap : '0.7')}vw;
    justify-content: ${props => (props.justifyContent ? props.justifyContent : 'flex-start')};
    margin: ${({ margin }) => margin};
`;

export const FlexRowWidth = styled(FlexRow)`
    width: ${props => (props.width ? props.width : '100%')};
`;

export const FlexRowCTA = styled(Flex)`
    flex-direction: row;
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    gap: ${props => (props.gap ? props.gap : '2.5')}vw;
    justify-content: flex-start;
`;

export const FlexRowTextCTA = styled(Flex)`
    flex-direction: row;
    align-items: ${props => (props.alignItems ? props.alignItems : 'center')};
    gap: ${props => (props.gap ? props.gap : '1.67')}vw;
    justify-content: flex-start;
`;
export const WrapperHeader = styled.div`
    margin-left: 10vw;
    width: 90vw;
    overflow: hidden;
`;

export const TableCellStyled = styled(TableCell)(({ noWrap, textAlign = 'left' }) => ({
    [`&.${tableCellClasses.body}`]: {
        paddingTop: '1.4vh',
        paddingBottom: '1.4vh',
        fontSize: '1.98vh',
        lineHeight: '2.83vh',
        borderBottom: `1px solid ${colorsCode.Neutral10}`,
        borderLeft: `1px solid ${colorsCode.Neutral10}`,
        background: colorsCode.white,
        whiteSpace: noWrap ? 'noWrap' : 'auto',
        textAlign: textAlign,
    },
    [`&.${tableCellClasses.head}`]: {
        paddingTop: '1.4vh',
        paddingBottom: '1.4vh',
        fontSize: '1.98vh',
        lineHeight: '2.83vh',
        borderBottom: '1px solid black',
        whiteSpace: noWrap ? 'noWrap' : 'auto',
        background: colorsCode.white,
        borderLeft: `1px solid ${colorsCode.Neutral10}`,
    },
}));

export const SectionDiv = styled.section`
    margin-right: 4vw;
`;

export const FlexColumnBetween = styled(FlexColumn)`
    justify-content: space-between;
    gap: ${props => props.gap}vh;
`;

export const FlexStartGap = styled(FlexStart)`
    gap: ${props => props.gap}vw;
`;

export const FlexColumnGap = styled(FlexColumn)`
    gap: ${props => props.gap}vh;
    width: ${props => (props.width ? props.width : '')};
`;

export const TextButton = styled(Typography)`
    color: ${({ color }) => color} !important;
    font-size: 1.75vh !important;
    font-weight: 400;
    line-height: 2.33vh;
    text-transform: ${({ allCaps }) => (allCaps ? 'uppercase' : '')};
    letter-spacing: 0.6px;
    cursor: ${props => (props.disabled === true ? `not-allowed` : `pointer`)};
    opacity: ${props => (props.disabled === true ? '0.5' : '1')};
`;

export const CircleIcon = styled.div`
    min-width: ${({ size }) => size || 12}px;
    min-height: ${({ size }) => size || 12}px;
    max-width: ${({ size }) => size || 12}px;
    max-height: ${({ size }) => size || 12}px;
    border-radius: 50%;
    border: 1px solid ${({ color }) => color || colorsCode.TextSubtle};
    background-color: ${({ color }) => color || 'transparent'};
    display: flex;
`;

export const Divider = styled.div`
    width: ${({ horizontal, size }) => (horizontal ? '100%' : size || '1px')};
    height: ${({ horizontal, size }) => (!horizontal ? 'auto' : size || '1px')};
    background: ${({ color }) => color || colorsCode.Neutral10};
    align-self: stretch;
`;

export const StyledFilterMenu = styled(Menu)`
    .MuiMenu-paper {
        height: ${({ height }) => height || '545px'};
        box-shadow: unset;
        background-color: unset;
        overflow-y: unset;
        overflow-x: unset;
    }
    .Mui-expanded {
        border-bottom: none !important;
    }

    .MuiList-root {
        padding: 0;
        box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14),
            0px 3px 14px 2px rgba(0, 0, 0, 0.12);
        background-color: ${colorsCode.white};
        border-radius: 4px;
    }
`;

// knowledge-center common styled components

// eslint-disable-next-line
export const AccordionStyled = styled(({ noDoubleBorder, paddingBottom, paddingTop, ...rest }) => (
    <Accordion {...rest} />
))`
    &&& {
        box-shadow: none;
        border-radius: 0;
        border-top: 2px solid ${colorsCode.Neutral10};
        width: 100%;
        margin: 0;
        padding-bottom: ${({ paddingBottom }) => pxToVh(paddingBottom ? paddingBottom : 16)}vh;
        padding-top: ${({ paddingTop }) => pxToVh(paddingTop || 0)}vh;

        :last-child {
            border-bottom: 1px solid ${colorsCode.Neutral10};
        }

        ${({ noDoubleBorder }) =>
            noDoubleBorder &&
            css`
                &:not(:first-child) {
                    border-top: 1px solid ${colorsCode.Neutral10};
                }
            `}

        :before {
            display: none;
        }
        .MuiCollapse-hidden {
            .note-chip {
                opacity: 0 !important;
            }
        }
    }
`;

// eslint-disable-next-line
export const AccordionSummaryStyled = styled(({ pdTop, pdBottom = 8, ...rest }) => <AccordionSummary {...rest} />)`
    &&& {
        padding: ${({ pdTop = 6, pdBottom = 8, pdLeft = 0 }) =>
            `${pxToVh(pdTop)}vh 0 ${pxToVh(pdBottom)}vh ${pxToVh(pdLeft)}vw`};
        min-height: auto;
        width: ${({ width }) => width || `unset`};

        .MuiAccordionSummary-content {
            margin: 0;
        }

      .MuiAccordionSummary-expandIconWrapper, .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
      {
        margin-left: ${({ pdLeftIcon }) => `${pxToVh(pdLeftIcon)}vw`};
      }
    }
`;

export const AccordionDetailsStyled = styled(AccordionDetails)`
    && {
        padding: ${pxToVh(12)}vh 0 0 0;
    }
`;

export const StepperStyled = styled(Stepper)``;

export const StepConnectorStyled = styled(StepConnector)`
    && {
        margin-left: calc(${pxToVh(17.5)}vh - 1.5px);

        .MuiStepConnector-line {
            border: none;
            min-height: calc(${pxToVh(30)}vh - 2px);
            width: 3px;
            background-color: ${colorsCode.deepBlue400};
            margin-bottom: 2px;

            ${({ isLast }) =>
                isLast &&
                css`
                    border-bottom-right-radius: 2px;
                    border-bottom-left-radius: 2px;
                `}

            ${({ isFirst }) =>
                isFirst &&
                css`
                    border-top-right-radius: 2px;
                    border-top-left-radius: 2px;
                    min-height: calc(${pxToVh(20)}vh - 2px);
                `}
        }
    }
`;

export const StepStyled = styled(Step)``;

export const StepLabelStyled = styled(StepLabel)`
    position: relative;

    &::before {
        position: absolute;
        top: ${pxToVh(35)}vh;
        margin-top: 2px;
        height: 100%;
        width: 3px;
        margin-left: calc(${pxToVh(17.5)}vh - 1.5px);
        background-color: ${colorsCode.deepBlue400};
        content: '';
        z-index: -1;
    }

    && {
        align-items: flex-start;
        padding: 0 0 ${pxToVh(8)}vh 0;

        .MuiStepLabel-iconContainer {
            padding-right: ${pxToVw(20)}vw;
        }
    }
`;

export const StepContentStyled = styled(StepContent)`
    && {
        padding-left: calc(${pxToVh(17.5)}vh + ${pxToVw(20)}vw);
        margin-left: calc(${pxToVh(17.5)}vh - 1.5px);
        border-left: 3px solid ${colorsCode.deepBlue400};
        height: 100%;
    }
`;

export const FootNote = styled(FlexCenter)`
    display: inline-flex;
    border: 1px solid ${colorsCode.SecondaryDeepBlue500};
    border-radius: 50%;
    min-height: ${pxToVh(16)}vh;
    min-width: ${pxToVh(16)}vh;
    max-height: ${pxToVh(16)}vh;
    max-width: ${pxToVh(16)}vh;
    position: relative;
    top: 0;
    transform: translateY(-40%);
    margin: 0 0 0 ${pxToVw(2)}vw;
    cursor: pointer;
`;

export const Section = styled.div`
    width: 100%;
    height: 100%;
    padding: ${({ padding }) => padding || `${pxToVh(74)}vh ${pxToVw(60)}vw ${pxToVh(100)}vh`};
    display: flex;
    flex-direction: column;
    gap: ${({ gap }) => gap}vh;

    > div {
        scroll-margin-top: 20vh;
    }
`;

export const Bottomline = styled.div`
    height: ${pxToVh(4)}vh;
    background: ${props => (props.error ? `${colorsCode.error}` : `${colorsCode.PrimaryDeepBlue}`)};
    align-self: ${({ alignself }) => alignself || `stretch`};
    width: ${props => (props.width ? `${props.width}vw` : `auto`)};
`;

export const BottomSubline = styled.div`
    height: ${pxToVh(3)}vh;
    background: ${props => (props.error ? `${colorsCode.error}` : `${colorsCode.PrimaryDeepBlue}`)};
    align-self: ${({ alignself }) => alignself || `stretch`};
    width: ${props => (props.width ? `${props.width}vw` : `auto`)};
`;

export const CommonSection = styled.div`
    padding: ${pxToVh(24)}vh ${pxToVw(20)}vw;
    border-radius: 8px;
    border: 1px solid ${colorsCode.Neutral10};
    border: ${props => (props.error ? `1px solid ${colorsCode.error}` : `1px solid ${colorsCode.Neutral10}`)};
    box-shadow: 0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.2);
    display: flex;
    align-self: stretch;
`;

export const FlexInline = styled.div`
    display: inline-flex;
    align-items: flex-start;
`;

export const HeaderInLine = styled.div`
    display: flex;
    align-items: center;
    font-weight: 400;
    width: 100%;
    color: ${colorsCode.TextHeading};
    font-size: ${({ textSize }) => pxToVh(textSize)}vh;
    line-height: ${({ lineHeight }) => pxToVh(lineHeight)}vh;
    padding-right: ${pxToVw(20)}vh;

    .questionCategory {
        max-width: 70%;
    }

    .midLine {
        margin: 0 ${pxToVh(16)}vh;
        flex-grow: 1;
        height: 1px;
        background: ${colorsCode.Neutral10};
    }

    .selectedQues {
        color: ${colorsCode.TextSubtle};
        font-size: ${({ subTextSize }) => pxToVh(subTextSize)}vh;
    }
`;

export const QuestionContent = styled.div`
    border-radius: 8px;
    background: var(--Neutral-White, #fff);
    box-shadow: 0 0 1px 0 rgba(5, 28, 44, 0.15), 0 2px 4px -1px rgba(5, 28, 44, 0.2);
    font-size: ${({ textSize }) => pxToVh(textSize)}vh;
    line-height: ${({ lineHeight }) => pxToVh(lineHeight)}vh;
    color: ${colorsCode.Neutral80};
    margin-top: ${pxToVh(28)}vh;
`;

export const TextWithCheckbox = styled.div`
    padding-left: ${pxToVh(44)}vh;

    .innerChild {
        position: absolute;
        left: 0;
        width: ${pxToVh(44)}vh;
        padding-left: ${pxToVw(8)}vw;

        .MuiCheckbox-root {
            position: relative;
            top: ${pxToVh(-1)}vh;

            .img {
                height: ${pxToVh(20)}vh;
                width: ${pxToVh(20)}vh;
            }
            svg {
                font-size: 3.732vh;
                color: ${colorsCode.TextHeading};
                position: relative;
                left: -0.27vh;
            }
        }
    }
`;

export const FlexGap = styled.div`
    display: flex;
    gap: ${({ gap }) => gap}vw;
`;

export const QuestionList = styled.div`
    margin: ${pxToVh(28)}vh 0 ${pxToVh(16)}vh ${pxToVh(36)}vh;
    padding-left: 0;
    column-count: 2;
    -moz-column-count: 2;
    -webkit-column-count: 2;
    overflow: hidden;

    .grid-item {
        margin-bottom: ${({ mgBtm = 16 }) => pxToVh(mgBtm)}vh;
    }
`;
