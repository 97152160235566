import React, { Fragment, memo, useEffect, useState } from 'react';
import { Typography, Paper } from '@mui/material';
import styled from 'styled-components';
import { FlexColumnGap, FlexRow, FlexStart } from '../../../common/styled/styled';
import cal from '../../../assets/img/cal.svg';
import approved from '../../../assets/img/approved.svg';
import pending from '../../../assets/img/T-warning.svg';
import arrowRight from '../../../assets/img/Arrow-right.svg';
import doc from '../../../assets/img/document.svg';
import color from '../../../assets/img/Color.svg';
import next from '../../../assets/img/next.svg';
import moment from 'moment';
import nodata from '../../../assets/img/no-data.svg';
import { cloneDeep } from 'lodash';
import { ACTUAL_STATUS, SURVEY_STATUS } from '../../../global-constants';
import { FlexRowStyled } from './Admin/ManageSurveys';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { CommonLabel } from '../../../common/FormInputs/Common';
import { Badge } from '../../Surveys/partials/setup-survey/Badge';

const AllSurveys = ({ styles, listing = [], data = [], handleResume = () => {} }) => {
    const [list, setList] = useState(listing);

    useEffect(() => {
        const draft = listing.length ? listing.slice(0, 3) : [];
        setList(draft);
    }, [listing]);

    const handleNext = () => {
        const copy = cloneDeep(list);
        setList([copy[2]]);
    };

    const handlePrev = () => {
        setList(listing);
    };

    const renderColoredChips = status => {
        switch (status) {
            case SURVEY_STATUS.REVIEWED:
                return (
                    <FlexRowStyled className="reviewed">
                        <img src={approved} alt="" className="imgApprove" />
                        <Typography className="text">{ACTUAL_STATUS[status] || '-'}</Typography>
                    </FlexRowStyled>
                );
            case SURVEY_STATUS.UNDER:
                return (
                    <FlexRowStyled className="underreview">
                        <img src={pending} alt="" className="imgApprove" />
                        <Typography className="text">{ACTUAL_STATUS[status] || '-'}</Typography>
                    </FlexRowStyled>
                );
        }
    };

    return (
        <FlexRowGap>
            {list.length ? (
                list.map(
                    (
                        {
                            survey_name = '',
                            survey_id = '',
                            uuid = '',
                            survey_template,
                            created_on = '',
                            status,
                            survey_sub_type = '',
                        },
                        i
                    ) => (
                        <Paper
                            className="fnd"
                            key={uuid + i}
                            sx={{ borderRadius: '8px', boxShadow: colorsCode.boxShadow }}
                        >
                            <FlexColShrink>
                                <FlexColStart>
                                    <FlexRow>
                                        <img src={doc} className="image" alt="doc" />
                                        <Typography className={`${styles.surveyText}`}>Survey ID</Typography>
                                        <div className="surveyStatusClass ">{renderColoredChips(status)}</div>
                                    </FlexRow>
                                    <Typography
                                        className="surveyData text-deco"
                                        onClick={() => handleResume(uuid, survey_template)}
                                        sx={{ cursor: 'pointer' }}
                                    >
                                        {survey_id.toUpperCase()}
                                    </Typography>
                                    <FlexStart></FlexStart>
                                </FlexColStart>
                                <FlexColStart>
                                    <FlexRow>
                                        <img src={color} className="image" alt="color" />
                                        <Typography className={`${styles.surveyText}`}>Survey Name</Typography>
                                        <Badge
                                            data={data}
                                            survey_template={survey_template}
                                            survey_sub_type={survey_sub_type}
                                        />
                                    </FlexRow>
                                    <Typography className="typography" title={survey_name}>
                                        {survey_name.length > 20 ? survey_name.slice(0, 17) + '...' : survey_name}
                                    </Typography>
                                </FlexColStart>
                            </FlexColShrink>
                            <FlexColChild>
                                <FlexColStart>
                                    <FlexRow>
                                        <img src={cal} className="image" alt="calender" />
                                        <Typography className={`${styles.surveyText}`}>Created on</Typography>
                                    </FlexRow>
                                    <Typography className={`surveyData`}>
                                        {moment.unix(created_on).format('DD-MMM-YYYY')}
                                    </Typography>
                                </FlexColStart>
                                <FlexColStart>
                                    <FlexRow>
                                        <Typography
                                            sx={{ fontSize: '1.98vh', lineHeight: '3.39vh', cursor: 'pointer' }}
                                            color="#2B5580"
                                            fontWeight={400}
                                            onClick={() => {
                                                handleResume(uuid, survey_template);
                                            }}
                                        >
                                            RESUME SETUP
                                        </Typography>
                                        <img
                                            src={arrowRight}
                                            className="image"
                                            alt="arrow"
                                            onClick={() => {
                                                handleResume(uuid, survey_template);
                                            }}
                                            style={{ cursor: 'pointer' }}
                                        />
                                    </FlexRow>
                                </FlexColStart>
                            </FlexColChild>
                        </Paper>
                    )
                )
            ) : (
                <Paper
                    sx={{
                        padding: `0 ${pxToVw(40)}vw ${pxToVh(10)}vh ${pxToVw(40)}vw`,
                        borderRadius: '8px',
                        boxShadow: '0px 0px 1px 0px #051c2c26, 0px 2px 4px -1px #051c2c26',
                    }}
                    elevation={2}
                >
                    <FlexStartGap gap={pxToVw(30)}>
                        <img
                            src={nodata}
                            alt="no-data-found"
                            style={{ width: `${pxToVw(160)}vw`, height: `${pxToVh(174)}vh` }}
                        />
                        <FlexColumnGap gap={pxToVh(2)} style={{ justifyContent: 'center' }}>
                            <CommonLabel
                                textSize={20}
                                textWeight={500}
                                lineHeight={32}
                                textColor={colorsCode.Neutral80}
                                label="No pending tasks"
                            />
                            <Fragment>
                                <CommonLabel
                                    textSize={14}
                                    textWeight={400}
                                    lineHeight={20}
                                    textColor={colorsCode.Neutral80}
                                    label="All outstanding tasks will be displayed here."
                                />
                                <CommonLabel
                                    textSize={14}
                                    textWeight={400}
                                    lineHeight={20}
                                    textColor={colorsCode.Neutral80}
                                    label="Currently, there are no pending assignments for you."
                                />
                            </Fragment>
                        </FlexColumnGap>
                    </FlexStartGap>
                </Paper>
            )}
            {listing.length && list.length > 2 ? (
                <div className="next">
                    <div className="classFlex">
                        <img src={next} alt="" className="img" onClick={handleNext} />
                    </div>
                </div>
            ) : null}
            {list.length === 1 && listing.length > 2 ? (
                <div className="prev">
                    <div className="classFlex">
                        <img src={next} alt="" className="img rotate" onClick={handlePrev} />
                    </div>
                </div>
            ) : null}
        </FlexRowGap>
    );
};

export const FlexStartGap = styled(FlexStart)`
    gap: 3.22vw !important;
`;

export const FlexColumnParent = styled.div`
    display: flex;
    height: 19.96vh;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
`;
export const FlexColStart = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 0.55vw;
    align-self: stretch;
`;

export const FlexColShrink = styled(FlexColumnParent)`
    width: 15.5vw;
    flex-shrink: 0;
`;

export const FlexColChild = styled(FlexColumnParent)`
    flex: 1 0 0;
    width: 10.4vw;
`;

export const FlexRowGap = styled(FlexRow)`
    gap: 1.67vw !important;
    overflow-x: hidden;
    padding: 1vh;
    overflow-x: hidden;
`;

export default memo(AllSurveys);
