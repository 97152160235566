import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { FlexColumnGap, FlexEnd, FlexRow, TableCellStyled } from '../../../common/styled/styled';
import { Typography, Paper, TableRow, TableHead, TableBody, Table, MenuItem, Menu } from '@mui/material';
import arrowRight from '../../../assets/img/Arrow-right.svg';
import actions from '../../../assets/img/Menu-5.svg';
import moment from 'moment';
import PropTypes from 'prop-types';
import SearchBar from '../../../common/FormInputs/SearchBar';
import CustomDateRangePicker from '../../../common/FormInputs/CustomDateRangePicker';
import { FlexRowGap } from './AllSurveys';
import PaginationComponent from '../../User/PaginationComponent';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import { Badge } from '../../Surveys/partials/setup-survey/Badge';
import { CommonLabel } from '../../../common/FormInputs/Common';
import { CONFIG } from '../../../global-constants';
import AuthStore from '../../../common/AuthStore';
import ResponseReporting from './Reporting/ResponseReporting';
import { createPortal } from 'react-dom';
import SurveyExportModal from '../../SurveyExportModal';
import { DownloadMonitorFiles, SurveyTemplatesLang } from '../../../store/actions/Surveys/action';
import { Icon } from '@mds/mds-reactjs-library';
import Outline24MoveLayerDown from '@mds/mds-icons/icons/svg/outline-24-move-layer-down.svg';
import { filter } from 'lodash';
import ConfirmationPopUp from '../../ConfirmationPopUp/ConfirmationPopUp';

const ClosedSurveys = ({
    listing = [],
    data = [],
    onSearchFn,
    OnDateChangeFn,
    pagination = false,
    total = 0,
    page = 1,
    limit = 10,
    handleChangeFn = () => {},
    handleItemPerPageFn = () => {},
    noDataMessage = 'No closed survey found',
    handleMonitorSurvey,
    search,
    handleResume,
    templatesLang,
    dispatchAction,
    location = '',
}) => {
    const [anchorEl, setAnchorEl] = useState(false);
    const [showConfirmation, setShowConfirmation] = useState('');
    const [params, setId] = useState({
        uuid: '',
        survey_template: '',
        template_name: '',
        client_name: '',
        total_completed: 0,
        status: '',
        langid: '',
    });
    const [surveyLanguages, setSurveyLanguages] = useState(templatesLang);
    const [surveyExport, setSurveyExport] = useState({
        open: false,
        type: 'base',
        isMulti: false,
    });
    const [menuOptions, setMenuOptions] = useState({ distribute: true });
    const menuOpen = Boolean(anchorEl);
    const [showReport, setShowReport] = useState(false);

    useEffect(() => {
        if (params?.langid?.split(',').length > 1 && templatesLang?.length) {
            const filteredLanguages = filter(templatesLang, lang => {
                return lang?.short_code !== 'en' && params.langid.includes(lang.uuid);
            });
            setSurveyLanguages(filteredLanguages);
        }
    }, [params, templatesLang]);

    const handleMenu = (e, surveyId, survey_template, status, langid) => {
        const template_name = data?.find(({ uuid }) => uuid === survey_template)?.template_name;
        const client_name = listing?.find(({ uuid }) => uuid === surveyId)?.client_name;
        const total_completed = listing?.find(({ uuid }) => uuid === surveyId)?.response_rate?.total_completed;

        if (template_name === 'LTE') {
            setMenuOptions(prev => {
                return { ...prev, distribute: false };
            });
        } else {
            setMenuOptions(prev => {
                return { ...prev, distribute: true };
            });
        }
        setAnchorEl(e.currentTarget);
        setId({
            uuid: surveyId,
            survey_template,
            template_name,
            client_name,
            total_completed,
            status,
            langid,
        });
    };
    // handle survey export
    const handleSurveyExport = () => {
        setAnchorEl(false);
        if (params.langid.split(',').length > 1) {
            dispatchAction(SurveyTemplatesLang({ tid: params.survey_template }));
            setSurveyExport({
                ...surveyExport,
                open: true,
            });
        } else {
            dispatchAction(DownloadMonitorFiles({ surveyId: params.uuid, langid: params.langid }));
        }
    };

    const handleGenerateResult = () => {
        if (params.template_name === 'DE&I Maturity Assessment' || params.template_name === 'TEI') {
            setShowReport(true);
        } else {
            let downloadLink = document.createElement('a');
            downloadLink.target = '_blank';
            downloadLink.href = `${CONFIG.ANALYTICS_PORTAL_LINK}?#jwt=${AuthStore.getMidToken}`;
            document.body.appendChild(downloadLink);
            downloadLink.click();
            document.body.removeChild(downloadLink);
        }
    };
    return (
        <ClosedSurveyDiv data-testid="closed">
            <FlexColumnGap gap="3">
                <FlexRowGapStyled gap="1">
                    <SearchBar
                        placeholder="Search"
                        id="search"
                        name="search"
                        onChangeFn={onSearchFn}
                        val={search}
                        callApi={true}
                    />
                    <CustomDateRangePicker OnDateChangeFn={OnDateChangeFn} />
                </FlexRowGapStyled>
                <FlexColumnGap style={{ alignSelf: 'stretch' }}>
                    <Table
                        size="small"
                        component={Paper}
                        className="tableClass table"
                        sx={{
                            boxShadow: '0px 0px 1px 0px #051c2c26, 0px 2px 4px -1px #051c2c26',
                        }}
                    >
                        <TableHead>
                            <TableRow>
                                <TableCellStyled sx={{ width: '300px' }}>Survey name</TableCellStyled>
                                <TableCellStyled>Survey template</TableCellStyled>
                                <TableCellStyled>
                                    <FlowRowGap>Created on</FlowRowGap>
                                </TableCellStyled>
                                <TableCellStyled>
                                    <FlowRowGap>Live on</FlowRowGap>
                                </TableCellStyled>
                                <TableCellStyled>
                                    <FlowRowGap>Closed on</FlowRowGap>
                                </TableCellStyled>
                                <TableCellStyled>Actions</TableCellStyled>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {listing.length ? (
                                listing.map(
                                    (
                                        {
                                            survey_name = '',
                                            survey_id = '',
                                            uuid = '',
                                            survey_template,
                                            created_on = '',
                                            survey_sub_type = '',
                                            launch_date,
                                            closed_on = '',
                                            status,
                                            supported_languages = '',
                                        },
                                        i
                                    ) => (
                                        <TableRow key={i}>
                                            <TableCellStyled>
                                                <CommonLabel
                                                    textWeight={400}
                                                    textSize={14}
                                                    lineHeight={20}
                                                    textColor={colorsCode.Neutral80}
                                                    title={`${survey_id.toUpperCase()} - ${survey_name}`}
                                                    label={`${survey_id.toUpperCase()} - ${
                                                        survey_name.length > 20
                                                            ? survey_name.slice(0, 20) + '...'
                                                            : survey_name
                                                    }`}
                                                />
                                            </TableCellStyled>
                                            <TableCellStyled>
                                                <Badge
                                                    data={data}
                                                    survey_template={survey_template}
                                                    survey_sub_type={survey_sub_type}
                                                />
                                            </TableCellStyled>
                                            <TableCellStyled className="tblecellStyled">
                                                {moment.unix(created_on).format('DD-MMM-YYYY')}
                                            </TableCellStyled>
                                            <TableCellStyled className="tblecellStyled">
                                                {launch_date ? moment.unix(launch_date).format('DD-MMM-YYYY') : '-'}
                                            </TableCellStyled>
                                            <TableCellStyled className="tblecellStyled">
                                                {status === 'COMPLETED' && closed_on
                                                    ? moment.unix(closed_on).format('DD-MMM-YYYY')
                                                    : '-'}
                                            </TableCellStyled>
                                            <TableCellStyled sx={{ textAlign: 'center' }}>
                                                <img
                                                    src={actions}
                                                    alt="clickmenu"
                                                    style={{
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={e =>
                                                        handleMenu(
                                                            e,
                                                            uuid,
                                                            survey_template,
                                                            status,
                                                            supported_languages
                                                        )
                                                    }
                                                />
                                            </TableCellStyled>
                                        </TableRow>
                                    )
                                )
                            ) : (
                                <TableRow>
                                    <TableCellStyled colSpan={7} sx={{ textAlign: 'center' }}>
                                        {noDataMessage}
                                    </TableCellStyled>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                    {pagination && listing.length && total > limit ? (
                        <FlexEnd style={{ alignSelf: 'stretch' }}>
                            <PaginationComponent
                                handleChangeFn={handleChangeFn}
                                handleItemPerPageFn={handleItemPerPageFn}
                                count={Math.ceil(total / limit)}
                                page={page}
                                perPage={limit}
                            />
                        </FlexEnd>
                    ) : null}
                </FlexColumnGap>
            </FlexColumnGap>

            {/* menu option */}
            {menuOpen ? (
                <Menu
                    anchorEl={anchorEl}
                    open={menuOpen}
                    onClose={() => setAnchorEl(false)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    sx={{
                        '& .MuiMenu-paper': {
                            boxShadow: '0px 0px 1px 0px rgba(5, 28, 44, 0.15), 0px 2px 4px -1px rgba(5, 28, 44, 0.20)',
                            width: `auto`,
                            marginLeft: `${pxToVw(-23)}vw`,
                            marginTop: `${pxToVh(10)}vh`,
                        },
                    }}
                    data-testid="menu"
                >
                    <MenuItem value="monitor">
                        <FlexRow>
                            <Typography
                                className="actionClass"
                                color="#2B5580"
                                onClick={() => handleMonitorSurvey(params.uuid)}
                                data-testid="monitor"
                            >
                                MONITOR
                            </Typography>
                            <img
                                src={arrowRight}
                                className="image"
                                alt=""
                                onClick={() => handleMonitorSurvey(params.uuid)}
                            />
                        </FlexRow>
                    </MenuItem>
                    <MenuItem value="monitor">
                        <FlexRow>
                            <Typography
                                className="actionClass"
                                color="#2B5580"
                                onClick={() => handleResume(params.uuid, params.survey_template)}
                                data-testid="monitor"
                            >
                                DISTRIBUTE SURVEY
                            </Typography>
                            <img
                                src={arrowRight}
                                className="image"
                                alt=""
                                onClick={() => handleResume(params.uuid, params.survey_template)}
                            />
                        </FlexRow>
                    </MenuItem>
                    {location !== 'analytics' ? (
                        <MenuItem value="monitor">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleSurveyExport();
                                    }}
                                >
                                    SURVEY EXPORT
                                </Typography>

                                <Icon
                                    src={Outline24MoveLayerDown}
                                    name="export"
                                    style={{
                                        height: `${pxToVh(12)}vh`,
                                        width: `${pxToVw(12)}vw`,
                                        color: colorsCode.SecondaryDeepBlue500,
                                    }}
                                    onClick={() => {
                                        setAnchorEl(false);
                                        handleSurveyExport();
                                    }}
                                />
                            </FlexRow>
                        </MenuItem>
                    ) : null}

                    {menuOptions.distribute ? (
                        <MenuItem value="monitor">
                            <FlexRow>
                                <Typography
                                    className="actionClass"
                                    color="#2B5580"
                                    onClick={() => handleGenerateResult()}
                                    data-testid="monitor"
                                >
                                    GENERATE RESULT
                                </Typography>
                                <img src={arrowRight} className="image" alt="" onClick={() => handleGenerateResult()} />
                            </FlexRow>
                        </MenuItem>
                    ) : null}
                </Menu>
            ) : null}
            {showReport ? (
                <ResponseReporting
                    surveyId={params?.uuid}
                    onClose={() => setShowReport(false)}
                    clientName={params?.client_name}
                    totalCompleted={params?.total_completed}
                    setShowConfirmation={setShowConfirmation}
                    isTEI={params?.template_name === 'TEI'}
                />
            ) : null}
            {showConfirmation && (
                <ConfirmationPopUp
                    isOpen={!!showConfirmation}
                    message={showConfirmation}
                    onClose={() => setShowConfirmation('')}
                />
            )}
            {createPortal(
                SurveyExportModal(
                    surveyExport,
                    setSurveyExport,
                    surveyLanguages,
                    setSurveyLanguages,
                    setId,
                    dispatchAction,
                    params,
                    listing
                ),
                document.body
            )}
        </ClosedSurveyDiv>
    );
};

const FlowRowGap = styled(FlexRow)`
    gap: 1.49vw !important;
`;

const FlexRowGapStyled = styled(FlexRowGap)`
    overflow-x: unset;
`;

export const ClosedSurveyDiv = styled.div`
    .flexBetween {
        align-self: stretch;
    }
`;

ClosedSurveys.propTypes = {
    listing: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
};

export default ClosedSurveys;
