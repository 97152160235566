import {
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormLabel,
    MenuItem,
    Radio,
    Select,
    TextField,
} from '@mui/material';
import React, { useState } from 'react';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { cloneDeep } from 'lodash';
import ArrowDown from '../../../../../../assets/img/arrow_down.svg';
import { PaddingContainer } from '../DisplayLogicQuestion';

export function CustomPopupIcon() {
    return <img src={ArrowDown} alt="Arrow Icon" />;
}
export const SelectDropdown = ({ optionList = [], handleSelectOptions, defaultValue }) => {
    const sortedOptions = cloneDeep(optionList);
    sortedOptions?.sort((a, b) => a?.option_order - b?.option_order);
    return (
        <Autocomplete
            placeholder={'Select an option'}
            disableCloseOnSelect
            multiple
            value={defaultValue}
            options={sortedOptions}
            limitTags={1}
            getOptionLabel={option => option?.option_text}
            onChange={handleSelectOptions}
            size="small"
            sx={{ width: '100%' }}
            isOptionEqualToValue={(option, value) => option?.option_id === value?.option_id}
            renderTags={() => (
                <PaddingContainer padding="0 0 0 8px">
                    {defaultValue?.length === 1
                        ? defaultValue[0]?.option_text
                        : `${defaultValue?.length} options selected`}
                </PaddingContainer>
            )}
            renderOption={(props, option, { selected }) => {
                return (
                    <li {...props}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option?.option_text}
                    </li>
                );
            }}
            renderInput={params => (
                <TextField {...params} placeholder={defaultValue?.length ? '' : 'Select an option'} />
            )}
        />
    );
};

export const SelectQuestionDropdown = ({ previousQuestionList, handleQuestionSelect, selectedQues }) => {
    return (
        <Autocomplete
            value={selectedQues ? selectedQues : null}
            options={previousQuestionList}
            onChange={(event, newValue) => {
                handleQuestionSelect(event, newValue);
            }}
            size="small"
            sx={{ width: '100%' }}
            getOptionLabel={option => option.question_text.replace(/<\/?[^>]+(>|$)/g, '')}
            renderInput={params => {
                return <TextField {...params} placeholder="Select an option" />;
            }}
            popupIcon={<CustomPopupIcon />}
        />
    );
};

export const SelectResponseDropdown = ({ optionList = [], handleSelectResponse, defaultValue }) => {
    const sortedOptions = cloneDeep(optionList);
    sortedOptions?.sort((a, b) => a?.option_order - b?.option_order);
    return (
        <Autocomplete
            disableCloseOnSelect
            multiple
            value={defaultValue}
            options={sortedOptions}
            limitTags={1}
            getOptionLabel={option => option.option_text}
            onChange={handleSelectResponse}
            size="small"
            sx={{ width: '100%' }}
            renderOption={(props, option, { selected }) => {
                if (defaultValue?.length) {
                    selected = defaultValue.map(itm => itm.option_id).includes(option.option_id);
                }
                return (
                    <li {...props} style={{ padding: '0' }}>
                        <Checkbox
                            icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                            checkedIcon={<CheckBoxIcon fontSize="small" />}
                            style={{ marginRight: 8 }}
                            checked={selected}
                        />
                        {option?.option_text}
                    </li>
                );
            }}
            renderTags={() => (
                <PaddingContainer padding="0 0 0 8px">
                    {defaultValue?.length === 1
                        ? defaultValue[0]?.option_text
                        : `${defaultValue?.length} options selected`}
                </PaddingContainer>
            )}
            renderInput={params => (
                <TextField {...params} placeholder={defaultValue?.length ? '' : 'Select an option'} />
            )}
            popupIcon={<CustomPopupIcon />}
        />
    );
};

export const SelectStateDropdown = ({ handleStateResponse, state }) => {
    const [selectedValue, setSelectedValue] = useState(
        state === 'IN' ? 'Selected' : state === 'NIN' ? 'Not selected' : 'Select an option'
    );

    const handleChange = event => {
        setSelectedValue(event.target.value);
        handleStateResponse(event.target.value);
    };
    return (
        <FormControl sx={{ width: '100%' }}>
            <Select
                size="small"
                value={selectedValue}
                onChange={handleChange}
                renderValue={() => (
                    <FormLabel style={{ color: `${state === '' ? '#00000061' : ''}` }}>{selectedValue}</FormLabel>
                )}
                IconComponent={props => <img src={ArrowDown} {...props} alt="dropdown" />}
            >
                <MenuItem value="Selected" style={{ padding: '0 0 0 1vw' }}>
                    <FormControlLabel
                        value="Selected"
                        control={<Radio checked={selectedValue === 'Selected'} />}
                        label="Selected"
                    />
                </MenuItem>
                <MenuItem value="Not selected" style={{ padding: '0 0 0 1vw' }}>
                    <FormControlLabel
                        value="Not selected"
                        control={<Radio checked={selectedValue === 'Not selected'} />}
                        label="Not selected"
                    />
                </MenuItem>
            </Select>
        </FormControl>
    );
};
