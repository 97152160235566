import React, { Fragment, memo } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../../common/colors';
import { FlexColumnGap, FlexBetween, FlexStartGap, Flex, CTA, FlexRow } from '../../../../../../common/styled/styled';
import line from '../../../../../../assets/img/Line 50.svg';
import approved from '../../../../../../assets/img/approved.svg';
import rejected from '../../../../../../assets/img/rejected.svg';
import pending from '../../../../../../assets/img/pending.svg';
import { CommonLabel } from '../../../../../../common/FormInputs/Common';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { SubmitForApproval } from '../../../../../../store/actions/Surveys/FinalizeActions';
import { SaveSurveyStatus } from '../../../../../../store/actions/Surveys/action';
import { SURVEY_STATUS } from '../../../../../../global-constants/text';
import { QUES_STATUS_TYPE } from '../../../../../../global-constants';
import { StatusBadge } from '../../Badge';

const TotalStatusCount = ({ state, si, AuthStore }) => {
    const { NOT_SUBMITTED, REJECTED, APPROVED } = QUES_STATUS_TYPE;
    const { REVIEWED, UNDER } = SURVEY_STATUS;
    const dispatch = useDispatch();
    const isApprovalActive =
        AuthStore.isClientOrCst && state.filter(({ question_status }) => question_status === NOT_SUBMITTED).length;
    const showApprovalInfo = AuthStore.isClientOrCst && si?.status === UNDER;
    const ifAllApproved =
        state.length &&
        AuthStore.isAdminOrCoach &&
        state.every(({ question_status }) => question_status === REJECTED || question_status === APPROVED) &&
        si?.status === UNDER;

    const handleAdminSubmit = () => {
        dispatch(SaveSurveyStatus({ surveyId: si?.uuid, status: REVIEWED, isFinalizeScreen: true }));
    };

    const getQuestionStatusCount = qStatus => state.filter(({ question_status }) => question_status === qStatus).length;
    const submitApproval = () => {
        dispatch(SubmitForApproval({ surveyId: si?.uuid, status: UNDER }));
    };
    return (
        <FlexColumnGap gap={pxToVh(28)} className="column">
            <FlexBetween className="stretch">
                <FlexColumnGap>
                    <CommonLabel
                        textSize={18}
                        textWeight={500}
                        textColor={colorsCode.TextHeading}
                        lineHeight={28}
                        label="Custom questions"
                    />
                    <CommonLabel
                        textSize={14}
                        textWeight={400}
                        textColor={colorsCode.TextHeading}
                        lineHeight={24}
                        label="Review and track your custom added questions"
                    />
                </FlexColumnGap>
                <FlexColumnGap>
                    {isApprovalActive ? (
                        <CTA
                            pt={6}
                            pl={16}
                            bg={colorsCode.white}
                            color={colorsCode.PrimaryDeepBlue}
                            size={14}
                            fw={400}
                            lh={20}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={submitApproval}
                        >
                            Submit for approval
                        </CTA>
                    ) : null}
                    {showApprovalInfo ? (
                        <Fragment>
                            <CommonLabel
                                textSize={14}
                                textWeight={400}
                                textColor={colorsCode.TextHeading}
                                lineHeight={24}
                                label="Your questions are pending approval"
                            />
                            <CommonLabel
                                textSize={14}
                                textWeight={500}
                                textColor={colorsCode.PrimaryDeepBlue}
                                lineHeight={20}
                                label="Expected approval time: 48 hours"
                            />
                        </Fragment>
                    ) : null}
                    {/*  */}
                    {AuthStore.isAdminOrCoach && si?.status === REVIEWED ? (
                        <CommonLabel
                            textSize={14}
                            textWeight={500}
                            textColor={colorsCode.PrimaryDeepBlue}
                            lineHeight={20}
                            label="Survey status: REVIEWED"
                        />
                    ) : null}
                    {ifAllApproved && si?.status !== REVIEWED ? (
                        <CTA
                            pt={6}
                            pl={16}
                            bg={colorsCode.white}
                            color={colorsCode.PrimaryDeepBlue}
                            size={14}
                            fw={400}
                            lh={20}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={handleAdminSubmit}
                        >
                            Submit
                        </CTA>
                    ) : null}
                </FlexColumnGap>
            </FlexBetween>
            <CustomDiv>
                <FlexStartGap gap={pxToVw(24)}>
                    <FlexRow gap={pxToVh(4)}>
                        <CommonLabel
                            textSize={18}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                            lineHeight={28}
                            label={state?.length || 0}
                        />
                        <StatusBadge label="Total questions" color={colorsCode.cyan} bg={colorsCode.lightCyan} />
                    </FlexRow>
                    <img src={line} />
                    <FlexRow gap={pxToVh(4)}>
                        <CommonLabel
                            textSize={18}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                            lineHeight={28}
                            label={getQuestionStatusCount(QUES_STATUS_TYPE.APPROVED)}
                        />
                        <StatusBadge
                            label="Approved"
                            color={colorsCode.approved}
                            bg={colorsCode.approvedbg}
                            src={approved}
                        />
                    </FlexRow>
                    <FlexRow gap={pxToVh(4)}>
                        <CommonLabel
                            textSize={18}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                            lineHeight={28}
                            label={getQuestionStatusCount(QUES_STATUS_TYPE.REJECTED)}
                        />
                        <StatusBadge
                            label="Rejected"
                            color={colorsCode.rejected}
                            bg={colorsCode.rejectedbg}
                            src={rejected}
                        />
                    </FlexRow>
                    <FlexRow gap={pxToVh(4)}>
                        <CommonLabel
                            textSize={18}
                            textWeight={500}
                            textColor={colorsCode.TextHeading}
                            lineHeight={28}
                            label={getQuestionStatusCount(QUES_STATUS_TYPE.PENDING)}
                        />
                        <StatusBadge
                            label="Pending approval"
                            color={colorsCode.pending}
                            bg={colorsCode.pendingbg}
                            src={pending}
                        />
                    </FlexRow>
                </FlexStartGap>
            </CustomDiv>
        </FlexColumnGap>
    );
};

const CustomDiv = styled(Flex)`
    align-items: center;
    gap: ${pxToVw(20)}vw;
    align-self: stretch;
`;

export default memo(TotalStatusCount);
