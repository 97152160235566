import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Box, Typography } from '@mui/material';
import themeStyles from '../../themes/styles';
import HomeImg from '../../assets/img/home.svg';
import HomeInact from '../../assets/img/homeinact.svg';
import MonitorImg from '../../assets/img/monitor.svg';
import MonitorActiveImg from '../../assets/img/monitorActive.svg';
import AnalyticsImg from '../../assets/img/analytics.svg';
import knowledgeCenterAct from '../../assets/img/knowledge-center-active.svg';
import knowledgeCenterInAct from '../../assets/img/knowledge-center-inactive.svg';
import LogoutImg from '../../assets/img/Logout.svg';
import userActImg from '../../assets/img/useract.svg';
import userOutlinedImg from '../../assets/img/useractoutline.svg';
import Logo from '../../assets/img/Deep Blue.svg';
import ListingImg from '../../assets/img/listing.svg';
import SurveyImg from '../../assets/img/Bullet-list-69.svg';
import analyticsActive from '../../assets/img/Chart-pie-36.svg';
import { FlexCenter, FlexRow } from '../../common/styled/styled';
import { useNavigate } from 'react-router';
import AuthStore from '../../common/AuthStore';
import { Logout } from '../../common/api/login';
import { PATHS } from '../../global-constants';
import { useDispatch, useSelector } from 'react-redux';
import { colorsCode, pxToVw } from '../../common/colors';
import { CommonLabel } from '../../common/FormInputs/Common';
import { UserTemplates } from '../../store/actions/Surveys/action';

const Sidebar = () => {
    const styles = themeStyles();
    const history = useNavigate();
    const dispatch = useDispatch();
    const { data } = useSelector(state => state.permissionReducer);
    const { data: userTempData = [] } = useSelector(state => state.userTemplates);

    const [profileTab, setProfileTab] = useState(false);
    const pathname = window.location.pathname;
    const {
        can_view_survey_tab = '',
        can_view_user_management_tab = '',
        can_view_analytics_tab = '',
        can_view_help_tab = '',
        can_view_monitor_tab = '',
    } = data;
    const showKC = userTempData?.some(
        ({ schema_name }) => schema_name === 'template_ohi_40' || schema_name === 'template_ohi_32'
    );
    const { white, PrimaryDeepBlue } = colorsCode;
    const { DASHBOARD, SURVEYS, KNOWLEDGE_CENTER, CLIENT, ANALYTICS, MONITOR } = PATHS;

    useEffect(() => {
        if (!userTempData.length) {
            dispatch(UserTemplates());
        }
    }, []);

    const handleClick = path => {
        history(path);
    };

    const getPathNameClass = path => {
        return pathname.includes(path) ? `${styles.inactiveClass} ${styles.activeClass}` : `${styles.inactiveClass}`;
    };

    const textClassName = path => {
        return pathname.includes(path)
            ? `${styles.MenuTypoClass} ${styles.textClassActive}`
            : `${styles.MenuTypoClass}`;
    };

    const handleTab = () => {
        setProfileTab(old => !old);
    };

    const SidebarLabel = (label, path) => {
        return (
            <CommonLabel
                label={label}
                textColor={pathname.includes(path) ? PrimaryDeepBlue : white}
                textWeight={pathname.includes(path) ? 400 : 300}
                textSize={16}
                lineHeight={24}
                customStyles={{ padding: '0 1vw' }}
                cursor="pointer"
            />
        );
    };

    const SidebarImg = (imgAct, imgInact, path) => {
        return <img src={!pathname.includes(path) ? imgInact : imgAct} alt="" className={`${styles.imgClass}`} />;
    };

    return (
        <SideBarDiv>
            <Box className={`${styles.logoDiv}`}>
                <img src={Logo} alt="logo" className={`${styles.logoImg}`} onClick={() => history(PATHS.DASHBOARD)} />
            </Box>
            <Box display="flex" justifyContent="center" flexDirection="column" className={`${styles.flexRowClass}`}>
                <FlexRowWrap className={getPathNameClass(DASHBOARD)} onClick={() => handleClick(DASHBOARD)}>
                    {!pathname.includes(DASHBOARD) ? (
                        <img src={HomeInact} alt="" className={`${styles.imgClass} ${styles.imgIndex}`} />
                    ) : (
                        <img src={HomeImg} alt="" className={`${styles.imgClass}`} />
                    )}
                    <Typography component="span" className={textClassName(DASHBOARD)}>
                        Home
                    </Typography>
                </FlexRowWrap>

                {AuthStore.isAdmin && can_view_user_management_tab === 'can_view_user_management_tab' && (
                    <FlexRowWrap className={getPathNameClass(CLIENT)} onClick={() => handleClick(CLIENT)}>
                        {SidebarImg(userActImg, userOutlinedImg, CLIENT)}
                        {SidebarLabel('Users & Clients', CLIENT)}
                    </FlexRowWrap>
                )}

                {/* permisison code for survey tab */}
                {can_view_survey_tab === 'can_view_survey_tab' ? (
                    <FlexRowWrap className={getPathNameClass(SURVEYS)} onClick={() => handleClick(SURVEYS)}>
                        {SidebarImg(SurveyImg, ListingImg, SURVEYS)}
                        {SidebarLabel('Surveys', SURVEYS)}
                    </FlexRowWrap>
                ) : null}

                {can_view_monitor_tab === 'can_view_monitor_tab' ? (
                    <FlexRowWrap className={getPathNameClass(MONITOR)} onClick={() => handleClick(MONITOR)}>
                        {SidebarImg(MonitorActiveImg, MonitorImg, MONITOR)}
                        {SidebarLabel('Monitor', MONITOR)}
                    </FlexRowWrap>
                ) : null}

                {/* permission code for analytics */}
                {can_view_analytics_tab === 'can_view_analytics_tab' ? (
                    <FlexRowWrap className={getPathNameClass('/analytics')} onClick={() => handleClick(ANALYTICS)}>
                        {SidebarImg(analyticsActive, AnalyticsImg, ANALYTICS)}
                        {SidebarLabel('Analytics', ANALYTICS)}
                    </FlexRowWrap>
                ) : null}
                {can_view_help_tab === 'can_view_help_tab' && showKC ? (
                    <FlexRowWrap
                        className={getPathNameClass(KNOWLEDGE_CENTER)}
                        onClick={() => handleClick(KNOWLEDGE_CENTER)}
                    >
                        {SidebarImg(knowledgeCenterAct, knowledgeCenterInAct, KNOWLEDGE_CENTER)}
                        {SidebarLabel('Knowledge Centre', KNOWLEDGE_CENTER)}
                    </FlexRowWrap>
                ) : null}
            </Box>
            <Box className={`${styles.profileParent}`}>
                {profileTab && (
                    <Box className={`${styles.logoutParent}`}>
                        <FlexCenter
                            style={{ gap: `${pxToVw(5)}vw`, alignItems: 'center', paddingRight: '2vw' }}
                            onClick={() => {
                                Logout();
                            }}
                        >
                            <img src={LogoutImg} alt="" className={`${styles.logoutImg}`} />
                            <Typography className={`${styles.logout}`} component="span">
                                Log out
                            </Typography>
                        </FlexCenter>
                    </Box>
                )}
                <Box component="div" className={`${styles.profileClass}`} onClick={handleTab}>
                    <Typography className={`${styles.initials}`}>{AuthStore.initials}</Typography>
                </Box>
            </Box>
        </SideBarDiv>
    );
};

const SideBarDiv = styled.div`
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    position: fixed;
    width: ${pxToVw(190)}vw;
    background: #051c2c;
    z-index: 100;
`;
const FlexRowWrap = styled(FlexRow)`
    gap: 0.1vw !important;
`;

export default Sidebar;
