import React, { memo, useEffect, useMemo } from 'react';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Typography,
} from '@mui/material';
import { BorderLightButton, FlexBetween, FlexRow, StyledFilterMenu } from '../../../common/styled/styled';
import { ReactComponent as ArrowDown } from '../../../assets/img/arrow_down.svg';
import CheckboxSvg from '../../../assets/img/Checkbox.svg';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { commonThemes } from '../../../common/themes';
import { LABELS } from '../../../global-constants';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import CountLabel from '../../../common/FormInputs/CountLabel';
import { cloneDeep } from 'lodash';
import { useDispatch } from 'react-redux';
import { UsersListing } from '../../../store/actions/Users/actions';
import PropTypes from 'prop-types';
import { checkFilterApplied } from '../../../utils/functions';
import { CommonLabel } from '../../../common/FormInputs/Common';
import SearchBar from '../../../common/FormInputs/SearchBar';

const UserFilter = ({ open, openCloseFn, setUserFilters, userFilters, state, setFilters, anchorEl = null }) => {
    const styles = commonThemes();
    const dispatch = useDispatch();
    const [expanded, setExpanded] = React.useState(false);
    const [filterSearch, setFilterSearch] = React.useState('');
    const [localRoleList, setLocalRoleList] = React.useState([]);
    const [localStatusType, setLocalStatusType] = React.useState([]);
    const handleAccordion = panel => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };
    useEffect(() => {
        setFilterSearch('');
        setLocalRoleList(state.roles);
        setLocalStatusType(state.status);
    }, [expanded]);
    const handleChange = (e, val, type) => {
        const {
            target: { checked },
        } = e;
        const copy = cloneDeep(state);
        copy[type] = copy[type]?.map(item => {
            if (item.name === val) item.is_selected = checked;
            return item;
        });
        setFilters(copy);
        if (type === 'roles')
            setLocalRoleList(copy[type].filter(({ name }) => name.toLowerCase().includes(filterSearch)));
        if (type === 'status')
            setLocalStatusType(copy[type].filter(({ name }) => name.toLowerCase().includes(filterSearch)));
    };

    const cancelClearFilter = () => {
        const copy = cloneDeep(state);
        const copyUsersFilters = cloneDeep(userFilters);
        copy?.roles?.map(item => {
            item.is_selected = false;
        });
        copy?.status?.map(item => {
            item.is_selected = false;
        });
        copyUsersFilters.filters.filters = {};
        copyUsersFilters.filters.sort = 0;
        openCloseFn(false);
        dispatch(UsersListing(copyUsersFilters));
        setUserFilters(copyUsersFilters);
        setFilters(copy);
    };

    const close = () => {
        openCloseFn(false);
        // cancelClearFilter();
        // dispatch(UsersListing(userFilters));
    };

    const applyFilter = () => {
        const copy = cloneDeep(userFilters);
        const rolesLength = state.roles.filter(({ is_selected }) => is_selected === true);
        const statusLen = state.status.filter(({ is_selected }) => is_selected === true);
        copy.filters.filters = {}; // reset filters
        if (rolesLength.length) {
            Object.assign(copy.filters.filters, {
                user_role: rolesLength.map(({ uuid }) => uuid),
            });
        }
        if (statusLen.length) {
            Object.assign(copy.filters.filters, {
                user_status: statusLen.map(({ value }) => value),
            });
        }
        copy.page = 1;
        openCloseFn(false);
        setUserFilters(copy);
        dispatch(UsersListing(copy));
    };

    const isFilterApplied = useMemo(() => checkFilterApplied(state, 'is_selected'), [state]);

    return (
        <StyledFilterMenu
            open={open || false}
            className={styles.ContentWrapper}
            anchorEl={anchorEl}
            getcontentanchorel={null}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
            transformOrigin={{ vertical: 'top', horizontal: 'center' }}
            height={`${pxToVh(350)}vh`}
            style={{
                marginLeft: `9vw`,
                marginTop: `-1vh`,
            }}
        >
            <FlexBetween>
                <DialogTitle className={styles.filterModalTitle}>{LABELS.FILTER_MODAL.MAIN_TITLE}</DialogTitle>
                <DialogTitle
                    onClick={cancelClearFilter}
                    className={`${styles.filterModalClearTitle} ${!isFilterApplied ? styles.clearTitleDisabled : ''}`}
                >
                    {LABELS.FILTER_MODAL.CLEAR_TITLE}
                </DialogTitle>
            </FlexBetween>
            <DialogContent className={styles.filterOptionsContainer}>
                <DialogContentText className={styles.accordianTitle}>
                    <Accordion
                        expanded={expanded === 'role'}
                        onChange={handleAccordion('role')}
                        disableGutters={true}
                        square={true}
                        elevation={0}
                        sx={{
                            borderBottom: `1px solid ${colorsCode.Neutral10}`,
                        }}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} id="role">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.ROLE_TYPE}
                                />
                                <CountLabel
                                    count={state.roles.filter(({ is_selected }) => is_selected === true).length}
                                />
                            </FlexBetweenWrap>
                        </AccordionSummary>
                        <AccordionDetails id="role">
                            <SearchBar
                                placeholder="Search"
                                id="search"
                                name="search"
                                val={filterSearch}
                                onChangeFn={e => {
                                    setFilterSearch(e.target.value);
                                    setLocalRoleList(
                                        state.roles?.filter(item =>
                                            item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                                        )
                                    );
                                }}
                                customClass={styles.searchClassInFilter}
                            />
                            {!localRoleList?.length && <FlexRow justifyContent="center">No data found</FlexRow>}
                            <DisplayGrid>
                                {state.roles.length ? (
                                    localRoleList.map(({ name = '', is_selected = false }, i) => (
                                        <FormControlLabel
                                            label={name}
                                            key={`key-${i}`}
                                            sx={{
                                                padding: `${pxToVh(5)}vh`,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={is_selected}
                                                    onChange={e => handleChange(e, name, 'roles')}
                                                    checkedIcon={
                                                        <CheckBoxIcon sx={{ color: colorsCode.PrimaryDeepBlue }} />
                                                    }
                                                    sx={{
                                                        height: 0,
                                                        width: 0,
                                                        margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                                    }}
                                                    icon={
                                                        <img
                                                            src={CheckboxSvg}
                                                            className="img"
                                                            style={{
                                                                width: `${pxToVw(18)}vw`,
                                                                height: `${pxToVh(18)}vh`,
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    ))
                                ) : (
                                    <Typography variant="caption" component="span">
                                        No roles found
                                    </Typography>
                                )}
                            </DisplayGrid>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion
                        expanded={expanded === 'status'}
                        id="status"
                        onChange={handleAccordion('status')}
                        disableGutters={true}
                        square={true}
                        elevation={0}
                    >
                        <AccordionSummary expandIcon={<ArrowDown />} id="active-status">
                            <FlexBetweenWrap>
                                <CommonLabel
                                    textWeight={500}
                                    textSize={14}
                                    textColor={colorsCode.Neutral80}
                                    lineHeight={20}
                                    label={LABELS.FILTER_MODAL.ACTIVE}
                                />
                                <CountLabel
                                    count={state.status.filter(({ is_selected }) => is_selected === true).length}
                                />
                            </FlexBetweenWrap>
                        </AccordionSummary>
                        <AccordionDetails>
                            <SearchBar
                                placeholder="Search"
                                id="search"
                                name="search"
                                val={filterSearch}
                                onChangeFn={e => {
                                    setFilterSearch(e.target.value);
                                    setLocalStatusType(
                                        state.status?.filter(item =>
                                            item?.name?.toLowerCase().includes(e.target.value.toLowerCase())
                                        )
                                    );
                                }}
                                customClass={styles.searchClassInFilter}
                            />
                            {!localStatusType?.length && <FlexRow justifyContent="center">No data found</FlexRow>}
                            <DisplayGrid>
                                {state.status.length ? (
                                    localStatusType.map(({ name = '', is_selected = false }, i) => (
                                        <FormControlLabel
                                            label={name}
                                            key={`key-${i}`}
                                            sx={{
                                                padding: `${pxToVh(5)}vh`,
                                            }}
                                            control={
                                                <Checkbox
                                                    checked={is_selected}
                                                    onChange={e => handleChange(e, name, 'status')}
                                                    checkedIcon={
                                                        <CheckBoxIcon sx={{ color: colorsCode.PrimaryDeepBlue }} />
                                                    }
                                                    sx={{
                                                        height: 0,
                                                        width: 0,
                                                        margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                                    }}
                                                    icon={
                                                        <img
                                                            src={CheckboxSvg}
                                                            className="img"
                                                            style={{
                                                                width: `${pxToVw(18)}vw`,
                                                                height: `${pxToVh(18)}vh`,
                                                            }}
                                                        />
                                                    }
                                                />
                                            }
                                        />
                                    ))
                                ) : (
                                    <Typography variant="caption" component="span">
                                        No status found
                                    </Typography>
                                )}
                            </DisplayGrid>
                        </AccordionDetails>
                    </Accordion>
                </DialogContentText>
            </DialogContent>
            <DialogActions
                sx={{
                    justifyContent: 'flex-end',
                    gap: `${pxToVw(20)}vw`,
                    p: `${pxToVh(20)}vh ${pxToVw(17)}vw ${pxToVh(20)}vh 0vw`,
                    borderTop: `1px solid #ccc`,
                }}
            >
                <BorderLightButton onClick={close} color={colorsCode.PrimaryDeepBlue} light width={'6vw'}>
                    {LABELS.FILTER_MODAL.CANCEL_BTN}
                </BorderLightButton>
                <BorderLightButton isDisabled={!isFilterApplied} onClick={applyFilter} width={'6vw'}>
                    {LABELS.FILTER_MODAL.APPLY_BTN}
                </BorderLightButton>
            </DialogActions>
        </StyledFilterMenu>
    );
};

export const FlexBetweenWrap = styled(FlexBetween)`
    width: 100%;
    margin-right: 0.7vh;
`;
const DisplayGrid = styled.div`
    display: grid;
    grid-template-columns: 1fr;
`;

UserFilter.propTypes = {
    open: PropTypes.bool.isRequired,
    openCloseFn: PropTypes.func.isRequired,
    setUserFilters: PropTypes.func.isRequired,
    userFilters: PropTypes.object.isRequired,
    state: PropTypes.shape({
        roles: PropTypes.array.isRequired,
        status: PropTypes.array.isRequired,
    }),
    setFilters: PropTypes.func.isRequired,
};

export default memo(UserFilter);
