import { createSlice } from '@reduxjs/toolkit';
import { UserTemplates } from '../../actions/Surveys/action';

export const INITIAL_STATE = {
    loading: false,
    data: [],
    error: false,
    errorMessage: '',
};

const UserTemplatesSlice = createSlice({
    name: 'UserTemplates',
    initialState: INITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(UserTemplates.pending, state => {
                state.loading = true;
            })
            .addCase(UserTemplates.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.data = payload.data;
            })
            .addCase(UserTemplates.rejected, state => {
                state.loading = false;
                state.error = true;
            });
    },
});
export default UserTemplatesSlice.reducer;
