import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Checkbox,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControlLabel,
    Icon,
    TextField,
    Typography,
} from '@mui/material';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';
import CloseIcon from '../../../assets/img/close.svg';
import { CTA, FlexColumnGap, FlexRow, FlexRowWidth, FlexStartGap } from '../../../common/styled/styled';
import { CommonLabel, SwitchOnOff } from '../../../common/FormInputs/Common';
import { renderColoredChips } from '../../../utils/functions';
import noteIcon from '../../../assets/img/note.svg';
import redNoteIcon from '../../../assets/img/redNote.svg';
import { MESSAGE, SURVEY_STATUS, timeFormat, updateStatusOptions } from '../../../global-constants';
import DropdownMenu from '../../../common/FormInputs/DropdownMenu';
import DatePickerComponent from '../../../common/DatePickerComponent';
import TimeSelector from '../../../common/themes/TimeSelector';
import styled from 'styled-components';
import { checkSurveyStatusForFinalizing, getTimezones } from '../../../store/actions/Surveys/action';
import { unwrapResult } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import AuthStore from '../../../common/AuthStore';
import { CustomPopupIcon } from '../../Surveys/partials/setup-survey/DisplayLogic/DisplayLogicComponents/SelectQuestionDropdown';
import moment from 'moment';

const style = {
    fontSize: '2.26vh',
    lineHeight: '3.4vh',
};

export const UpdateStatus = ({ open, surveydata, handleSave, handleClose, dispatchAction }) => {
    const { updateSurveyLoading, checkSurveyFinalizingLoading, timezonesLoading, timezoneList } = useSelector(
        state => state.surveyReducer
    );

    const {
        status: fromStatus,
        survey_launch_date = null,
        survey_launch_time = null,
        timezone_uuid = null,
        maintenance_mode = false,
        uuid: surveyId,
    } = surveydata;

    const dateInUse = survey_launch_date ? new Date(survey_launch_date) : new Date();
    const timeInUse = survey_launch_date ? moment(survey_launch_time, timeFormat).toDate() : null;
    const currentDate = moment().startOf('day');

    const { REOPEN_WARNING } = MESSAGE;
    const { LIVE, DRAFT, COMPLETED, LIVE_SCHEDULE } = SURVEY_STATUS;
    const [surveyFinalizingReasons, setSurveyFinalizingReasons] = useState([]);
    const [anythingUpdated, setAnythingUpdated] = useState(false);
    const isAdmin = AuthStore.isAdmin;

    let statusInUse = fromStatus;
    if (fromStatus === LIVE) {
        if (survey_launch_time) {
            statusInUse = 'SCHEDULED';
        } else if (maintenance_mode) {
            statusInUse = 'MAINTENANCE';
        }
    }

    const updateStatusOptionsInUse = isAdmin
        ? updateStatusOptions[statusInUse]
        : updateStatusOptions[statusInUse].filter(({ onlyAdminAllowed }) => !onlyAdminAllowed);

    const showLoader = updateSurveyLoading || checkSurveyFinalizingLoading || timezonesLoading;
    const enableMaintenanceMode = statusInUse === LIVE || statusInUse === 'MAINTENANCE';

    const initialConfig = {
        maintenance_mode_enabled: maintenance_mode,
        status_type: fromStatus === LIVE && survey_launch_time ? LIVE_SCHEDULE : '',
        date: dateInUse,
        time: timeInUse,
        timezone: timezone_uuid,
        checkbox_selected: false,
    };

    const [surveyConfig, setSurveyConfig] = useState(initialConfig);

    const { maintenance_mode_enabled, status_type, date, time, timezone, checkbox_selected } = surveyConfig;

    useEffect(() => {
        if (fromStatus === DRAFT) {
            const fetchSurveyStatus = async () => {
                const response = await dispatchAction(checkSurveyStatusForFinalizing({ surveyId }));
                const resp = await unwrapResult(response);
                const { data } = resp;
                setSurveyFinalizingReasons(data || []);
            };

            fetchSurveyStatus();
        }
        if (!timezoneList?.length) {
            dispatchAction(getTimezones());
        }
    }, []);

    const handleChange = e => {
        const {
            target: { name, value },
        } = e;

        setAnythingUpdated(true);

        if (name === 'status_type') {
            setSurveyConfig({
                ...surveyConfig,
                date: dateInUse,
                time: timeInUse,
                timezone: timezone_uuid,
                checkbox_selected: false,
                [name]: value,
            });
        } else {
            setSurveyConfig({
                ...surveyConfig,
                [name]: value,
            });
        }
    };

    const handleCheckbox = e => {
        const {
            target: { name, checked },
        } = e;
        setAnythingUpdated(true);

        if ((statusInUse === LIVE || statusInUse === 'MAINTENANCE') && name === 'maintenance_mode_enabled') {
            setSurveyConfig({ ...surveyConfig, status_type: '', [name]: checked });
        } else {
            setSurveyConfig({ ...surveyConfig, [name]: checked });
        }
    };

    const handleDateChange = e => {
        setAnythingUpdated(true);
        setSurveyConfig({ ...surveyConfig, date: e });
    };

    const enableDisable = () => {
        let disableUpdateCTA = false;

        const statusTypeNotExists = !status_type;

        if (fromStatus === DRAFT) {
            if (statusTypeNotExists) {
                disableUpdateCTA = true;
            } else if (
                status_type === LIVE_SCHEDULE &&
                (!date || !time || !timezone || moment(date).isBefore(currentDate))
            ) {
                disableUpdateCTA = true;
            }
        } else if (fromStatus === LIVE) {
            if (statusInUse === 'MAINTENANCE' && !maintenance_mode_enabled) {
                if (status_type === DRAFT) {
                    if (!checkbox_selected) {
                        disableUpdateCTA = true;
                    }
                }
            } else if (statusInUse !== 'MAINTENANCE' && !maintenance_mode_enabled) {
                if (statusTypeNotExists) {
                    disableUpdateCTA = true;
                } else if (status_type === DRAFT) {
                    if (!checkbox_selected) {
                        disableUpdateCTA = true;
                    }
                } else if (
                    status_type === LIVE_SCHEDULE &&
                    (!date || !time || !timezone || moment(date).isBefore(currentDate))
                ) {
                    disableUpdateCTA = true;
                }
            }
        } else if (fromStatus === COMPLETED) {
            if (statusTypeNotExists) {
                disableUpdateCTA = true;
            } else if (status_type === LIVE) {
                if (!checkbox_selected) {
                    disableUpdateCTA = true;
                }
            }
        }

        return disableUpdateCTA;
    };

    const errorJsx = (
        <NoteContainer
            bgColor={colorsCode.danger}
            borderColor={colorsCode.rejected}
            gap={pxToVh(8)}
            style={{ justifyContent: 'center', alignItems: 'start' }}
        >
            <FlexRow>
                <img src={redNoteIcon} alt="noteIcon" />
                <NotesSection>
                    <b style={{ fontWeight: 500 }}>You have pending actions:</b>
                </NotesSection>
            </FlexRow>
            <StyledListContainer>
                {surveyFinalizingReasons.map(reason => (
                    <StyledList key={reason}>{reason}</StyledList>
                ))}
            </StyledListContainer>
        </NoteContainer>
    );

    return (
        <React.Fragment>
            <Dialog
                open={open}
                PaperProps={{
                    sx: {
                        maxWidth: `${pxToVw(580)}vw`,
                        width: `${pxToVw(575)}vw`,
                        height: `70%`,
                    },
                }}
            >
                <DialogTitle sx={{ mt: `${pxToVh(20)}vh`, ml: `${pxToVw(20)}vw` }}>
                    <CommonLabel
                        textSize={24}
                        textColor={colorsCode.TextHeading}
                        textWeight={500}
                        lineHeight={36}
                        label={'Change status'}
                    />
                </DialogTitle>
                <DialogContent
                    sx={{
                        mt: `${pxToVh(20)}vh`,
                        p: `${pxToVh(40)}vh ${pxToVw(40)}vw`,
                        height: `100%`,
                    }}
                >
                    <Icon
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: `${pxToVw(16)}vw`,
                            top: `${pxToVh(16)}vh`,
                            cursor: 'pointer',
                            width: 'unset',
                            height: 'unset',
                        }}
                    >
                        <img
                            src={CloseIcon}
                            alt=""
                            style={{
                                height: `${pxToVh(22)}vh`,
                                width: ` ${pxToVw(22)}vw`,
                            }}
                        />
                    </Icon>
                    {showLoader ? (
                        <CircularProgress
                            size="7.5vh"
                            thickness={2}
                            style={{ position: 'absolute', top: '50%', left: '45%' }}
                        />
                    ) : (
                        <DialogContentText>
                            <FlexColumnGap gap="4">
                                <FlexRow gap={pxToVw(80)}>
                                    <FlexStartGap gap={pxToVw(12)} style={{ alignItems: 'center' }}>
                                        <FlexStartGap gap={pxToVw(8)} style={{ alignItems: 'center' }}>
                                            <CommonLabel
                                                label="Maintenance mode"
                                                textColor={
                                                    enableMaintenanceMode
                                                        ? colorsCode.TextHeading
                                                        : colorsCode.Neutral30
                                                }
                                                textSize={16}
                                                textWeight={400}
                                                lineHeight={24}
                                                tooltip={false}
                                            />
                                        </FlexStartGap>
                                        <SwitchOnOff
                                            name="maintenance_mode_enabled"
                                            id="maintenance_mode_enabled"
                                            onChangeHandler={handleCheckbox}
                                            checkedFlag={maintenance_mode_enabled}
                                            disabled={!enableMaintenanceMode}
                                        />
                                    </FlexStartGap>
                                </FlexRow>
                                <FlexColumnGap gap="0.5" width={'100%'}>
                                    <Typography component="span" sx={style} fontWeight="400">
                                        Current Status
                                    </Typography>
                                    <div className="surveyStatusClass">
                                        {renderColoredChips(
                                            fromStatus,
                                            maintenance_mode,
                                            survey_launch_date,
                                            survey_launch_time
                                        )}
                                    </div>
                                </FlexColumnGap>

                                {surveyFinalizingReasons?.length ? (
                                    errorJsx
                                ) : (
                                    <>
                                        <FlexColumnGap gap="0.5" width={'100%'}>
                                            <Typography component="span" sx={style} fontWeight="400">
                                                Change Status
                                            </Typography>
                                            <DropdownMenu
                                                disabled={maintenance_mode_enabled}
                                                fullWidth={true}
                                                menuItems={updateStatusOptionsInUse}
                                                onChangeFn={handleChange}
                                                value={status_type}
                                                width={'100%'}
                                                name="status_type"
                                                placeholder="Select an option"
                                                wrapperStyles={{ width: '100%' }}
                                            />
                                        </FlexColumnGap>
                                        {status_type === LIVE_SCHEDULE ? (
                                            <>
                                                <FlexRowWidth gap="0.5" alignItems="flex-start">
                                                    <FlexColumnGap width={'50%'}>
                                                        <DatePickerComponent
                                                            setDate={handleDateChange}
                                                            date={date}
                                                            fullWidth
                                                            blockPreviousDates
                                                        />
                                                    </FlexColumnGap>
                                                    <FlexColumnGap width={'50%'}>
                                                        <TimeSelector
                                                            time={time}
                                                            headerInfo={''}
                                                            minutesStep={15}
                                                            setTimeValue={value =>
                                                                handleChange({
                                                                    target: { name: 'time', value },
                                                                })
                                                            }
                                                        />
                                                    </FlexColumnGap>
                                                </FlexRowWidth>
                                                <FlexColumnGap width={'50%'}>
                                                    <Autocomplete
                                                        options={timezoneList}
                                                        getOptionLabel={option => option.name}
                                                        value={
                                                            timezoneList.find(option => option.uuid === timezone) ||
                                                            null
                                                        }
                                                        onChange={(e, obj) => {
                                                            handleChange({
                                                                target: { name: 'timezone', value: obj.uuid },
                                                            });
                                                        }}
                                                        clearIcon={null}
                                                        sx={{
                                                            '& .MuiOutlinedInput-root': {
                                                                padding: `0 0.28vw 0 ${pxToVw(16)}vw`,
                                                            },
                                                            '&&& input': {
                                                                paddingLeft: '0',
                                                            },
                                                            '&& .MuiAutocomplete-endAdornment': {
                                                                right: `${pxToVw(16)}vw`,
                                                            },
                                                            borderColor: colorsCode.Neutral30,
                                                            width: '100%',
                                                        }}
                                                        renderTags={() => null}
                                                        renderInput={params => (
                                                            <TextField
                                                                {...params}
                                                                name="timezone"
                                                                placeholder="Select a timezone"
                                                                sx={{
                                                                    '& .MuiInputBase-root': {
                                                                        height: '5.67vh',
                                                                        borderColor: colorsCode.Neutral30,
                                                                        borderRadius: '2px',
                                                                        fontSize: `2.2vh`,
                                                                    },
                                                                }}
                                                            />
                                                        )}
                                                        popupIcon={<CustomPopupIcon />}
                                                    />
                                                </FlexColumnGap>
                                            </>
                                        ) : null}
                                        {!maintenance_mode_enabled &&
                                        (status_type === DRAFT ||
                                            (status_type === LIVE && fromStatus === COMPLETED)) ? (
                                            <>
                                                <NoteContainer
                                                    gap={pxToVh(8)}
                                                    style={{ justifyContent: 'center', alignItems: 'start' }}
                                                >
                                                    <FlexRow>
                                                        <img src={noteIcon} alt="noteIcon" />
                                                        <NotesSection>
                                                            <b style={{ fontWeight: 500 }}>Note:</b>
                                                        </NotesSection>
                                                    </FlexRow>
                                                    <StyledListContainer>
                                                        <StyledList>
                                                            Modifying your survey status is a significant action that
                                                            will impact the overall status of your survey. Please ensure
                                                            you have sufficient reason and approval to perform the
                                                            action.
                                                        </StyledList>
                                                        {fromStatus !== LIVE ? (
                                                            <StyledList>{REOPEN_WARNING}</StyledList>
                                                        ) : null}
                                                    </StyledListContainer>
                                                </NoteContainer>
                                                <FormControlLabel
                                                    label={
                                                        <CommonLabel
                                                            label={`I have read the disclaimer and want to proceed with changing the survey status to ${
                                                                fromStatus === LIVE ? 'Draft' : 'Live'
                                                            }.`}
                                                            lineHeight={18}
                                                            textSize={16}
                                                            textWeight={400}
                                                            textColor={colorsCode.Neutral80}
                                                        />
                                                    }
                                                    control={
                                                        <Checkbox
                                                            name={'checkbox_selected'}
                                                            value={checkbox_selected}
                                                            checked={checkbox_selected}
                                                            onClick={e => {
                                                                handleCheckbox(e);
                                                            }}
                                                            sx={{
                                                                height: 0,
                                                                width: 0,
                                                                margin: `${pxToVw(6)}vw ${pxToVw(12)}vw 0 ${pxToVw(
                                                                    12
                                                                )}vw`,
                                                            }}
                                                        />
                                                    }
                                                    sx={{
                                                        alignItems: 'self-start',
                                                        fontSize: `${pxToVh(16)}vh`,
                                                    }}
                                                />
                                            </>
                                        ) : null}
                                        {!maintenance_mode_enabled && status_type === COMPLETED ? (
                                            <>
                                                <NoteContainer
                                                    bgColor={colorsCode.danger}
                                                    borderColor={colorsCode.rejected}
                                                    gap={pxToVh(8)}
                                                    style={{ justifyContent: 'center', alignItems: 'start' }}
                                                >
                                                    <FlexRow>
                                                        <img src={redNoteIcon} alt="noteIcon" />
                                                        <NotesSection>
                                                            <b style={{ fontWeight: 500 }}>
                                                                You are about to close the survey!
                                                            </b>
                                                        </NotesSection>
                                                    </FlexRow>
                                                    <StyledListContainer>
                                                        <StyledList>
                                                            Please note once you close the survey, you wouldn&apos;t be
                                                            able to record the responses of any participant post
                                                            closure.
                                                        </StyledList>
                                                        <StyledList>{REOPEN_WARNING}</StyledList>
                                                        <StyledList>
                                                            Results would be generated post review by the McKinsey
                                                            Coach.
                                                        </StyledList>
                                                    </StyledListContainer>
                                                    <Typography
                                                        component="span"
                                                        sx={style}
                                                        color={colorsCode.TextHeading}
                                                    >
                                                        Are you sure you want to proceed?
                                                    </Typography>
                                                </NoteContainer>
                                            </>
                                        ) : null}
                                    </>
                                )}
                            </FlexColumnGap>
                        </DialogContentText>
                    )}
                </DialogContent>
                <DialogActions sx={{ justifyContent: 'center', gap: `${pxToVw(24)}vw`, mt: `${pxToVw(60)}vh` }}>
                    <CTA
                        pt={8}
                        pl={16}
                        bg={colorsCode.white}
                        color={colorsCode.PrimaryDeepBlue}
                        fw={400}
                        lh={24}
                        size={16}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={handleClose}
                    >
                        Cancel
                    </CTA>
                    <CTA
                        pt={8}
                        pl={16}
                        bg={colorsCode.PrimaryDeepBlue}
                        color={colorsCode.white}
                        fw={400}
                        lh={24}
                        size={16}
                        disabled={!anythingUpdated || enableDisable()}
                        onClick={() => {
                            handleSave(surveyConfig);
                        }}
                    >
                        Update
                    </CTA>
                </DialogActions>
                <br />
            </Dialog>
        </React.Fragment>
    );
};

const NoteContainer = styled(FlexColumnGap)`
    width: 100%;
    padding: ${pxToVw(16)}vw;
    background: ${({ bgColor }) => bgColor || colorsCode.pendingbg};
    border: 1px solid;
    border-color: ${({ borderColor }) => borderColor || colorsCode.warning};
    border-radius: 5px;
`;

const NotesSection = styled.div`
    font-size: ${pxToVh(16)}vh;
`;

const StyledListContainer = styled.ul`
    margin: 0;
    padding: 0 ${pxToVh(10)}vw;
`;

const StyledList = styled.li`
    color: ${colorsCode.TextHeading};
    font-size: ${pxToVh(16)}vh;
    line-height: ${pxToVh(24)}vh;
    font-weight: 400;
`;
