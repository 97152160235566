import { createSlice } from '@reduxjs/toolkit';

export const INITIAL_STATE = {
    toast: {
        show: false,
        type: 'error',
        message: '',
    },
};
const Toast = createSlice({
    name: 'Toast',
    initialState: INITIAL_STATE,
    reducers: {
        resetToast: state => {
            state.toast = INITIAL_STATE.toast;
        },
        setToast: (state, { payload }) => {
            state.toast = payload;
        },
    },
});
export const { setToast, resetToast } = Toast.actions;
export default Toast.reducer;
