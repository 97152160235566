import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Paper,
    Checkbox,
    FormControlLabel,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableContainer,
    Grid,
} from '@mui/material';
import { FlexStart, TableCellStyled, FlexColumnGap, FlexAlignCenter } from '../../../../common/styled/styled';
import { CommonLabel, Pagetab } from '../../../../common/FormInputs/Common';
import checkImg from '../../../../assets/img/checked.svg';
import uncheckImg from '../../../../assets/img/uncheck.svg';
import document from '../../../../assets/img/document.svg';
import CheckboxSvg from '../../../../assets/img/Checkbox.svg';
import '../../scss/index.scss';
import { useSelector } from 'react-redux';
import styled from 'styled-components';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import IndeterminateCheckBoxIcon from '@mui/icons-material/IndeterminateCheckBox';
import Loader from '../../../Loader';
import { LABELS } from '../../../../global-constants';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CheckboxDiv } from './ClientOverview';

const SurveyTemplate = ({
    setClientState,
    clientState,
    setSurveyTemplateState = () => {},
    surveyTemplateState = [],
}) => {
    const {
        CREATE_SURVEY: { Overview_Title },
    } = LABELS;
    const [tab, setTab] = useState();
    const { loading } = useSelector(state => state.clientReducer);

    useEffect(() => {
        if (clientState.survey_template_type.length) {
            setTab(clientState.survey_template_type[0]);
        }
    }, []);

    const handleClick = val => {
        setTab(val);
    };

    const handleSelection = (e, i) => {
        const {
            target: { value, checked },
        } = e;
        handleTemplates(checked, i);
        const copy = { ...clientState };
        if (checked) {
            if (!copy.survey_template_type.includes(value)) {
                copy.survey_template_type.push(value);
            }
            setTab(copy.survey_template_type[0]);
            setClientState(copy);
        } else {
            const filterData = copy.survey_template_type.filter(item => item !== value);
            if (filterData.length) {
                setTab(filterData[0]);
            }
            setClientState({ ...clientState, survey_template_type: filterData });
        }
    };

    const handleTemplates = (checked, i) => {
        const copySurveyTemplate = cloneDeep(surveyTemplateState);
        copySurveyTemplate[i].is_selected = checked;
        setSurveyTemplateState(copySurveyTemplate);
    };

    const handleWordingSelection = (e, uuid, staticIndex) => {
        const {
            target: { checked },
        } = e;
        const index = surveyTemplateState.findIndex(({ uuid: sid }) => sid === uuid);
        const copy = cloneDeep(surveyTemplateState);
        copy[index].static_questions[staticIndex].is_selected = checked;
        setSurveyTemplateState(copy);
    };

    const handleParent = e => {
        const {
            target: { checked },
        } = e;
        const findIndex = surveyTemplateState.findIndex(({ uuid }) => uuid === tab);
        const copy = cloneDeep(surveyTemplateState);
        copy[findIndex].static_questions.map((item, i) => {
            copy[findIndex].static_questions[i].is_selected = checked;
        });
        setSurveyTemplateState(copy);
    };

    const lengthofState = () => {
        const findIndex = surveyTemplateState?.findIndex(({ uuid }) => uuid === tab);
        return surveyTemplateState[findIndex]?.static_questions;
    };

    const showInderminate = () => {
        const allSelected = lengthofState()?.filter(({ is_selected }) => is_selected === true).length;
        if (allSelected === 0) {
            return false;
        }
        return allSelected !== lengthofState()?.length;
    };

    const showAllChecked = () =>
        lengthofState()?.filter(({ is_selected }) => is_selected === true).length === lengthofState()?.length;

    return (
        <Box className="clientOverview" data-testid="surveytemp">
            {loading ? <Loader showBackground position="absolute" background="transparent" /> : null}
            <Box className="partone">
                <FlexStart style={{ marginTop: '1vh' }}>
                    <CommonLabel
                        textWeight={500}
                        textColor={colorsCode.TextHeading}
                        textSize={18}
                        label={Overview_Title.title}
                        lineHeight={28}
                    />
                </FlexStart>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        marginTop: `${pxToVh(0)}vh`,
                    }}
                >
                    {surveyTemplateState.length
                        ? surveyTemplateState.map(
                              (
                                  {
                                      template_name = '',
                                      uuid = '',
                                      is_selected = false,
                                      description = '',
                                      static_questions = [],
                                  },
                                  i
                              ) => (
                                  <Grid xs={3} md={3} item key={uuid}>
                                      <SurveytempDiv
                                          key={`${uuid}-${template_name}`}
                                          bg={is_selected ? colorsCode.cyan100 : colorsCode.SecondaryAmber100}
                                          bcolor={is_selected ? `1px solid ${colorsCode.primaryCyan}` : `none`}
                                      >
                                          <CheckboxDiv>
                                              <FormControlLabel
                                                  control={
                                                      <Checkbox
                                                          icon={<img src={uncheckImg} alt="" className="imgTemplate" />}
                                                          checkedIcon={
                                                              <img src={checkImg} alt="" className="imgTemplate" />
                                                          }
                                                          sx={{ width: 0, height: 0 }}
                                                          checked={is_selected}
                                                          inputProps={{
                                                              'data-testid': 'selection',
                                                          }}
                                                          name="servey_templates"
                                                          id="survey_templates"
                                                          onClick={e => handleSelection(e, i)}
                                                          value={uuid}
                                                      />
                                                  }
                                              />
                                          </CheckboxDiv>
                                          <FlexColumnGap gap={pxToVh(8)}>
                                              <CommonLabel
                                                  textWeight={500}
                                                  textSize={18}
                                                  lineHeight={28}
                                                  textColor={colorsCode.Neutral80}
                                                  label={template_name}
                                              />
                                              <CommonLabel
                                                  textWeight={400}
                                                  textSize={16}
                                                  lineHeight={24}
                                                  textColor={colorsCode.Neutral80}
                                                  label={
                                                      description.length > 68
                                                          ? `${description.slice(0, 63)}...`
                                                          : description
                                                  }
                                              />
                                          </FlexColumnGap>
                                          <FlexAlignCenter gap={pxToVw(8)}>
                                              <img src={document} alt="" className="img" />
                                              <CommonLabel
                                                  textWeight={300}
                                                  textSize={14}
                                                  lineHeight={20}
                                                  textColor={colorsCode.Neutral70}
                                                  label={`${static_questions.length} template wordings`}
                                              />
                                          </FlexAlignCenter>
                                      </SurveytempDiv>
                                  </Grid>
                              )
                          )
                        : null}
                </Grid>
            </Box>

            {clientState.survey_template_type.length ? (
                <Box className="partone">
                    <FlexStart>
                        <Typography className="partonetitle">{Overview_Title.Wording_Title}</Typography>
                    </FlexStart>
                    <FlexColumnGap gap={pxToVh(27)}>
                        <FlexInline>
                            {clientState.survey_template_type.map((item, i) => {
                                const TemplateName = surveyTemplateState.length
                                    ? surveyTemplateState.find(({ uuid }) => uuid === item)?.template_name
                                    : '';
                                return (
                                    <Pagetab
                                        active={tab === item}
                                        key={i}
                                        clickHandler={() => handleClick(item)}
                                        label={TemplateName}
                                    />
                                );
                            })}
                        </FlexInline>
                        <TableContainer
                            component={Paper}
                            sx={{
                                maxHeight: '50vh',
                                height: 'auto',
                                boxShadow: colorsCode.boxShadow,
                            }}
                        >
                            <Table size="small" aria-label="a dense table" stickyHeader>
                                <TableHead>
                                    <TableRow>
                                        <TableCellStyled sx={{ width: 0 }}>
                                            <Checkbox
                                                indeterminate={showInderminate()}
                                                onClick={handleParent}
                                                disabled={true}
                                                checked={showAllChecked()}
                                                checkedIcon={<CheckBoxIcon sx={{ color: colorsCode.Neutral10 }} />}
                                                indeterminateIcon={<IndeterminateCheckBoxIcon sx={{ color: 'grey' }} />}
                                                icon={
                                                    <img
                                                        src={CheckboxSvg}
                                                        className="img"
                                                        style={{
                                                            width: `${pxToVw(18)}vw`,
                                                            height: `${pxToVh(18)}vh`,
                                                        }}
                                                    />
                                                }
                                                sx={{
                                                    height: 0,
                                                    width: 0,
                                                }}
                                            />
                                        </TableCellStyled>
                                        <TableCellStyled>Questions</TableCellStyled>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {surveyTemplateState.length
                                        ? surveyTemplateState
                                              .filter(({ uuid = '' }) => uuid === tab)
                                              ?.map(({ uuid, static_questions }, i) => {
                                                  return static_questions.map(
                                                      ({ qsn_text, is_selected }, staticIndex) => (
                                                          <TableRow key={`${uuid}-${qsn_text}`}>
                                                              <TableCellStyled>
                                                                  <Checkbox
                                                                      checked={is_selected}
                                                                      disabled={true}
                                                                      value={i}
                                                                      onClick={e =>
                                                                          handleWordingSelection(e, uuid, staticIndex)
                                                                      }
                                                                      inputProps={{
                                                                          'data-testid': 'parent',
                                                                      }}
                                                                      checkedIcon={
                                                                          <CheckBoxIcon
                                                                              sx={{ color: colorsCode.Neutral10 }}
                                                                          />
                                                                      }
                                                                      icon={
                                                                          <img
                                                                              src={CheckboxSvg}
                                                                              className="img"
                                                                              style={{
                                                                                  width: `${pxToVw(18)}vw`,
                                                                                  height: `${pxToVh(18)}vh`,
                                                                              }}
                                                                          />
                                                                      }
                                                                      sx={{
                                                                          height: 0,
                                                                          width: 0,
                                                                      }}
                                                                  />
                                                              </TableCellStyled>
                                                              <TableCellStyled>{qsn_text}</TableCellStyled>
                                                          </TableRow>
                                                      )
                                                  );
                                              })
                                        : null}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </FlexColumnGap>
                </Box>
            ) : null}
            <br />
        </Box>
    );
};

export const FlexInline = styled.div`
    display: inline-flex;
    align-items: flex-start;
`;

const SurveytempDiv = styled.div`
    display: flex;
    width: ${pxToVw(272)}vw;
    height: ${pxToVh(220)}vh;
    padding: ${pxToVh(20)}vh ${pxToVw(24)}vw;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    border-radius: 8px;
    border: ${({ bcolor }) => bcolor};
    background: ${({ bg }) => bg};
    position: relative;
    box-shadow: ${colorsCode.boxShadow};
`;

SurveyTemplate.propTypes = {
    styles: PropTypes.object.isRequired,
    setClientState: PropTypes.func.isRequired,
    clientState: PropTypes.object.isRequired,
    setSurveyTemplateState: PropTypes.func,
    surveyTemplateState: PropTypes.array,
    client_id: PropTypes.string,
};

export default SurveyTemplate;
