import * as React from 'react';
import sc from 'styled-components';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { enUS } from 'date-fns/locale';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import { colorsCode } from '../colors';
import { ReactComponent as ClockIcon } from '../../assets/img/Clock.svg';

const toRem = pixels => {
    return `${pixels}vh`;
};

export const TimeSelector = ({ headerInfo = 'Select a time', minutesStep = 1, setTimeValue, time = null }) => {
    const [value, setValue] = React.useState(time);

    const handleChange = newValue => {
        setValue(newValue);
        setTimeValue(newValue);
    };

    return (
        <TimeSelectorStyled>
            <LocalizationProvider dateAdapter={AdapterDateFns} locale={enUS}>
                {headerInfo ? <label>{headerInfo}</label> : null}
                <div className="timePickerWrap">
                    <TimePicker
                        value={value}
                        onChange={handleChange}
                        minutesStep={minutesStep}
                        slots={{
                            openPickerIcon: ClockIcon,
                        }}
                        viewRenderers={{
                            hours: renderTimeViewClock,
                            minutes: renderTimeViewClock,
                            seconds: renderTimeViewClock,
                        }}
                        renderInput={params => <TextField {...params} helperText="Input start of Day time" />}
                        InputProps={{
                            onBlur: e => {
                                const newDate = new Date(value);
                                newDate.setMinutes(0);
                                if (e.target.value && e.target.value.length === 5) {
                                    console.log(newDate);
                                }
                            },
                        }}
                    />
                    <div className="overlayWrap" />
                </div>
            </LocalizationProvider>
        </TimeSelectorStyled>
    );
};

const TimeSelectorStyled = sc.div`

    width: 100%;
    
    label {
		color: ${colorsCode.Neutral80};
		font-size: ${toRem(2.16)};
		line-height: ${toRem(3.2)};
		margin-bottom: ${toRem(0.8)};
		display: block;
	}
	
	.timePickerWrap {
	    position: relative;
	    
	    .overlayWrap {
	        position: absolute;
            width: 100%; 
            height: 100%;
            left: 0;
            top: 0;
	    }
    }
	
	.MuiFormControl-root {
		margin: 0;
		width: 100%;
		font-family: inherit;
		
		* {
			font-family: inherit;
		}
		
		.MuiInputBase-root {
			font-family: inherit;
			padding: 0 ${toRem(2.16)};
			
			fieldset {
				border-color: ${colorsCode.Neutral30};
				border-radius: 2px;
				border-width: 1px !important;
			}
			
			&.Mui-focused,
			&:hover {
				fieldset {
					border-color: ${colorsCode.PrimaryDeepBlue} !important;
				}
			}
			
			input {
				height: inherit;
				margin: 0;
				padding: 0;
				font-size: ${toRem(2.16)};
				line-height: ${toRem(5.4)};
				letter-spacing: ${toRem(0.1)};
			}
			
			.MuiInputAdornment-root  {
                z-index: 2;
                position: relative; 
                height: inherit;
                max-height: inherit;
                display: block;
                
                & > button {
                    display: inline-block;
                    font-size: 0;
                        
                    svg {
                        font-size: ${toRem(2.16)};
                        height: ${toRem(2.16)};
                        width: ${toRem(2.16)};
                    }
                }
			}
		}
	}
`;

export default TimeSelector;
