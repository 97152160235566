import { createSlice } from '@reduxjs/toolkit';
import { GetSurveyConfig, saveAllSettings, saveShowLogo, SettingsAll } from '../../actions/Settings/actions';

const INITIAL_STATE = {
    loading: false,
    settingsLoading: false,
    configLoading: false,
    settings: [],
    error: false,
    isSaved: false,
    config: {},
    errorMessage: '',
    showLogoLoading: false,
};

const SettingsSlice = createSlice({
    name: 'settings',
    initialState: INITIAL_STATE,
    reducers: {
        resetSettings: state => {
            state.error = false;
            state.isSaved = false;
            state.config = {};
            state.errorMessage = '';
            state.settings = [];
        },
    },
    extraReducers: builder => {
        builder
            .addCase(SettingsAll.pending, state => {
                state.settingsLoading = true;
            })
            .addCase(SettingsAll.fulfilled, (state, { payload }) => {
                let data = payload.data.filter(({ meta_key }) => meta_key === 'layout');
                data = data[0].translation['en'];
                data = data.map(item => {
                    return { ...item, is_selected: false };
                });
                state.settingsLoading = false;
                state.settings = data;
            })
            .addCase(SettingsAll.rejected, (state, { payload }) => {
                state.settingsLoading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(saveAllSettings.pending, state => {
                state.loading = true;
            })
            .addCase(saveAllSettings.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.isSaved = payload.shouldHideModal ? false : true;
            })
            .addCase(saveAllSettings.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(GetSurveyConfig.pending, state => {
                state.configLoading = true;
            })
            .addCase(GetSurveyConfig.fulfilled, (state, { payload }) => {
                state.configLoading = false;
                state.config = payload.data;
            })
            .addCase(GetSurveyConfig.rejected, (state, { payload }) => {
                state.configLoading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(saveShowLogo?.pending, state => {
                state.showLogoLoading = true;
            })
            .addCase(saveShowLogo?.fulfilled, state => {
                state.showLogoLoading = false;
            })
            .addCase(saveShowLogo?.rejected, state => {
                state.showLogoLoading = false;
            });
    },
});

export const { resetSettings } = SettingsSlice.actions;
export default SettingsSlice.reducer;
