import { Breadcrumbs, Link, Paper, Typography } from '@mui/material';
import React from 'react';
import styled from 'styled-components';
import { CTA, Flex, FlexBetween, FlexColumnGap, FlexRow, FlexStart } from '../../../../common/styled/styled';
import { LABELS, PATHS, STEPS, NPM_TEMPLATE } from '../../../../global-constants';
import BtnPlay from '../../../../assets/img/Btn-play.svg';
import breadcrumIcon from '../../../../assets/img/breadcrumb.svg';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import '../../scss/index.scss';
import '../../../User/scss/index.scss';
import { useNavigate } from 'react-router';
import { CommonLabel, Img } from '../../../../common/FormInputs/Common';
import { useDispatch, useSelector } from 'react-redux';
import {
    setActiveStep,
    setShowPulseQuestionSelectError,
    setShowUnsavedError,
} from '../../../../store/reducers/slice/ActiveStep';
import { resetSurveyInfo } from '../../../../store/reducers/slice/SurveySlice';
import { resetSaved } from '../../../../store/reducers/slice/ClientSlice';
import { resetSearched } from '../../../../store/reducers/slice/SearchUserClientSlice';
import { resetData } from '../../../../store/reducers/slice/Users';
import { setOrgname } from '../../../../store/reducers/slice/CreateSurveySlice';
import AuthStore from '../../../../common/AuthStore';
import LightTooltip from '../../../../common/LightTooltip';
import { renderColoredChips } from '../../../../utils/functions';

const Header = ({
    clickHandler,
    position = 'absolute',
    zIndex = 0,
    width = '100%',
    isNextEnabled = true,
    isPreviewDisabled = true,
    isPreviewRequired = false,
    isNextRequired = true,
    btnText = 'Next',
    previewHandler = () => {},
    loading = false,
    showSurveyID = true,
}) => {
    const dispatch = useDispatch();
    const history = useNavigate();
    const { activeStep, disabledStep, unsavedQuestError, pulseQuestionSelectError } = useSelector(
        state => state.activeStepReducer
    );
    const { surveyInformation: si = {} } = useSelector(state => state.surveyReducer);
    const { status, maintenance_mode, survey_launch_timeattrs } = si;
    const { launch_date, launch_time } = survey_launch_timeattrs || {};
    const { DASHBOARD, SURVEYS } = PATHS;
    const { PrimaryDeepBlue, TextSubtle, Neutral30, TextHeading, Neutral80, white, boxShadow } = colorsCode;
    const stylesObj = {
        textDecoration: 'none',
        cursor: 'pointer',
    };
    const isNpmTemplate = si?.schema_name === NPM_TEMPLATE;
    let nonAdminSteps = { ...STEPS };
    let adminSteps = { ...STEPS };
    delete nonAdminSteps['TEXT_EDIT'];
    if (isNpmTemplate) {
        delete nonAdminSteps['RESPONDENT'];
        delete adminSteps['RESPONDENT'];
    }
    const updatedSteps = AuthStore.isAdmin ? adminSteps : nonAdminSteps;

    const handleClick = pathname => {
        history(pathname);
        //reset all data
        dispatch(resetSurveyInfo());
        if (AuthStore.isAdminOrCoach) {
            dispatch(resetSaved());
            dispatch(resetSearched());
            dispatch(resetData());
        }
        dispatch(setOrgname(''));
    };

    const handleStepper = stepName => {
        dispatch(setActiveStep(stepName));
    };

    const setTextcolor = status => {
        if (activeStep === status) {
            return PrimaryDeepBlue;
        }
        if (disabledStep.includes(status)) {
            return Neutral30;
        }
        return Neutral80;
    };

    const setCursor = status => {
        return {
            cursor: !disabledStep.includes(status) ? 'pointer' : 'not-allowed',
        };
    };

    const handler = status => {
        if (!disabledStep.includes(status)) {
            handleStepper(status);
        }
    };

    return (
        <Paper
            elevation={0}
            sx={{
                zIndex: zIndex,
                position: position,
                width: width,
                borderRadius: '0px 0px 8px 8px',
                boxShadow: boxShadow,
            }}
        >
            <HeaderDiv>
                <FlexColumnGap gap={pxToVh(40)}>
                    <FlexBetween className="gapClass">
                        <FlexColumnGap gap={pxToVh(16)}>
                            <FlexRow gap="0.8">
                                <CommonLabel
                                    textColor={TextHeading}
                                    textSize={32}
                                    lineHeight={36}
                                    textWeight={500}
                                    label={LABELS.CREATE_SURVEY_LBL}
                                />
                                <img
                                    src={BtnPlay}
                                    alt="btnplay"
                                    style={{ height: `${pxToVh(24)}vh`, width: `${pxToVw(24)}vw`, display: 'none' }}
                                />
                            </FlexRow>
                            {/* BREADCRUMBS */}
                            <Breadcrumbs
                                separator={<Img image={breadcrumIcon} h={pxToVh(16)} w={pxToVw(16)} alt="separator" />}
                                sx={{ fontSize: `${pxToVh(14)}vh` }}
                            >
                                <Link onClick={() => handleClick(DASHBOARD)} style={stylesObj} title="clicked">
                                    <CommonLabel
                                        textColor={colorsCode.TextSubtle}
                                        textSize={14}
                                        lineHeight={20}
                                        textWeight={300}
                                        label={LABELS.HOME}
                                        cursor="pointer"
                                    />
                                </Link>
                                <Link onClick={() => handleClick(SURVEYS)} style={stylesObj}>
                                    <CommonLabel
                                        textColor={colorsCode.TextSubtle}
                                        textSize={14}
                                        lineHeight={20}
                                        textWeight={300}
                                        label={LABELS.CHOOSE_TEMPLATE}
                                        cursor="pointer"
                                    />
                                </Link>
                                <Link color={colorsCode.Neutral80} style={{ textDecoration: 'none' }}>
                                    <CommonLabel
                                        textColor={colorsCode.Neutral80}
                                        textSize={14}
                                        lineHeight={20}
                                        textWeight={400}
                                        label={LABELS.SURVEY_BUILDER}
                                    />
                                </Link>
                            </Breadcrumbs>
                        </FlexColumnGap>
                        <FlexRow>
                            <Flex className="flexGap">
                                <CommonLabel
                                    textColor={TextSubtle}
                                    textSize={14}
                                    lineHeight={20}
                                    textWeight={400}
                                    label=""
                                />
                                {isPreviewRequired ? (
                                    <CTA
                                        color={PrimaryDeepBlue}
                                        bg={white}
                                        bcolor={PrimaryDeepBlue}
                                        disabled={isPreviewDisabled}
                                        onClick={() => previewHandler()}
                                    >
                                        Preview
                                    </CTA>
                                ) : null}
                                {isNextRequired && (
                                    <LightTooltip
                                        title={isNextEnabled || loading ? '' : 'You have unsaved changes.'}
                                        placement="left"
                                        arrow
                                    >
                                        <CTA
                                            color={white}
                                            bg={PrimaryDeepBlue}
                                            onClick={clickHandler}
                                            disabled={!isNextEnabled || loading}
                                        >
                                            {btnText}
                                        </CTA>
                                    </LightTooltip>
                                )}
                            </Flex>
                        </FlexRow>
                    </FlexBetween>
                    <FlexBetween className="gapClass">
                        <FlexStart className="stepper">
                            {Object.entries(updatedSteps).map(([key, value]) => (
                                <FlexColumnGap key={key}>
                                    <Typography
                                        component="span"
                                        color={setTextcolor(value)}
                                        onClick={() => {
                                            if (pulseQuestionSelectError) {
                                                dispatch(setShowPulseQuestionSelectError(true));
                                            } else if (unsavedQuestError) {
                                                dispatch(setShowUnsavedError(true));
                                            } else {
                                                handler(value);
                                                dispatch(setShowUnsavedError(false));
                                            }
                                        }}
                                        className={activeStep === value ? 'active' : ''}
                                        sx={setCursor(value)}
                                    >
                                        {value}
                                    </Typography>
                                </FlexColumnGap>
                            ))}
                        </FlexStart>
                        {showSurveyID && (
                            <FlexStart>
                                <CommonLabel
                                    textSize={12}
                                    textColor={Neutral80}
                                    textWeight={500}
                                    label={si?.survey_name?.replaceAll('&amp;', '&') || ''}
                                />
                                &nbsp;
                                <CommonLabel
                                    textSize={12}
                                    textColor={TextSubtle}
                                    textWeight={500}
                                    label={si?.survey_id ? `(Survey ID: ${si?.survey_id?.toUpperCase()})` : ''}
                                />
                                &nbsp;
                                <div className="surveyStatusClass">
                                    {renderColoredChips(status, maintenance_mode, launch_date, launch_time)}
                                </div>
                            </FlexStart>
                        )}
                    </FlexBetween>
                </FlexColumnGap>
            </HeaderDiv>
        </Paper>
    );
};
const HeaderDiv = styled.div`
    padding: ${pxToVh(52)}vh ${pxToVw(52)}vw 0 ${pxToVw(52)}vw;
    &::first-child {
        padding: 0 0.55vw;
    }
`;
export default Header;
