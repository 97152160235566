import { Dialog, DialogActions, DialogContent, DialogTitle, Icon, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import CloseIcon from '../../../../../assets/img/close.svg';
import { Set_Question_Logic } from '../../../../../global-constants';
import Text from '../../../../KnowledgeCenter/Text';
import { CTA, FlexColumnGap, FlexColumnWithAlign, FlexRow } from '../../../../../common/styled/styled';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../Loader';
import {
    SelectQuestionDropdown,
    SelectResponseDropdown,
    SelectStateDropdown,
} from './DisplayLogicComponents/SelectQuestionDropdown';
import { SaveDisplayLogic } from '../../../../../store/actions/DisplayLogic/actions';
import { cloneDeep } from 'lodash';
import { DisplayLogicContainer } from './DisplayLogicQuestion';

const DisplayLogicModal = ({ onClose, showDisplayLogicModal, question, surveyId, activeTab }) => {
    const dispatch = useDispatch();
    const { loading, previousQuestionList, subGroupId } = useSelector(state => state.displayLogicData);
    let { question_text = '', question_id = '', conditions } = question;

    const [questionLogicState, setQuestionLogicState] = useState({});
    useEffect(() => {
        setQuestionLogicState({
            question_id: question_id,
            conditions: {
                on: conditions ? conditions?.on : 'question',
                cases: [
                    {
                        op: conditions ? conditions?.cases[0]?.op : 'AND',
                        logic: [
                            {
                                question_id: conditions ? conditions?.cases[0]?.logic[0].question_id : '',
                                option_id: conditions ? conditions?.cases[0]?.logic[0].option_id : [],
                                state: conditions ? conditions?.cases[0]?.logic[0].state : '',
                                question_options: previousQuestionList.find(
                                    ele => ele.question_id === conditions?.cases[0]?.logic[0].question_id
                                )?.question_options,
                                selected_options: conditions
                                    ? previousQuestionList
                                          .find(ele => ele.question_id === conditions?.cases[0]?.logic[0].question_id)
                                          ?.question_options?.filter(opt =>
                                              conditions?.cases[0]?.logic[0].option_id.includes(opt.option_id)
                                          )
                                    : [],
                                selected_quest: conditions
                                    ? previousQuestionList.find(
                                          ele => ele.question_id === conditions?.cases[0]?.logic[0].question_id
                                      )
                                    : '',
                            },
                        ],
                    },
                ],
            },
        });
    }, [previousQuestionList]);

    const handleQuestionSelect = (event, selectedQuest) => {
        const updatedQuestionLogic = questionLogicState?.conditions?.cases[0]?.logic?.map(ele => {
            return {
                ...ele,
                question_id: selectedQuest?.question_id,
                question_options: selectedQuest ? selectedQuest?.question_options : [],
                selected_options: [],
                option_id: [],
                selected_quest: selectedQuest,
            };
        });

        const temp = {
            ...questionLogicState,
            conditions: { ...questionLogicState.conditions, cases: [{ op: 'AND', logic: updatedQuestionLogic }] },
        };

        setQuestionLogicState(temp);
    };
    const handleSelectResponse = (event, value, question_id) => {
        const updatedQuestionLogic = questionLogicState?.conditions?.cases[0]?.logic?.map(ele => {
            const idCounts = value.reduce((counts, obj) => {
                counts[obj.option_id] = (counts[obj.option_id] || 0) + 1;
                return counts;
            }, {});

            const uniqueOpt = value.filter(obj => idCounts[obj.option_id] === 1);

            if (ele?.question_id === question_id) {
                const updatedEle = {
                    ...ele,
                    option_id: uniqueOpt.map(opt => opt.option_id),
                    selected_options: uniqueOpt,
                };
                return updatedEle;
            }
            return ele;
        });
        setQuestionLogicState({
            ...questionLogicState,
            conditions: { ...questionLogicState.conditions, cases: [{ op: 'AND', logic: updatedQuestionLogic }] },
        });
    };
    const handleStateResponse = (value, question_id) => {
        const updatedQuestionLogic = questionLogicState?.conditions?.cases[0]?.logic?.map(ele => {
            if (ele?.question_id === question_id) {
                const updatedEle = { ...ele, state: value === 'Selected' ? 'IN' : 'NIN' };
                return updatedEle;
            }
            return ele;
        });
        setQuestionLogicState({
            ...questionLogicState,
            conditions: { ...questionLogicState.conditions, cases: [{ op: 'AND', logic: updatedQuestionLogic }] },
        });
    };

    const handleSaveDisable = () => {
        if (
            questionLogicState?.conditions?.cases[0]?.logic[0]?.question_id &&
            questionLogicState?.conditions.cases[0]?.logic[0]?.option_id?.length &&
            questionLogicState?.conditions.cases[0]?.logic[0]?.state?.length
        )
            return false;
        return true;
    };
    const handleSave = event => {
        event.stopPropagation();
        const tempPayload = cloneDeep(questionLogicState);
        if (
            tempPayload.conditions &&
            tempPayload.conditions.cases &&
            tempPayload.conditions.cases[0] &&
            tempPayload.conditions.cases[0].logic &&
            tempPayload.conditions.cases[0].logic[0] &&
            'question_options' in tempPayload.conditions.cases[0].logic[0]
        ) {
            delete tempPayload.conditions.cases[0].logic[0].question_options;
            delete tempPayload.conditions.cases[0].logic[0].selected_quest;
            delete tempPayload.conditions.cases[0].logic[0].selected_options;
        }
        dispatch(SaveDisplayLogic({ surveyId, tempPayload, activeTab, subGroupId }));
        onClose(true);
    };
    return (
        <Dialog
            open={showDisplayLogicModal}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(987)}vw`,
                    width: `${pxToVw(987)}vw`,
                    height: `${pxToVh(587)}vh`,
                    maxheight: `${pxToVh(587)}vh`,
                },
            }}
        >
            {loading && <Loader showBackground background="transparent" position="fixed" />}
            <DialogTitle sx={{ mt: `${pxToVh(10)}vh` }}>
                <FlexColumnWithAlign gap="1">
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={18}
                        lineHeight={24}
                        label={Set_Question_Logic}
                        fontWeight={500}
                    />
                    <Text
                        fontColor={colorsCode.Neutral80}
                        fontSize={14}
                        lineHeight={24}
                        label={'Define the conditions through which this question will be displayed to your user. '}
                        fontWeight={400}
                    />
                </FlexColumnWithAlign>
                <Icon
                    aria-label="close"
                    onClick={() => onClose(true)}
                    sx={{
                        position: 'absolute',
                        right: `${pxToVw(16)}vw`,
                        top: `${pxToVh(16)}vh`,
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={CloseIcon}
                        alt=""
                        style={{
                            height: `${pxToVh(18)}vh`,
                            width: ` ${pxToVw(18)}vw`,
                        }}
                    />
                </Icon>
            </DialogTitle>
            <DialogContent sx={{ width: '100%' }}>
                <FlexColumnWithAlign gap="2">
                    <DisplayLogicContainer width="50%">
                        <FlexRow justifyContent="space-between">
                            <FlexColumnGap gap="0.5" width="70%">
                                <Text
                                    fontColor={colorsCode.purple}
                                    fontSize={12}
                                    lineHeight={24}
                                    label={'Display this question'}
                                    fontWeight={400}
                                    fontStyle="italic"
                                />
                            </FlexColumnGap>
                            <FlexColumnGap gap="0.5" width="85%">
                                <Text
                                    fontColor={colorsCode.purple}
                                    fontSize={12}
                                    lineHeight={24}
                                    label={'Only if this logic is applied'}
                                    fontWeight={400}
                                    fontStyle="italic"
                                />
                            </FlexColumnGap>
                        </FlexRow>
                    </DisplayLogicContainer>
                    {questionLogicState?.conditions?.cases[0]?.logic?.map(item => (
                        <DisplayLogicContainer key={item.question_id} width="100%">
                            <FlexRow justifyContent="space-between">
                                <FlexColumnGap gap="0.5" width="75%">
                                    <Typography
                                        style={{ color: `${colorsCode.TextSubtle}` }}
                                        component="span"
                                        fontWeight="400"
                                    >
                                        Question
                                    </Typography>
                                    <TextField
                                        sx={{ width: '100%' }}
                                        disabled
                                        value={question_text.replace(/<\/?[^>]+(>|$)/g, '')}
                                        variant="outlined"
                                        size="small"
                                    />
                                </FlexColumnGap>
                                <FlexColumnGap gap="0.5" width="85%">
                                    <Typography component="span" fontWeight="400">
                                        Select question
                                    </Typography>
                                    <SelectQuestionDropdown
                                        previousQuestionList={previousQuestionList}
                                        handleQuestionSelect={handleQuestionSelect}
                                        selectedQues={item.selected_quest}
                                    />
                                </FlexColumnGap>
                                <FlexColumnGap gap="0.5" width="100%">
                                    <Typography component="span" fontWeight="400">
                                        Select response
                                    </Typography>
                                    <SelectResponseDropdown
                                        optionList={
                                            conditions
                                                ? previousQuestionList.find(
                                                      ele => ele?.question_id === item?.question_id
                                                  )?.question_options
                                                : item?.question_options
                                        }
                                        handleSelectResponse={(event, value) => {
                                            handleSelectResponse(event, value, item?.question_id);
                                        }}
                                        defaultValue={item?.selected_options}
                                    />
                                </FlexColumnGap>
                                <FlexColumnGap gap="0.5" width="70%">
                                    <Typography component="span" fontWeight="400">
                                        Select state
                                    </Typography>
                                    <SelectStateDropdown
                                        handleStateResponse={val => handleStateResponse(val, item?.question_id)}
                                        state={item?.state}
                                    />
                                </FlexColumnGap>
                            </FlexRow>
                        </DisplayLogicContainer>
                    ))}
                </FlexColumnWithAlign>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'start', m: `${pxToVw(25)}vw` }}>
                <CTA
                    bg={colorsCode.PrimaryDeepBlue}
                    disabled={handleSaveDisable()}
                    pt={4}
                    pl={16}
                    size={12}
                    fw={400}
                    lh={28}
                    color={colorsCode.white}
                    onClick={handleSave}
                    id="basic-button"
                >
                    Save
                </CTA>
            </DialogActions>
        </Dialog>
    );
};

export default DisplayLogicModal;
