import React from 'react';
import { Modulebody } from '../../OptionalModules';
import { CommonLabel } from '../../../../../../common/FormInputs/Common';
import { colorsCode, pxToVh, pxToVw } from '../../../../../../common/colors';
import c from 'classnames';
import { CardWrapper } from '../Pulse/PracticeSections';
import OtherThanValuesCard from '../Pulse/OtherThanValuesCard';
import { Paper } from '@mui/material';
import TeiQuestions from './TeiQuestions';
import AuthStore from '../../../../../../common/AuthStore';

const TEIModule = ({ isHeaderRequired = true, styles, teiData, handleModuleUpdate, handleQuestionUpdate }) => {
    const [tabId, setTabId] = React.useState(null);
    const makeQuestionDisable = !AuthStore.isAdmin;

    React.useEffect(() => {
        if (tabId === null && teiData?.length) {
            const { sub_group_code } = teiData[0];
            setTabId(sub_group_code);
        }
        // eslint-disable-next-line
    }, [teiData, tabId]);

    return (
        <Paper
            elevation={2}
            sx={{ ...styles, alignSelf: 'stretch' }}
            className={c('outcomesAndPractices', {
                notPulse: makeQuestionDisable,
            })}
        >
            <Modulebody>
                <CommonLabel
                    textWeight={500}
                    textSize={18}
                    lineHeight={28}
                    label="Optional modules"
                    textColor={colorsCode.TextHeading}
                />
            </Modulebody>
            <Modulebody style={{ paddingTop: '0vh', paddingBottom: `${pxToVh(40)}vh` }}>
                <CardWrapper gap={pxToVw(16)} flexWrap="wrap">
                    {teiData?.map((item, index) => {
                        return (
                            <OtherThanValuesCard
                                key={item.id}
                                isValues={false}
                                item={item}
                                index={index}
                                addRemoveModule={(item, index) => {
                                    handleModuleUpdate(item, index, true);
                                }}
                                desc={item.description?.en || item.group_text}
                            />
                        );
                    })}
                </CardWrapper>
            </Modulebody>
            {teiData?.length && isHeaderRequired ? (
                <TeiQuestions
                    teiData={teiData}
                    handleQuestionUpdate={handleQuestionUpdate}
                    handleModuleUpdate={handleModuleUpdate}
                    makeQuestionDisable={makeQuestionDisable}
                />
            ) : null}
        </Paper>
    );
};

export default TEIModule;
