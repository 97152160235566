import { Dialog, DialogTitle, DialogActions } from '@mui/material';
import React from 'react';
import { colorsCode, pxToVh, pxToVw } from './colors';
import CloseIcon from '../assets/img/close.svg';
import { CTA, FlexCenterWithAlign, FlexColumnCenter } from './styled/styled';
import { CommonLabel, Img } from './FormInputs/Common';
import styled from 'styled-components';

const ConfirmationModal = ({
    open,
    onClose,
    icon,
    title = '',
    description = '',
    showDescription = true,
    confirmationText = '',
    showCancel = true,
    onConfirm,
    onConfirmText = '',
    onCancelText = 'Cancel',
    textWeight,
    textSize,
    lineHeight,
}) => {
    return (
        <ConfirmDialog open={open}>
            <ConfirmCloseIcon src={CloseIcon} alt="" onClick={onClose} />

            <ConfirmDialogTitle>
                <FlexCenterWithAlign>
                    <Img image={icon} h={pxToVh(45)} w={pxToVw(45)} alt="" />
                </FlexCenterWithAlign>
                <FlexCenterWithAlign>
                    <CommonLabel
                        textSize={20}
                        textColor={colorsCode.TextHeading}
                        textWeight={500}
                        lineHeight={36}
                        label={title}
                    />
                </FlexCenterWithAlign>
                <FlexColumnCenter gap="1" margin="20px 0">
                    {showDescription ? (
                        <FlexCenterWithAlign>
                            <CommonLabel
                                textSize={textSize || 14}
                                textColor={colorsCode.TextHeading}
                                textWeight={textWeight || 300}
                                textAlign="center"
                                label={description}
                                lineHeight={lineHeight || 18}
                            />
                        </FlexCenterWithAlign>
                    ) : null}
                    <FlexCenterWithAlign>
                        <CommonLabel
                            textSize={14}
                            textColor={colorsCode.TextHeading}
                            textWeight={300}
                            textAlign="center"
                            label={confirmationText}
                        />
                    </FlexCenterWithAlign>
                </FlexColumnCenter>
            </ConfirmDialogTitle>

            <ConfirmDialogActions>
                {showCancel && (
                    <CTA
                        size={16}
                        lh={24}
                        bg={colorsCode.white}
                        pt={8}
                        pl={16}
                        color={colorsCode.PrimaryDeepBlue}
                        bcolor={colorsCode.PrimaryDeepBlue}
                        onClick={onClose}
                    >
                        {onCancelText}
                    </CTA>
                )}
                <CTA
                    size={16}
                    lh={24}
                    bg={colorsCode.PrimaryDeepBlue}
                    pt={8}
                    pl={16}
                    color={colorsCode.white}
                    onClick={onConfirm}
                >
                    {onConfirmText}
                </CTA>
            </ConfirmDialogActions>
        </ConfirmDialog>
    );
};

export default ConfirmationModal;

const ConfirmDialog = styled(Dialog)(() => ({
    '& .MuiPaper-root': {
        maxWidth: `${pxToVw(480)}vw`,
        width: `${pxToVw(480)}vw`,
        borderRadius: '10px',
    },
}));

const ConfirmCloseIcon = styled.img`
    height: ${pxToVh(20)}vh;
    width: ${pxToVw(20)}vw;
    cursor: pointer;
    right: ${pxToVw(10)}vw;
    top: ${pxToVh(8)}vh;
    position: absolute;
`;

const ConfirmDialogTitle = styled(DialogTitle)`
    margin-top: 15px !important;
`;

const ConfirmDialogActions = styled(DialogActions)`
    justify-content: center !important;
    padding: ${pxToVh(20)}vh 0 ${pxToVh(35)}vh !important;
    gap: ${pxToVw(24)}vw;

    && > :not(:first-of-type) {
        margin-left: 0;
    }
`;
