import { makeStyles } from '@mui/styles';
import { colorsCode, pxToVh, pxToVw } from '../../../common/colors';

const themeStyles = makeStyles(() => {
    return {
        parent: {
            width: '80vw',
            marginTop: '3vh',
            marginBottom: '12vh',
        },
        title: {
            fontSize: '3.4vh !important',
        },
        liveSurveyHeader: {
            padding: '2.83vh 1.67vw',
            background: '#E6F2F9',
            borderTopLeftRadius: '8px',
            borderTopRightRadius: '8px',
        },
        ellipsisCss: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: '1',
            '-webkit-box-orient': 'vertical',
        },
        isRequired: {
            '&::after': {
                content: '" *"',
                color: colorsCode.error,
            },
        },
        typoRoot: {
            marginTop: '1.5vh !important',
            marginBottom: '2vh !important',
            fontSize: ' 3.39vh !important',
        },
        rootTotalCount: {
            marginTop: '6vh !important',
            marginBottom: '2vh !important',
            fontSize: '4.53vh !important',
        },
        paperRoot: {
            background: '#0679C3 !important',
            padding: `0 0 0 ${pxToVw(48)}vw`,
            borderRadius: '8px !important',
        },
        sectionParent: {
            padding: '4.3vh',
            width: '47vw',
            height: '47vh',
            color: 'white',
            marginRight: '10vh !important',
        },
        surveyTitle: {
            fontFamily: 'Bower !important',
            fontSize: `${pxToVh(44)}vh !important`,
            lineHeight: `${pxToVh(52)}vh !important`,
            marginBottom: `${pxToVh(24)}vh !important`,
        },
        infoClass: {
            fontSize: `${pxToVh(18)}vh !important`,
            lineHeight: `${pxToVh(28)}vh !important`,
            marginTop: `${pxToVh(24)}vh !important`,
        },
        imgClass: {
            paddingTop: '10vh',
            height: '47vh',
        },
        img: {
            height: '100%',
            width: '100%',
            objectFit: 'contain',
        },
        watchPaper: {
            background: '#FFEFD1 !important',
            padding: '2.3vh 2.5vw',
        },
        watchBtn: {
            color: '#051C2C !important',
            border: '1px solid #051C2C !important',
            padding: '0.9vh 2vh !important',
            fontWeight: '400 !important',
            textTransform: 'capitalize !important',
            lineHeight: '2.83vh !important',
            fontSize: '1.98vh !important',
        },
        createBtn: {
            marginTop: '4vh',
        },
        typoClass: {
            fontSize: `${pxToVh(14)}vh !important`,
            fontWeight: '400 !important',
        },
        surveyParent: {
            height: '60.73vh',
            width: '31.95vw',
            marginRight: '2.2vh',
        },
        createSurvey: {
            width: '24.17vw !important',
        },
        sectionHeaderBlue: {
            width: '18.33vw !important',
            height: '15.56vh !important',
            background: '#E6F2F9 !important',
            padding: '2.9vh 2.9vh !important',
            borderRadius: '8px !important',
            boxShadow: '0px 2px 4px -1px rgba(5, 28, 44, 0.2), 0px 0px 1px rgb(5, 28, 44, 0.15);',
        },
        sectionHeaderWhite: {
            width: '22.33vw',
            height: '15.27vh',
            // background: "#ffffff",
            padding: '3.39vh 1.67vw',
            borderRadius: '8px',
            boxShadow: '0px 2px 4px -1px rgba(5, 28, 44, 0.2), 0px 0px 1px rgb(5, 28, 44, 0.15);',
        },
        surveyText: {
            fontSize: '1.98vh !important',
            color: '#757575 !important',
            fontWeight: '400 !important',
            '@media (max-width: 768px)': {
                fontSize: '1.11vw !important',
            },
            '@media (max-width: 991px)': {
                fontSize: '1.11vw !important',
            },
            '@media (max-width: 900px)': {
                fontSize: '1.11vw !important',
            },
            '@media (max-width: 1024px)': {
                fontSize: '1.11vw !important',
            },
            '@media (max-width: 1100px)': {
                fontSize: '1.11vw !important',
            },
        },
        txtBulb: {
            marginTop: '1.15vh !important',
            fontSize: '1.98vh !important',
            color: '#757575',
        },
        headerBulb: {
            fontSize: '2.30vh !important',
            color: '#2B5580 !important',
            textUnderlineOffset: '2px',
            textDecorationThickness: '1px',
        },
        headerClient: {
            fontSize: '1.98vh !important',
            color: '#757575',
        },
        txtClient: {
            marginTop: '1.15vh !important',
            fontFamily: 'Bower !important',
            fontSize: '4.32vh !important',
            color: '#333333',
        },
        rightDivText: {
            fontSize: '2.23vh !important',
            textDecoration: 'underline',
        },
        rightDivClass: {
            width: '20vw !important',
            padding: '2vh 2vw !important',
            height: '30vh !important',
        },
        firstLoginClass: {
            gap: '1.42vh',
            paddingLeft: `${pxToVw(10)}vw`,
            alignSelf: 'stretch',
            flex: 0.45,
            maxWidth: `${pxToVw(392)}vw`,
        },
        activeClass: {
            borderBottom: `${pxToVh(4)}vh solid #051C2C`,
            paddingBottom: `${pxToVh(4)}vh`,
            color: '#051C2C !important',
            fontWeight: '400 !important',
            cursor: 'pointer',
            fontSize: '2.23vh !important',
        },
        activeClassError: {
            borderBottom: `${pxToVh(4)}vh solid ${colorsCode.error}`,
            paddingBottom: `${pxToVh(4)}vh`,
            color: '#051C2C !important',
            fontWeight: '400 !important',
            cursor: 'pointer',
            fontSize: '2.23vh !important',
        },
        tabClass: {
            borderBottom: `${pxToVh(4)}vh solid transparent`,
            paddingBottom: `${pxToVh(4)}vh`,
            fontSize: '2.23vh !important',
            fontWeight: '300 !important',
            color: '#333333 !important',
            cursor: 'pointer',
        },
        liveSurveyContainer: {
            margin: `${pxToVh(40)}vh 0 ${pxToVh(24)}vh`,
        },
    };
});

export default themeStyles;
