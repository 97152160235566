import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CTA, FlexColumnGap, FlexRow } from '../../../../common/styled/styled';
import {
    Checkbox,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Icon,
    ListSubheader,
    MenuItem,
} from '@mui/material';
import { DialogActionsStyled } from '../../../Surveys/partials/setup-survey/Finalize';
import CloseIcon from '../../../../assets/img/close.svg';
import Note from '../../../../assets/img/note-grey.svg';
import ArrowDownIcon from '../../../../assets/img/arrow_down.svg';
import { CommonChip, Img } from '../../../../common/FormInputs/Common';
import { SelectStyled } from '../../../../common/FormInputs/DropdownMenu';
import Text from '../../../KnowledgeCenter/Text';
import { api } from '../../../../http/api';
import { Endpoints } from '../../../../http/Endpoints';
const ResponseReporting = ({ surveyId, onClose, clientName, totalCompleted, setShowConfirmation, isTEI = false }) => {
    const [selectedOptions, setSelectedOptions] = useState(isTEI ? '' : ['Overall']);
    const [loading, setLoading] = useState(true);
    const [groupedOptions, setGroupedOptions] = useState({});
    const isMaxSelectionReached = selectedOptions.length >= 3;

    const handleChange = option => {
        const newSelected = selectedOptions.includes(option)
            ? selectedOptions.filter(item => item !== option)
            : [...selectedOptions, option];

        if (newSelected.length > 3) return;

        setSelectedOptions(newSelected);
    };

    const getRespondentList = async () => {
        setLoading(true);
        try {
            const res = await api.get(`${Endpoints.getRespondentList.replace(':survey_id', surveyId)}`);
            setGroupedOptions(['None', ...res.data.data]);
        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
    };

    const getDropdownData = async () => {
        setLoading(true);
        try {
            const res = await api.get(`${Endpoints.getReportBenchmark.replace(':survey_id', surveyId)}`);
            setGroupedOptions(res.data.data);
        } catch (error) {
            console.log('error', error);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (isTEI) {
            setLoading(false);
            getRespondentList();
        } else {
            getDropdownData();
        }
    }, []);

    const renderGroupBenchmarkOptions = () => {
        return (
            <>
                <MenuItemStyled value="Overall">
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={selectedOptions.includes('Overall')}
                                disabled={isMaxSelectionReached && !selectedOptions.includes('Overall')}
                                onChange={() => handleChange('Overall')}
                                sx={{
                                    '&.Mui-disabled': {
                                        background: colorsCode.Neutral80,
                                        opacity: 0.5,
                                        height: '20px',
                                        width: '20px',
                                        borderRadius: '2px',
                                        padding: '0',
                                        margin: '10px 12px',
                                    },
                                }}
                            />
                        }
                        label="Overall"
                        className="Overall"
                    />
                </MenuItemStyled>

                {Object.entries(groupedOptions)
                    .filter(([group]) => group !== 'Overall')
                    ?.map(([group, items]) => (
                        <React.Fragment key={group}>
                            <ListSubheaderStyled>{group}</ListSubheaderStyled>
                            {items?.map(item => (
                                <MenuItemStyled key={item} value={item}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={selectedOptions.includes(item)}
                                                disabled={isMaxSelectionReached && !selectedOptions.includes(item)}
                                                sx={{
                                                    '&.Mui-disabled': {
                                                        background: colorsCode.Neutral80,
                                                        opacity: 0.5,
                                                        height: '20px',
                                                        width: '20px',
                                                        borderRadius: '2px',
                                                        padding: '0 !important',
                                                        margin: '0 12px',
                                                    },
                                                }}
                                            />
                                        }
                                        label={item}
                                        className="OtherOptions"
                                        onChange={() => handleChange(item)}
                                    />
                                </MenuItemStyled>
                            ))}
                        </React.Fragment>
                    ))}
            </>
        );
    };

    const renderRespondentIDOptions = () => {
        return groupedOptions.map(item => (
            <MenuItem key={item} value={item} onClick={() => setSelectedOptions(item)}>
                {item}
            </MenuItem>
        ));
    };
    const handleDownloadPPT = async () => {
        setLoading(true);
        try {
            const payload = isTEI
                ? { leader_respondent_id: selectedOptions === 'None' ? null : selectedOptions }
                : { benchmarks: !selectedOptions?.length ? ['Overall'] : selectedOptions };
            const res = await api.post(`${Endpoints.exportPPTReport.replace(':survey_id', surveyId)}`, payload);
            if (res.data.url) {
                let downloadLink = document.createElement('a');
                downloadLink.target = '_blank';
                downloadLink.href = res.data.url;
                document.body.appendChild(downloadLink);
                downloadLink.click();
                document.body.removeChild(downloadLink);
            }
            setShowConfirmation(res.data.message);
        } catch (error) {
            console.log('error', error);
        }
        onClose();
        setLoading(false);
    };

    return (
        <Dialog
            open={true}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(520)}vw`,
                    width: `${pxToVw(520)}vw`,
                    minHeight: `${pxToVh(isTEI ? 200 : 320)}vh`,
                },
            }}
        >
            <FlexRow justifyContent="flex-end" margin="1vw 1vw 0 0">
                <Icon
                    aria-label="close"
                    sx={{
                        position: 'absolute',
                        right: `${pxToVw(16)}vw`,
                        top: `${pxToVh(12)}vh`,
                        cursor: 'pointer',
                    }}
                >
                    <img
                        src={CloseIcon}
                        alt=""
                        style={{
                            height: `${pxToVh(18)}vh`,
                            width: ` ${pxToVw(18)}vw`,
                            cursor: 'pointer',
                        }}
                        onClick={onClose}
                    />
                </Icon>
            </FlexRow>
            <DialogTitle sx={{ padding: `20px 30px 30px` }}>
                <Text fontColor={colorsCode.TextHeading} fontSize="24px" label="Response report" fontWeight={500} />
            </DialogTitle>
            {loading ? (
                <FlexRow justifyContent="center" alignItems="center" style={{ height: '200px' }}>
                    <CircularProgress
                        sx={{
                            height: '25px !important',
                            width: '25px !important',
                            color: colorsCode.blueShade5,
                        }}
                    />
                </FlexRow>
            ) : (
                <>
                    <DialogContent sx={{ padding: `10px 30px` }}>
                        {!isTEI && (
                            <FlexColumnGap gap="0" style={{ marginBottom: '40px' }}>
                                <FlexRow>
                                    <Img image={Note} />
                                    <Text
                                        fontColor={colorsCode.TextSubtle}
                                        fontSize="14px"
                                        label={clientName}
                                        fontWeight={400}
                                    />
                                </FlexRow>
                                <Text
                                    fontColor={colorsCode.Neutral80}
                                    fontSize="16px"
                                    label={`N=${totalCompleted}`}
                                    fontWeight={400}
                                />
                            </FlexColumnGap>
                        )}

                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize="16px"
                            label={isTEI ? 'Leader’s respondent ID' : 'Benchmark (Select upto 3 options)'}
                            fontWeight={400}
                        />
                        <FormControl fullWidth>
                            <SelectStyled
                                multiple={!isTEI}
                                value={isTEI ? groupedOptions : Object.entries(groupedOptions)}
                                renderValue={() =>
                                    isTEI && selectedOptions ? (
                                        selectedOptions
                                    ) : (
                                        <span className="dropdown-placeholder">
                                            {isTEI ? 'Select ID' : 'Select benchmark'}
                                        </span>
                                    )
                                }
                                MenuProps={{
                                    PaperProps: {
                                        style: {
                                            maxHeight: 300,
                                        },
                                    },
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'left',
                                    },
                                    transformOrigin: {
                                        vertical: 'top',
                                        horizontal: 'left',
                                    },
                                    getContentAnchorEl: null,
                                }}
                                sx={{ height: '45px', marginTop: '10px' }}
                                IconComponent={props => <img src={ArrowDownIcon} {...props} alt="dropdown" />}
                            >
                                {isTEI ? renderRespondentIDOptions() : renderGroupBenchmarkOptions()}
                            </SelectStyled>
                        </FormControl>

                        {!isTEI && (
                            <div style={{ paddingTop: '10px' }}>
                                {selectedOptions.map((item, i) => {
                                    return (
                                        <CommonChip
                                            key={i}
                                            value={item}
                                            onDeleteHandler={() => {
                                                handleChange(item);
                                            }}
                                            canDelete={true}
                                        />
                                    );
                                })}
                            </div>
                        )}
                    </DialogContent>

                    <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={onClose}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            onClick={() => {
                                handleDownloadPPT();
                            }}
                            disabled={isTEI ? !selectedOptions : false}
                        >
                            Download {isTEI ? 'Excel' : 'ppt'}
                        </CTA>
                    </DialogActionsStyled>
                </>
            )}
        </Dialog>
    );
};

export default ResponseReporting;

const ListSubheaderStyled = styled(ListSubheader)`
    && {
        font-size: 12px;
        line-height: 16px;
        font-weight: 400;
        color: ${colorsCode.TextSubtle};
        padding: ${pxToVh(4)}vh 16px;
    }
`;

const MenuItemStyled = styled(MenuItem)`
    .Overall {
        padding: 0 2px;
    }
    .OtherOptions {
        .MuiButtonBase-root {
            padding: 0 10px;
        }
    }
`;
