import React from 'react';
import { colorsCode, pxToVh, pxToVw } from '../../../../common/colors';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '../../../../assets/img/close.svg';
import Warning from '../../../../assets/img/T-warning.svg';
import { CTA, FlexColumnCenter } from '../../../../common/styled/styled';
import { Img } from '../../../../common/FormInputs/Common';
import Text from '../../../KnowledgeCenter/Text';
import { duplicateModalSubText } from '../../../../global-constants';

const DuplicateSurveyModal = ({ duplicateModal, closeDuplcateModal, handleDuplicate, duplicateLoader }) => {
    const { surveyId, open } = duplicateModal;
    return (
        <Dialog
            open={open}
            PaperProps={{
                sx: {
                    maxWidth: `${pxToVw(580)}vw`,
                    width: `${pxToVw(580)}vw`,
                    borderRadius: '8px',
                },
            }}
        >
            <img
                src={CloseIcon}
                alt=""
                style={{
                    height: `${pxToVh(16)}vh`,
                    width: ` ${pxToVw(16)}vw`,
                    cursor: 'pointer',
                    right: `${pxToVw(10)}vw`,
                    top: `${pxToVh(8)}vh`,
                    position: 'absolute',
                }}
                onClick={closeDuplcateModal}
            />
            <DialogTitle sx={{ marginTop: '15px' }}>
                <FlexColumnCenter gap="1">
                    <Img image={Warning} h={pxToVh(45)} w={pxToVw(45)} alt="" />
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize="24px"
                        label={`Are you sure you want to duplicate <br/> Survey Id - ${surveyId?.toUpperCase()} ?`}
                        textAlign="center"
                        fontWeight={500}
                        lineHeight="36px"
                    />
                </FlexColumnCenter>
            </DialogTitle>
            <DialogContent>
                <ul>
                    <Text
                        fontColor={colorsCode.TextHeading}
                        fontSize="18px"
                        label={duplicateModalSubText}
                        fontWeight={400}
                        lineHeight="28px"
                    />
                </ul>
            </DialogContent>
            <DialogActions sx={{ justifyContent: 'center', gap: '30px', paddingBottom: `${pxToVh(25)}vh` }}>
                <CTA
                    size={16}
                    fw={400}
                    lh={24}
                    color={colorsCode.PrimaryDeepBlue}
                    bg={colorsCode.white}
                    bcolor={colorsCode.PrimaryDeepBlue}
                    onClick={closeDuplcateModal}
                >
                    Cancel
                </CTA>
                {duplicateLoader ? (
                    <CircularProgress />
                ) : (
                    <CTA
                        size={16}
                        lh={24}
                        fw={400}
                        bg={colorsCode.PrimaryDeepBlue}
                        pt={8}
                        pl={16}
                        color={colorsCode.white}
                        onClick={handleDuplicate}
                    >
                        Duplicate
                    </CTA>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default DuplicateSurveyModal;
