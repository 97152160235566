import React, { useState } from 'react';
import {
    CTA,
    FlexAlignCenter,
    FlexBetween,
    FlexCenterWithAlign,
    FlexColumnCenter,
    FlexColumnGap,
    FlexEnd,
    FlexRow,
    FlexRowCTA,
    FlexRowTextCTA,
    FlexSelfEnd,
    FlexStartGap,
} from '../../../../../common/styled/styled';
import { colorsCode, pxToVh, pxToVw } from '../../../../../common/colors';
import styled from 'styled-components';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Checkbox,
    Dialog,
    DialogTitle,
    FormControlLabel,
    Menu,
    MenuItem,
    Typography,
} from '@mui/material';
import { ReactComponent as ArrowDown } from '../../../../../assets/img/arrow_down.svg';
import { CommonLabel, Img } from '../../../../../common/FormInputs/Common';
import {
    Question,
    SELECT_LOGIC,
    Set_Question_Logic,
    Set_Response_Logic,
    demographic_ques,
    display_default_text,
    open_text_ques,
    ADD_LOGIC,
    NoResultDisplayLogicSubText,
    QUES_STATUS_TYPE,
} from '../../../../../global-constants';
import Vector from '../../../../../assets/img/Vector.svg';
import MenuImg from '../../../../../assets/img/Menu-5.svg';
import Info from '../../../../../assets/img/info.svg';
import DisplayLogicModal from './DisplayLogicModal';
import Text from '../../../../KnowledgeCenter/Text';
import { useDispatch, useSelector } from 'react-redux';
import { DeleteDisplatLogic, GetPreviousQuestionList } from '../../../../../store/actions/DisplayLogic/actions';
import { cloneDeep, find, findIndex } from 'lodash';
import LightTooltip from '../../../../../common/LightTooltip';
import { Icon } from '@mds/mds-reactjs-library';
import { DialogActionsStyled } from '../Finalize';
import CloseIcon from '../../../../../assets/img/close.svg';
import RedWarningIcon from '../../../../../assets/img/red_warning.svg';
import next from '../../../../../assets/img/next.svg';
import DisplayResponseLogicModal from './DisplayResponseLogicModal';
import { commonThemes } from '../../../../../common/themes';
import SearchBar from '../../../../../common/FormInputs/SearchBar';
import AuthStore from '../../../../../common/AuthStore';
import { StatusBadge } from '../Badge';
import approved from '../../../../../assets/img/approved.svg';
import rejected from '../../../../../assets/img/rejected.svg';
import pending from '../../../../../assets/img/pending.svg';
import notsubmitted from '../../../../../assets/img/not submitted.svg';

const DisplayLogicQuestion = ({ question, surveyId, activeTab, first_quest, handleOptionSearch }) => {
    const {
        question_text = '',
        question_options = [],
        display_logic = [],
        question_id,
        conditions = null,
        is_scale_question = true,
        show_response_logic = true,
        question_status = '',
    } = question;

    const dispatch = useDispatch();
    const styles = commonThemes();
    const [showDisplayLogicOptions, setShowDisplayLogicOptions] = useState(null);
    const [showSummaryMenu, setSummaryMenu] = useState(null);
    const [showDisplayLogicModal, setShowDisplayLogicModal] = useState(false);
    const [isExpanded, setIsExpanded] = useState(false);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [optionsCheckBox, setOptionsCheckBox] = useState(false);
    const { subGroupId } = useSelector(state => state.displayLogicData);
    const clonedOptions = cloneDeep(question_options);
    const [showDeletePopUp, setShowDeletePopUp] = useState(false);
    const [search, setSearch] = useState('');

    const [showResponseLogicModal, setShowResponseLogicModal] = useState(false);
    const [editResponse, setEditResponse] = useState(false);
    const optionsConditionExist = display_logic?.length && conditions?.on === 'option';
    const localSortedOption = clonedOptions?.sort((a, b) => a?.option_order - b?.option_order)?.slice(0, 12);
    const questionType = AuthStore.isAdmin ? 'ALL' : 'DEMOGRAPHIC';

    const [sortedOption, setSortOption] = useState([]);

    React.useEffect(() => {
        setSortOption(localSortedOption);
    }, [question_options]);

    const renderQuestionLogicSummary = display_logic?.map((item, index) => {
        const allowedQuestArr = item?.allowed_options?.map(ele => ele.option_text).join(', ');
        const questionOptionsArr = item?.question_options?.map(ele => ele.option_text).join(', ');
        const rowNum = index + 1;
        return (
            <FlexBetween key={item?.question_uuid}>
                <FlexRowTextCTA alignItems="start">
                    <div>
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize={11}
                            lineHeight={20}
                            label={rowNum.toString().padStart(2, '0')}
                            fontWeight={500}
                        />
                    </div>

                    <PaddingContainer padding="0 4vw 0 0">
                        {item.logic_on === 'question' ? (
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={12}
                                lineHeight={20}
                                label={index === 0 ? 'If' : item.operator}
                                fontWeight={400}
                                sx={{ paddingRight: '20px' }}
                            />
                        ) : (
                            ''
                        )}
                        {item.logic_on === 'option' ? (
                            <Text
                                fontColor={colorsCode.Neutral80}
                                fontSize={12}
                                lineHeight={20}
                                label={allowedQuestArr}
                                fontWeight={400}
                                sx={{ paddingRight: '20px' }}
                            />
                        ) : (
                            ''
                        )}
                        {item.logic_on === 'option' ? (
                            <Text
                                fontColor={colorsCode.TextSubtle}
                                fontSize={12}
                                lineHeight={20}
                                label="will be shown if"
                                fontWeight={400}
                                sx={{ paddingRight: '20px' }}
                            />
                        ) : (
                            ''
                        )}
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize={12}
                            lineHeight={20}
                            label={item?.question_text}
                            fontWeight={400}
                            sx={{ paddingRight: '20px' }}
                        />
                        <Text
                            fontColor={colorsCode.Neutral80}
                            fontSize={12}
                            lineHeight={20}
                            label={questionOptionsArr}
                            fontWeight={400}
                            sx={{ paddingRight: '20px' }}
                        />
                        <Text
                            fontColor={colorsCode.TextSubtle}
                            fontSize={12}
                            lineHeight={20}
                            label={item?.state?.toUpperCase() === 'IN' ? 'is selected' : 'not selected'}
                            fontWeight={400}
                            sx={{ paddingRight: '20px' }}
                        />
                    </PaddingContainer>
                </FlexRowTextCTA>
                {display_logic?.length - 1 === index && question_id !== 241 ? (
                    <FlexSelfEnd id="basic-summary-menu">
                        <Img
                            image={MenuImg}
                            h={pxToVh(22)}
                            w={pxToVw(16)}
                            alt=""
                            clickEvent={event => {
                                event.stopPropagation();
                                setSummaryMenu(event.currentTarget);
                            }}
                        />
                    </FlexSelfEnd>
                ) : (
                    ''
                )}
            </FlexBetween>
        );
    });

    const handleDeleteLogic = event => {
        event.stopPropagation();
        setSummaryMenu(null);
        setShowDeletePopUp(true);
    };
    const handleExpand = view => {
        if (view) {
            setSortOption(clonedOptions);
        } else {
            setSortOption(clonedOptions?.sort((a, b) => a?.option_order - b?.option_order)?.slice(0, 12));
        }
    };

    const handleOptionClick = (e, option) => {
        const isChecked = e.target.checked;
        const updatedOptions = cloneDeep(selectedOptions);
        const { option_id } = option;

        if (isChecked) {
            updatedOptions.push(option);
        } else {
            const optionPosition = findIndex(selectedOptions, { option_id: option_id });
            if (optionPosition >= 0) {
                updatedOptions.splice(optionPosition, 1);
            }
        }
        setSelectedOptions(updatedOptions);
    };

    const handleSelectAll = e => {
        const {
            target: { checked },
        } = e;
        setSelectedOptions(checked ? question_options : []);
    };

    const handleAddResponseLogic = () => {
        dispatch(
            GetPreviousQuestionList({
                surveyId: surveyId,
                question_id: question_id,
                questionType,
            })
        );
        setShowResponseLogicModal(true);
    };

    const handleSetResponseLogic = () => {
        setShowDisplayLogicOptions(null);
        setEditResponse(false);
        setIsExpanded(true);
        setOptionsCheckBox(true);
    };

    const resetSearch = () => {
        setSearch('');
        handleOptionSearch('');
    };
    const handleCancel = () => {
        setShowDisplayLogicOptions(null);
        setIsExpanded(false);
        setOptionsCheckBox(false);
        setSelectedOptions([]);
        resetSearch();
    };

    const handleModalClose = isSaved => {
        if (isSaved) {
            if (selectedOptions?.length) {
                setSelectedOptions([]);
            }
            setOptionsCheckBox(false);
            setIsExpanded(false);
            resetSearch();
        }
        setShowResponseLogicModal(false);
        setShowDisplayLogicModal(false);
    };

    const handleSearch = val => {
        setSearch(val);
        handleOptionSearch(val);
    };

    const handleEditEvent = event => {
        event.stopPropagation();
        setEditResponse(true);
        setSummaryMenu(null);
        dispatch(
            GetPreviousQuestionList({
                surveyId: surveyId,
                question_id: question_id,
                questionType,
            })
        );
        if (conditions && conditions?.on === 'option') {
            setShowResponseLogicModal(true);
        } else {
            setShowDisplayLogicModal(true);
        }
    };

    return (
        <DisplayLogicContainer>
            <Accordion
                sx={{ padding: '0.5rem' }}
                expanded={isExpanded}
                onChange={() => {
                    setIsExpanded(!isExpanded);
                }}
            >
                <AccordionSummary expandIcon={<ArrowDown />}>
                    <DisplayLogicContainer>
                        <FlexBetween
                            gap={`${pxToVw(28)}vw`}
                            style={{
                                padding: '0 3vw 0 0',
                            }}
                        >
                            <div>
                                <FlexRow justifyContent="start">
                                    <QuesTitle>{Question}</QuesTitle>
                                    {question_status ? (
                                        <StatusBadge
                                            label={question_status || QUES_STATUS_TYPE?.NOT_SUBMITTED}
                                            color={
                                                question_status === QUES_STATUS_TYPE?.REJECTED
                                                    ? colorsCode.rejected
                                                    : question_status === QUES_STATUS_TYPE?.PENDING
                                                    ? colorsCode?.pending
                                                    : question_status === QUES_STATUS_TYPE?.APPROVED
                                                    ? colorsCode.approved
                                                    : colorsCode?.TextSubtle
                                            }
                                            bg={
                                                question_status === QUES_STATUS_TYPE?.REJECTED
                                                    ? colorsCode.rejectedbg
                                                    : question_status === QUES_STATUS_TYPE?.PENDING
                                                    ? colorsCode?.pendingbg
                                                    : question_status === QUES_STATUS_TYPE?.APPROVED
                                                    ? colorsCode.approvedbg
                                                    : colorsCode?.Neutral05
                                            }
                                            src={
                                                question_status === QUES_STATUS_TYPE?.REJECTED
                                                    ? rejected
                                                    : question_status === QUES_STATUS_TYPE?.PENDING
                                                    ? pending
                                                    : question_status === QUES_STATUS_TYPE?.APPROVED
                                                    ? approved
                                                    : notsubmitted
                                            }
                                        />
                                    ) : null}
                                </FlexRow>

                                <Quest>
                                    <Typography
                                        sx={{ fontSize: `${pxToVh(15)}vh` }}
                                        dangerouslySetInnerHTML={{ __html: question_text }}
                                    />
                                </Quest>
                            </div>
                            {first_quest ? (
                                <FlexRow>
                                    <LightTooltip title={display_default_text} placement="top" arrow>
                                        <FlexRowCTA>
                                            <Img image={Info} h={pxToVh(20)} w={pxToVw(14)} alt="" />
                                        </FlexRowCTA>
                                    </LightTooltip>

                                    <Text
                                        fontColor={colorsCode.TextSubtle}
                                        fontSize={13}
                                        lineHeight={20}
                                        label="Displayed by default"
                                    />
                                </FlexRow>
                            ) : (
                                ''
                            )}
                            {!first_quest ? (
                                <CTA
                                    bg={colorsCode.PrimaryDeepBlue}
                                    disabled={
                                        optionsCheckBox || (display_logic?.length && conditions?.on === 'question')
                                    }
                                    pt={4}
                                    pl={16}
                                    size={12}
                                    fw={400}
                                    lh={28}
                                    color={colorsCode.white}
                                    onClick={event => {
                                        event.stopPropagation();
                                        setShowDisplayLogicOptions(event.currentTarget);
                                    }}
                                    id="basic-button"
                                >
                                    {SELECT_LOGIC}
                                </CTA>
                            ) : (
                                ''
                            )}
                            <Menu
                                anchorEl={showDisplayLogicOptions}
                                open={showDisplayLogicOptions}
                                onClose={event => {
                                    event.stopPropagation();
                                    setShowDisplayLogicOptions(null);
                                }}
                            >
                                <MenuItem
                                    onClick={event => {
                                        event.stopPropagation();
                                        setShowDisplayLogicOptions(null);
                                        setShowDisplayLogicModal(!showDisplayLogicModal);
                                        dispatch(
                                            GetPreviousQuestionList({
                                                surveyId: surveyId,
                                                question_id: question_id,
                                                questionType,
                                            })
                                        );
                                    }}
                                    disabled={optionsConditionExist}
                                >
                                    {Set_Question_Logic}
                                </MenuItem>
                                {activeTab !== open_text_ques && !is_scale_question && show_response_logic ? (
                                    <MenuItem
                                        onClick={event => {
                                            event.stopPropagation();
                                            handleSetResponseLogic();
                                        }}
                                        disable
                                    >
                                        {Set_Response_Logic}
                                    </MenuItem>
                                ) : (
                                    ''
                                )}
                            </Menu>
                        </FlexBetween>
                        {display_logic?.length ? (
                            <DisplayLogicPurpleCard>
                                <FlexStartGap gap={pxToVw(6)}>
                                    <Img image={Vector} h={pxToVh(22)} w={pxToVw(16)} alt="" />
                                    <Text
                                        fontColor={colorsCode.Neutral80}
                                        fontSize={13}
                                        lineHeight={20}
                                        label="Display logic applied"
                                        fontWeight={500}
                                    />
                                </FlexStartGap>
                                <Text
                                    fontColor={colorsCode.Neutral80}
                                    fontSize={13}
                                    lineHeight={20}
                                    label={
                                        display_logic[0]?.logic_on === 'question'
                                            ? 'This question will be displayed if it has met the following conditions:'
                                            : 'Selected responses will be displayed if it has met the following conditions:'
                                    }
                                    fontWeight={400}
                                />

                                <div>{renderQuestionLogicSummary}</div>

                                <Menu
                                    anchorEl={showSummaryMenu}
                                    open={showSummaryMenu}
                                    onClose={event => {
                                        event.stopPropagation();
                                        setSummaryMenu(null);
                                    }}
                                >
                                    <MenuItem onClick={handleEditEvent}>Edit</MenuItem>
                                    <MenuItem onClick={handleDeleteLogic}>Delete</MenuItem>
                                </Menu>
                            </DisplayLogicPurpleCard>
                        ) : (
                            ''
                        )}
                    </DisplayLogicContainer>
                </AccordionSummary>
                <AccordionDetails>
                    <FlexColumnGap gap={pxToVh(12)}>
                        {sortedOption?.length ? <QuesTitle>Choices (single selection)</QuesTitle> : ''}
                        {!show_response_logic ? (
                            <CommonLabel
                                textColor={colorsCode.TextSubtle}
                                textSize={14}
                                lineHeight={20}
                                textWeight={400}
                                label="This is an open text question"
                            />
                        ) : (
                            ''
                        )}
                        {optionsCheckBox ? (
                            <SearchBar
                                placeholder="Search"
                                id="search"
                                name="search"
                                val={search}
                                onChangeFn={e => handleSearch(e.target.value)}
                                customClass={styles.optionsSearch}
                            />
                        ) : null}
                    </FlexColumnGap>
                    <OptionGridContainer column_count={activeTab === demographic_ques && sortedOption?.length ? 2 : 1}>
                        {optionsCheckBox && !search ? (
                            <FormControlLabel
                                style={{ width: '100%', padding: '0.2rem' }}
                                label="Select all"
                                control={
                                    <Checkbox
                                        checked={selectedOptions?.length === question_options?.length}
                                        indeterminate={
                                            selectedOptions?.length &&
                                            selectedOptions?.length !== question_options?.length
                                        }
                                        onClick={handleSelectAll}
                                        sx={{
                                            height: 0,
                                            width: 0,
                                            margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                        }}
                                    />
                                }
                            />
                        ) : null}
                        {sortedOption?.length ? (
                            sortedOption?.map(option => {
                                const { option_uuid, option_text, option_id } = option;
                                const isSelected = find(selectedOptions, { option_id: option_id });
                                return (
                                    <FlexStartGap gap={pxToVw(8)} key={option_uuid} className="grid-item">
                                        {!optionsCheckBox && (
                                            <CommonLabel
                                                textColor={colorsCode.Neutral80}
                                                textSize={14}
                                                lineHeight={20}
                                                textWeight={400}
                                                label={option_text}
                                                component="li"
                                            />
                                        )}
                                        {optionsCheckBox && (
                                            <FormControlLabel
                                                style={{ width: '100%', padding: '0.2rem' }}
                                                label={option_text}
                                                control={
                                                    <Checkbox
                                                        value={option_uuid}
                                                        checked={!!isSelected}
                                                        onChange={e => {
                                                            handleOptionClick(e, option);
                                                        }}
                                                        sx={{
                                                            height: 0,
                                                            width: 0,
                                                            margin: `0 ${pxToVw(12)}vw 0 ${pxToVw(12)}vw`,
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    </FlexStartGap>
                                );
                            })
                        ) : (
                            <FlexColumnGap>
                                <CommonLabel
                                    textColor={colorsCode.Neutral80}
                                    textSize={16}
                                    lineHeight={24}
                                    textWeight={500}
                                    label={`No results found for choice '${search}''`}
                                />
                                <CommonLabel
                                    textColor={colorsCode.Neutral80}
                                    textSize={12}
                                    lineHeight={20}
                                    textWeight={300}
                                    label={NoResultDisplayLogicSubText}
                                />
                            </FlexColumnGap>
                        )}
                    </OptionGridContainer>
                    {sortedOption?.length > 11 && sortedOption?.length <= 12 ? (
                        <FlexEnd className="classExpand">
                            <LightTooltip title="Expand to view all options" placement="top" arrow>
                                <FlexColumnGap className="expand-icon-container">
                                    <img
                                        src={next}
                                        alt=""
                                        style={{ rotate: '270deg', cursor: 'pointer' }}
                                        onClick={() => handleExpand('full')}
                                    />
                                    <img
                                        src={next}
                                        alt=""
                                        style={{ rotate: '-270deg', cursor: 'pointer' }}
                                        onClick={() => handleExpand('full')}
                                    />
                                </FlexColumnGap>
                            </LightTooltip>
                        </FlexEnd>
                    ) : sortedOption?.length > 12 ? (
                        <FlexEnd className="classExpand">
                            <FlexColumnGap className="expand-icon-container">
                                <img
                                    src={next}
                                    alt=""
                                    style={{ rotate: '90deg', cursor: 'pointer' }}
                                    onClick={() => handleExpand()}
                                />
                                <img
                                    src={next}
                                    alt=""
                                    style={{ rotate: '270deg', cursor: 'pointer' }}
                                    onClick={() => handleExpand()}
                                />
                            </FlexColumnGap>
                        </FlexEnd>
                    ) : null}
                    {optionsCheckBox ? (
                        <FlexAlignCenter style={{ marginTop: '1rem' }} gap={pxToVw(24)}>
                            <CTA
                                disabled={!selectedOptions?.length}
                                bg={colorsCode.white}
                                pt={4}
                                pl={16}
                                size={12}
                                fw={400}
                                lh={28}
                                color={colorsCode.PrimaryDeepBlue}
                                bcolor={colorsCode.PrimaryDeepBlue}
                                onClick={event => {
                                    event.stopPropagation();
                                    handleAddResponseLogic();
                                }}
                                id="basic-button"
                            >
                                {ADD_LOGIC}
                            </CTA>
                            <CTA
                                pt={4}
                                pl={16}
                                size={12}
                                fw={400}
                                lh={28}
                                bg={colorsCode.white}
                                bcolor={colorsCode.PrimaryDeepBlue}
                                color={colorsCode.PrimaryDeepBlue}
                                onClick={event => {
                                    event.stopPropagation();
                                    handleCancel();
                                }}
                                id="basic-button"
                            >
                                Cancel
                            </CTA>
                        </FlexAlignCenter>
                    ) : (
                        ''
                    )}
                </AccordionDetails>
            </Accordion>
            {showDisplayLogicModal && (
                <DisplayLogicModal
                    onClose={handleModalClose}
                    question={question}
                    showDisplayLogicModal={showDisplayLogicModal}
                    surveyId={surveyId}
                    activeTab={activeTab}
                    selectedOptions={selectedOptions}
                    setIsExpanded={setIsExpanded}
                />
            )}
            {showDeletePopUp ? (
                <Dialog
                    open={showDeletePopUp}
                    PaperProps={{
                        sx: {
                            maxWidth: `${pxToVw(520)}vw`,
                            width: `${pxToVw(520)}vw`,
                            borderRadius: '15px',
                        },
                    }}
                >
                    <FlexRow justifyContent="flex-end" margin="1vw 1vw 0 0">
                        <Icon
                            aria-label="close"
                            sx={{
                                position: 'absolute',
                                right: `${pxToVw(16)}vw`,
                                top: `${pxToVh(16)}vh`,
                                cursor: 'pointer',
                            }}
                        >
                            <img
                                src={CloseIcon}
                                alt=""
                                style={{
                                    height: `${pxToVh(20)}vh`,
                                    width: ` ${pxToVw(20)}vw`,
                                    cursor: 'pointer',
                                }}
                                onClick={() => setShowDeletePopUp(false)}
                            />
                        </Icon>
                    </FlexRow>
                    <DialogTitle>
                        <FlexCenterWithAlign>
                            <Img image={RedWarningIcon} h={pxToVh(40)} w={pxToVw(40)} alt="" />
                        </FlexCenterWithAlign>
                        <FlexCenterWithAlign>
                            <CommonLabel
                                textSize={20}
                                textColor={colorsCode.TextHeading}
                                textWeight={500}
                                lineHeight={36}
                                label="Delete display logic"
                            />
                        </FlexCenterWithAlign>
                        <FlexColumnCenter gap="2" margin="20px 0">
                            <FlexCenterWithAlign>
                                <CommonLabel
                                    textSize={14}
                                    textColor={colorsCode.TextHeading}
                                    textWeight={300}
                                    textAlign="center"
                                    label="Are you sure you want to delete display logic applied to this question?"
                                />
                            </FlexCenterWithAlign>
                        </FlexColumnCenter>
                    </DialogTitle>

                    <DialogActionsStyled sx={{ justifyContent: 'center', padding: `${pxToVh(30)}vh 0` }}>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.white}
                            pt={8}
                            pl={16}
                            color={colorsCode.PrimaryDeepBlue}
                            bcolor={colorsCode.PrimaryDeepBlue}
                            onClick={() => setShowDeletePopUp(false)}
                        >
                            Cancel
                        </CTA>
                        <CTA
                            size={16}
                            lh={24}
                            fw={400}
                            bg={colorsCode.PrimaryDeepBlue}
                            pt={8}
                            pl={16}
                            color={colorsCode.white}
                            onClick={() => {
                                dispatch(DeleteDisplatLogic({ surveyId, question_id, activeTab, subGroupId }));
                                setShowDeletePopUp(false);
                            }}
                        >
                            Delete
                        </CTA>
                    </DialogActionsStyled>
                </Dialog>
            ) : null}
            {showResponseLogicModal && (
                <DisplayResponseLogicModal
                    onClose={handleModalClose}
                    question={question}
                    showResponseLogicModal={showResponseLogicModal}
                    surveyId={surveyId}
                    activeTab={activeTab}
                    toEdit={editResponse}
                    selectedOptions={selectedOptions}
                    setIsExpanded={setIsExpanded}
                />
            )}
        </DisplayLogicContainer>
    );
};

export const DisplayLogicContainer = styled.div`
    width: ${props => (props.width ? props.width : '100%')};
`;
export const QuesTitle = styled.div`
    font-size: ${pxToVh(14)}vh;
    color: ${colorsCode.TextSubtle};
`;
export const PaddingContainer = styled.div`
    padding: ${props => props.padding};
`;
export const Quest = styled.div`
    width: ${pxToVw(860)}vw;
    font-size: ${pxToVh(15)}vh;
`;

const DisplayLogicPurpleCard = styled.div`
    background: #f4ecfd;
    border-radius: 5px;
    padding: 12px;
    margin: 2vh 2vh 0 0;
`;

const OptionGridContainer = styled.div`
    column-count: ${props => props.column_count || 2};
    -moz-column-count: ${props => props.column_count || 2};
    -webkit-column-count: ${props => props.column_count || 2};
    overflow: hidden;
    gap: ${props => props.gap || '24px'};
    width: 100%;
    padding-top: 2vh;
`;
export default DisplayLogicQuestion;
