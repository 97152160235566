import { createSlice } from '@reduxjs/toolkit';
import { AllCustomQuestion, GetSurveyPreviewLinks, SaveStatusQuestions } from '../../actions/Surveys/FinalizeActions';

export const INITIAL_STATE = {
    loading: false,
    error: false,
    data: [],
    previewData: {},
    errorMessage: '',
};

const FinalizeReducer = createSlice({
    name: 'finalize',
    initialState: INITIAL_STATE,
    extraReducers: builder => {
        builder
            .addCase(AllCustomQuestion.pending, state => {
                state.loading = true;
            })
            .addCase(AllCustomQuestion.fulfilled, (state, { payload }) => {
                const data = Object.keys(payload.records).length
                    ? Object.values(payload.records).map(item => {
                          return { ...item, is_selected: false };
                      })
                    : [];
                state.loading = false;
                state.data = data;
            })
            .addCase(AllCustomQuestion.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(SaveStatusQuestions.pending, state => {
                state.loading = true;
            })
            .addCase(SaveStatusQuestions.fulfilled, state => {
                state.loading = false;
            })
            .addCase(SaveStatusQuestions.rejected, (state, { payload }) => {
                state.loading = false;
                state.error = true;
                state.errorMessage = payload;
            })
            .addCase(GetSurveyPreviewLinks.pending, state => {
                state.loading = true;
            })
            .addCase(GetSurveyPreviewLinks.fulfilled, (state, { payload }) => {
                state.loading = false;
                state.previewData = payload;
            })
            .addCase(GetSurveyPreviewLinks.rejected, (state, { payload }) => {
                state.error = true;
                state.loading = false;
                state.errorMessage = payload;
            });
    },
    reducers: {
        resetData: state => {
            state.error = false;
            state.errorMessage = '';
        },
        resetFinalizeSurveyData: state => {
            state.error = false;
            state.previewData = {};
            state.data = [];
            state.errorMessage = '';
        },
    },
});

export const { resetData, resetFinalizeSurveyData } = FinalizeReducer.actions;
export default FinalizeReducer.reducer;
